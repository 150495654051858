import React, { useEffect, useState, useCallback } from 'react'
import { Box, Button, Modal } from 'stylewhere/components'
import { __ } from 'stylewhere/i18n'
import styled from '@emotion/styled'
import { useLocation } from 'react-router-dom'
import { RfidReader, AppStore } from 'stylewhere/shared'
import { showToastError } from 'stylewhere/utils'

// Aggiungiamo la dichiarazione del tipo per Window
declare global {
  interface Window {
    __TEST_SHOULD_FAIL__?: boolean
  }
}

// Aggiungo un evento custom per i test
declare global {
  interface WindowEventMap {
    'test:device-manager-update': CustomEvent<{
      currentVersion: string
      latestVersion: string
      shouldFail?: boolean // Nuovo parametro opzionale
    }>
  }
}

interface DeviceManagerUpdateNotificationProps {
  onUpdate?: () => void
}

export const DeviceManagerUpdateNotification: React.FC<DeviceManagerUpdateNotificationProps> = ({ onUpdate }) => {
  const [showNotification, setShowNotification] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [updateStatus, setUpdateStatus] = useState<'idle' | 'updating' | 'completed'>('idle')
  const [testVersions, setTestVersions] = useState<{
    currentVersion?: string
    latestVersion?: string
    shouldFail?: boolean
  }>({})
  const location = useLocation()

  // Aggiungiamo una costante per l'intervallo
  const CHECK_INTERVAL = 5 * 60 * 1000 // 5 minuti in millisecondi

  const isOperationalPage = useCallback((pathname: string): boolean => {
    const operationalPatterns = ['/reading', '/verify', '/add', '/encode']
    const nonOperationalPatterns = ['/view', '/start', '/utilities', '/login', '/connection-error', '/dashboard']

    const isOperational = operationalPatterns.some((pattern) => pathname.includes(pattern))
    const isNonOperational = nonOperationalPatterns.some((pattern) => pathname.includes(pattern))

    return isOperational && !isNonOperational && !RfidReader.isReading()
  }, [])

  // Effect per gestire gli eventi di test
  useEffect(() => {
    const handleTestEvent = (event: WindowEventMap['test:device-manager-update']) => {
      const { currentVersion, latestVersion, shouldFail } = event.detail
      setTestVersions({ currentVersion, latestVersion, shouldFail })
    }

    window.addEventListener('test:device-manager-update', handleTestEvent)
    return () => {
      window.removeEventListener('test:device-manager-update', handleTestEvent)
    }
  }, [])

  const handleUpdate = useCallback(async () => {
    setShowNotification(false)
    setShowModal(true)
    setUpdateStatus('updating')

    try {
      if (!AppStore.defaultWorkstation) {
        throw new Error('Workstation non impostata')
      }

      const remoteUrl = AppStore.defaultWorkstation.attributes?.deviceManagerCloudUrl
      const username = AppStore.defaultWorkstation.attributes?.deviceManagerUser

      const config = {
        remoteUrl: remoteUrl || '',
        remotePath: '/upload/update-config.xml',
        username: username || '',
        password: 'pass',
        connectionType: 'SFTP' as const,
        backupPaths: ['application.yml', 'bootstrap.properties', 'device_manager*.jar'],
        cleanupPatterns: ['device_manager-(.)*.jar', 'bootstrap-new.properties'],
        excludePatterns: ['device_manager-1.5.4.jar'],
      }

      const result = await RfidReader.performUpdate(config)

      if (result.success) {
        setUpdateStatus('completed')
        if (onUpdate) {
          onUpdate()
        }
        setTimeout(() => {
          setShowModal(false)
          setShowNotification(false)
          setUpdateStatus('idle')
        }, 3000)
      } else {
        throw new Error(result.error || 'Aggiornamento fallito')
      }
    } catch (error) {
      console.error("Errore durante l'aggiornamento:", error)
      setUpdateStatus('idle')
      // Mostra l'errore all'utente
      const errorMessage = error instanceof Error ? error.message : 'Errore sconosciuto'
      setUpdateStatus('idle')
      setShowModal(false)
      showToastError(errorMessage)
    }
  }, [onUpdate, setShowModal])

  const openUpdateModal = useCallback(() => {
    handleUpdate()
  }, [handleUpdate])

  const checkDeviceManagerUpdate = useCallback(async () => {
    try {
      const updateSetting = await AppStore.getDataDogApplicationSettings('deviceManagerUpdate', 'disabled')
      const modalDismissed = sessionStorage.getItem('deviceManagerUpdateDismissed')
      const dismissedTime = sessionStorage.getItem('deviceManagerUpdateDismissedTime')

      const ONE_HOUR = 60 * 60 * 1000 // 1 ora in millisecondi
      const canShowAgain = !dismissedTime || Date.now() - Number(dismissedTime) > ONE_HOUR

      if (updateSetting === 'disabled' || (modalDismissed === 'true' && !canShowAgain)) {
        return
      }

      // Se siamo in modalità test, usiamo le versioni di test
      if (testVersions.currentVersion && testVersions.latestVersion) {
        const hasUpdate = testVersions.currentVersion !== testVersions.latestVersion
        window.__TEST_SHOULD_FAIL__ = testVersions.shouldFail

        // Se c'è un aggiornamento e non siamo in una pagina operativa
        if (hasUpdate && !isOperationalPage(location.pathname)) {
          if (updateSetting === 'forceUpdate') {
            openUpdateModal()
          } else if (updateSetting === 'enabled') {
            setShowNotification(true)
          }
        }
        return
      }

      // Se non siamo in modalità test, procediamo con il controllo normale

      const remoteUrl = AppStore.defaultWorkstation.attributes?.deviceManagerCloudUrl
      const username = AppStore.defaultWorkstation.attributes?.deviceManagerUser
      const remotePath =
        AppStore.defaultWorkstation.attributes?.deviceManagerUpdatePath || '/upload/latest-version.json'

      const config = {
        remoteUrl: remoteUrl || '',
        remotePath: remotePath || '',
        username: username || '',
        password: 'pass',
        connectionType: 'SFTP' as const,
      }

      const reader = await RfidReader.checkForUpdates(config)
      if (!reader) return

      const hasUpdate = reader.hasUpdate

      if (hasUpdate && !isOperationalPage(location.pathname)) {
        if (updateSetting === 'forceUpdate') {
          openUpdateModal()
        } else if (updateSetting === 'enabled') {
          setShowNotification(true)
        }
      }
    } catch (error) {
      console.error('Errore nel controllo degli aggiornamenti:', error)
    }
  }, [location.pathname, isOperationalPage, testVersions, openUpdateModal])

  // Effect per controllare gli aggiornamenti quando cambiano le versioni di test
  useEffect(() => {
    if (testVersions.currentVersion && testVersions.latestVersion) {
      checkDeviceManagerUpdate()
    }
  }, [testVersions, checkDeviceManagerUpdate])

  // Modifichiamo l'effetto per il controllo periodico
  useEffect(() => {
    // Controllo iniziale
    checkDeviceManagerUpdate()

    // Imposta un intervallo per il controllo ogni 5 minuti
    const interval = setInterval(() => {
      checkDeviceManagerUpdate()
    }, CHECK_INTERVAL)

    // Pulizia dell'intervallo quando il componente viene smontato
    return () => {
      clearInterval(interval)
    }
  }, [checkDeviceManagerUpdate, CHECK_INTERVAL])

  if (!showNotification && !showModal) return null

  if (isOperationalPage(location.pathname)) {
    return null
  }

  return (
    <>
      {showNotification && (
        <Modal
          visible={true}
          onClose={() => {
            setShowNotification(false)
            sessionStorage.setItem('deviceManagerUpdateDismissed', 'true')
            sessionStorage.setItem('deviceManagerUpdateDismissedTime', Date.now().toString())
          }}
          size="md"
          showCloseButton
        >
          <ModalContainer flex vcenter>
            <ModalTitle>{__('messages.deviceManagerUpdateTitle')}</ModalTitle>
            <NotificationContainer flex textAlign="center" center>
              <Box flex vcenter>
                <Box flex mb={10}>
                  <NotificationText>{__('messages.deviceManagerUpdateDescription')}</NotificationText>
                </Box>

                <Button
                  variant="primary"
                  size="small"
                  title={__('misc.update')}
                  onClick={handleUpdate}
                  style={{ marginLeft: 16 }}
                />
              </Box>
            </NotificationContainer>
          </ModalContainer>
        </Modal>
      )}

      {showModal && (
        <Modal visible={true} onClose={() => {}} size="md">
          <Box flex row={false} vcenter p={20}>
            <ModalTitle>{__('messages.deviceManagerUpdateTitle')}</ModalTitle>
            <Box mt={20}>
              {updateStatus === 'updating' && <UpdateText>{__('messages.deviceManagerUpdating')}</UpdateText>}
              {updateStatus === 'completed' && <UpdateText>{__('messages.deviceManagerUpdateCompleted')}</UpdateText>}
            </Box>
          </Box>
        </Modal>
      )}
    </>
  )
}

const ModalContainer = styled(Box)`
  gap: 10px;
`

const NotificationContainer = styled(Box)`
  width: '100%';
  min-height: 80;
`

const NotificationText = styled.span`
  font-size: 14px;
  color: #333333;
`

const NotificationTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
  color: #333333;
`

const UpdateText = styled.div`
  font-size: 16px;
  color: #333333;
  text-align: center;
`

const ModalTitle = styled.h2`
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  text-align: center;
`

import { Component, ReactNode } from 'react'
import { DecodedItem, GroupedItemsByProduct, IdentifierType, ProductInfoField } from 'stylewhere/api'
import { GroupedProductRow, List } from 'stylewhere/components'
import { OperationConfig } from 'stylewhere/shared'
import { __, T } from 'stylewhere/shared/i18n'
import { sortGroupeditemList, sortGroupeditemListStatus } from 'stylewhere/shared/utils'

interface Props<OC extends OperationConfig> {
  rows: GroupedItemsByProduct[]
  emptyMessage?: string | JSX.Element | null
  loading?: boolean
  onRemoveItem?: (item: DecodedItem) => void
  onActionItem?: (item: DecodedItem) => void
  operation: OC
  removeModeEnabled?: boolean
  hideIdentifiersByType?: IdentifierType[]
  hideIdentifiersZones?: boolean
  overrideProductInfoFields?: ProductInfoField[]
}

export class GroupedProductList extends Component<Props<any>> {
  rowRender = (item) => {
    const {
      operation,
      onRemoveItem,
      onActionItem,
      removeModeEnabled,
      hideIdentifiersByType,
      hideIdentifiersZones,
      overrideProductInfoFields,
    } = this.props
    const product = item.item ? item.item.product : item.product
    return (
      <GroupedProductRow
        key={product ? product.code : ''}
        product={product}
        detected={item.detected ?? 0}
        expected={item.expected ?? 0}
        unexpected={item.unexpected ?? 0}
        operation={operation}
        decodedItems={item.items || []}
        onRemoveItem={onRemoveItem}
        onActionItem={onActionItem}
        status={item.status ?? ''}
        removeModeEnabled={removeModeEnabled}
        hideIdentifiersByType={hideIdentifiersByType}
        hideIdentifiersZones={hideIdentifiersZones}
        overrideProductInfoFields={overrideProductInfoFields}
      />
    )
  }

  getDataRow = (rows: GroupedItemsByProduct[], operation: any) => {
    const ordererdRows = operation?.newReadingsFirst
      ? [...rows].reverse()
      : rows.sort((a: GroupedItemsByProduct, b: GroupedItemsByProduct) => {
          if (a.status === 'error') return -1
          if (b.status === 'error') return 1
          if (a.status === 'warning') return -1
          if (b.status === 'warning') return 1
          if (a.status === 'ignored') return -1
          if (b.status === 'ignored') return 1
          return 0
        })
    return ordererdRows
  }

  render() {
    const { rows, operation, emptyMessage } = this.props
    return (
      <List
        data={this.getDataRow(rows, operation)}
        emptyMessage={
          emptyMessage === null ? undefined : emptyMessage ? (
            (emptyMessage as ReactNode)
          ) : (
            <span style={{ whiteSpace: 'break-spaces' }}>{__(T.messages.press_start_to_read)}</span>
          )
        }
        rowRender={this.rowRender}
      />
    )
  }
}

import React, { Component } from 'react'
import styled from '@emotion/styled'
import {
  Page,
  Box,
  FullLoadingLayer,
  EncodingInboundSapHeader,
  TagCounter,
  AntennaButton,
  OperationReadingList,
  ToggleField,
  ImbustatriceTagRow,
  Button,
  EncodingDailyItems,
  AntennaStatus,
} from 'stylewhere/components'
import {
  Router,
  RemoteOperation,
  OperationReadingProps,
  OperationReadingState,
  RfidReader,
  AppStore,
  Sounds,
} from 'stylewhere/shared'
import { SessionItemSessionListOperationConfig } from 'stylewhere/shared/RemoteOperation'
import { SimpleItemSessionListExtensions } from 'stylewhere/extensions'
import { SimpleItemSessionList, TmrTag } from 'stylewhere/api'
import { askUserConfirmation, showToast, showToastError } from 'stylewhere/shared/utils'
import { T, __, __UP } from 'stylewhere/i18n'

const EMBEDDED_ROLE = 'EMBEDDED'
const PENDANT_ROLE = 'PENDANT'

interface State extends OperationReadingState {
  sessionListId: string
  sessionId: string
  processing: boolean
  umg: string
  umgResetCounter: number
  umgVerifying: boolean
  umgVerified: boolean
  checkCountry: boolean
  checkShowCase: boolean
  reloadUmgsCounter: number
  expected: number
  detected: number
  deleteItems: boolean
  associationStatus: number
  identifiers: any[]
  configuration: any[]
  lastTagEpc: string[]
  pendingEpcs: string[]
  forceUpdateContent: boolean
  lastItem: any
  expectedUmg: number
  detectedUmg: number
  antennaOn: boolean
}

export default class EncodingInboundSapEncode extends Component<OperationReadingProps, State> {
  numTagToRead = 2
  antennaRef
  matchParams = Router.getMatchParams(this.props)
  locationState = Router.getLocationState<OperationReadingState>(this.props)
  operation = RemoteOperation.getOperationConfig<SessionItemSessionListOperationConfig>(this.matchParams.opCode)
  formSchema = SimpleItemSessionListExtensions.formSchema(this.operation)
  timer?: any
  timerReset?: any
  inBatch = false

  state = {
    sessionListId: '',
    sessionId: '',
    formData: this.locationState.formData ?? {},
    processing: false,
    loading: false,
    items: [],
    umg: '',
    umgResetCounter: 0,
    umgVerified: false,
    umgVerifying: false,
    checkCountry: true,
    checkShowCase: true,
    reloadUmgsCounter: 0,
    expected: 0,
    detected: 0,
    deleteItems: false,
    associationStatus: 0,
    identifiers: [],
    configuration: [],
    lastTagEpc: [],
    pendingEpcs: [],
    forceUpdateContent: false,
    lastItem: undefined,
    expectedUmg: 0,
    detectedUmg: 0,
    antennaOn: false,
  } as any

  constructor(props) {
    super(props)
    this.antennaRef = React.createRef()
  }

  async componentDidMount() {
    if (!this.locationState.formData || !this.locationState.formData.sessionListId) {
      this.goBack()
      return
    }
    const { formData } = this.state
    RfidReader.setBatchInterval(this.operation.batchInterval)
    RfidReader.setBatchIntervalTagCount(this.operation.batchIntervalTagCount)
    RfidReader.setBatchIntervalTime(this.operation.batchIntervalTime)
    RfidReader.setAutomaticStop(this.operation.autostopAntennaTimeout > 0)
    RfidReader.setAutomaticStopTime(this.operation.autostopAntennaTimeout)
    RfidReader.onStartCallback = this.onStartCallback
    RfidReader.onStopCallback = this.onStopCallback
    RfidReader.setOnTagReadCallback(this.onTagRead)
    this.setState({
      sessionListId: this.locationState.formData.sessionListId,
      detected: this.locationState.detected || 0,
      expected: parseInt(formData.quantity || 0, 10),
      configuration: [
        {
          identifierType: 'UHF_TAG',
          role: EMBEDDED_ROLE,
          optional: false,
        },
        {
          identifierType: 'UHF_TAG',
          role: PENDANT_ROLE,
          optional: false,
        },
      ],
    })
  }

  onStartCallback = () => {
    this.setState({ antennaOn: true })
  }

  onStopCallback = () => {
    this.setState({ antennaOn: false })
  }

  componentWillUnmount() {
    this.setState({
      identifiers: [],
      associationStatus: 0,
      lastTagEpc: [],
      forceUpdateContent: false,
      lastItem: undefined,
    })
    this.handleCheckTagStopTimer()
  }

  getDetectedItem = async (sessionListId, umgId) => {
    const params = { size: 10000, sessions: [umgId] }
    const items: any = await SimpleItemSessionList.getItemsList(sessionListId, params)
    return items && items.content ? items.content : []
  }

  getIdentifierLabel = (role) => {
    if (role && role !== null) return __UP(__(T.imbustatrice.dynamic_tag, { role: role }))
    return __UP(T.imbustatrice.unkwon_tag)
  }

  onTagRead = async (tag: TmrTag) => {
    const { configuration, umgVerified } = this.state
    if (this.inBatch) {
      this.removeReaderTag([tag.epc])
      return
    }
    if (!umgVerified) {
      showToastError(__(T.error.item_session_list_create_session), __(T.error.error), true)
      return
    }
    let tmp = this.state.lastTagEpc
    if (tmp.length === this.numTagToRead) {
      this.destroyResetRead()
      tmp = []
    }
    // check if epc already exist
    const checkEpc = tmp.find((t) => t === tag.epc)
    if (!checkEpc) {
      const tmpIdentifiers: any[] = []
      let c = 0
      for (c = 0; c < configuration.length; c++) {
        tmpIdentifiers.push({
          status: 0,
          label: this.getIdentifierLabel(configuration[c].role),
          subtitle: '',
          type: configuration[c].identifierType,
          role: configuration[c].role,
        })
      }
      tmp.push(tag.epc)
      if (tmp.length === this.numTagToRead) {
        this.inBatch = true
        this.handleCheckTagStopTimer()
        this.setState({ lastTagEpc: tmp }, this.batchEpc)
      } else {
        this.handleCheckTagStartTimer()
        this.setState({
          identifiers: tmpIdentifiers,
          associationStatus: 0,
          lastTagEpc: tmp,
          forceUpdateContent: false,
          lastItem: undefined,
        })
      }
    }
  }

  handleCheckTagStartTimer = () => {
    if (this.operation.stopAndResetAntennaTimeout > 0) {
      this.handleCheckTagStopTimer()
      this.timer = setTimeout(() => {
        this.inBatch = true
        this.batchEpc()
      }, this.operation.stopAndResetAntennaTimeout || 3000)
    }
  }

  handleCheckTagStopTimer = () => {
    if (!this.timer) return
    clearTimeout(this.timer)
    this.timer = undefined
  }

  checkIdentifier = (identifiers, type, role) => {
    let count = 0
    for (let e = 0; e < identifiers.length; e++) {
      if (identifiers[e].type === type && identifiers[e].role === role) count++
    }
    return count
  }

  batchEpc = async () => {
    const { lastTagEpc, sessionListId } = this.state
    if (this.antennaRef && this.antennaRef.current) {
      await this.antennaRef.current.stopReader()
    }

    let tags = ''
    for (let l = 0; l < lastTagEpc.length; l++) {
      tags += (tags != '' ? ',' : '') + lastTagEpc[l]
    }

    try {
      const result: any = await SimpleItemSessionList.decodeAndCreate(
        sessionListId,
        this.operation.id,
        'encoding-imbustatrici',
        tags,
        AppStore.defaultWorkstation ? AppStore.defaultWorkstation.id : '',
      )
      const success = result.success
      const tmpIdentifiers = this.getItemIdentifiers(success, result.item)
      if (success) {
        this.setState({ lastItem: result.item }, this.executeUpdateContent)
      } else {
        Sounds.error()
        this.removeReaderTag(lastTagEpc)
        this.setState(
          {
            identifiers: tmpIdentifiers,
            associationStatus: 2,
          },
          this.resetRead,
        )
      }
    } catch (error) {
      Sounds.error()
      showToastError(error, __(T.error.error), true)
      this.reset()
    }
  }

  getItemIdentifiers = (success, item) => {
    const tmpIdentifiers = this.state.identifiers
    const identifiers = item ? item.identifiers || [] : []

    const checkPendant = !success ? this.checkIdentifier(identifiers, 'UHF_TAG', PENDANT_ROLE) : 1
    const checkEmbedded = !success ? this.checkIdentifier(identifiers, 'UHF_TAG', EMBEDDED_ROLE) : 1
    //in ogni caso modificare identifiers con stato success parziale in base al role
    for (let l = 0; l < tmpIdentifiers.length; l++) {
      if (tmpIdentifiers[l].role === PENDANT_ROLE) tmpIdentifiers[l].status = checkPendant === 1 ? 1 : 2
      else if (tmpIdentifiers[l].role === EMBEDDED_ROLE) tmpIdentifiers[l].status = checkEmbedded === 1 ? 1 : 2
    }
    return tmpIdentifiers
  }

  executeUpdateContent = async () => {
    const { lastItem, sessionId, deleteItems, forceUpdateContent, checkCountry, checkShowCase } = this.state
    try {
      const tmpIdentifiers = this.getItemIdentifiers(true, lastItem)
      const res: any = await SimpleItemSessionList.updateContent(
        sessionId,
        deleteItems ? 'REMOVE' : 'INSERT',
        [lastItem.id],
        { force: forceUpdateContent, checkCountry: checkCountry, checkShowcases: checkShowCase },
      )
      if (res.attributes && res.attributes.forceRequired === 'X') {
        const ok = await askUserConfirmation(
          res.attributes && res.attributes.forceMessage ? res.attributes.forceMessage : __(T.misc.attention),
          res.attributes && res.attributes.message ? res.attributes.message : __(T.messages.force_update_content),
          __(T.misc.no),
          __(T.misc.yes),
        )
        if (ok && !forceUpdateContent) {
          this.setState({ forceUpdateContent: true }, this.executeUpdateContent)
        } else {
          this.reset()
        }
      } else {
        this.confirmUpdateContent(res, tmpIdentifiers)
      }
    } catch (error) {
      showToastError(error, __(T.error.error))
      this.reset()
    }
  }

  confirmUpdateContent = async (res, tmpIdentifiers) => {
    let t = 0
    const { lastTagEpc, deleteItems, detected, pendingEpcs, lastItem, umg, detectedUmg } = this.state
    const tag = lastTagEpc[lastTagEpc.length - 1]
    const messageType = res.attributes && res.attributes.messageType ? res.attributes.messageType : 'E'
    if (messageType !== 'E') {
      const items = this.state.items
      if (!deleteItems) {
        if (!lastItem.identifiers || lastItem.identifiers.length === 0) {
          if (!lastItem.identifiers) lastItem.identifiers = []
          for (t = 0; t < lastTagEpc.length; t++) {
            lastItem.identifiers.push({ code: lastTagEpc[t] })
          }
        }
        items.push({ item: lastItem })
      } else {
        let found
        let i = 0
        while (i < items.length && !found) {
          if (items[i].item && items[i].item.identifiers) {
            found = items[i].item.identifiers.find((idt) => idt.code === tag)
            if (found) {
              items.splice(i, 1)
            }
          }
          i++
        }
      }

      let counter = detected
      let counterUmg = detectedUmg
      if (res.sessionList && res.sessionList.externalSystemResponse) {
        counter = 0
        counterUmg = 0
        const wam = res.sessionList.externalSystemResponse.Wam
          ? res.sessionList.externalSystemResponse.Wam.item || []
          : []
        let menge
        for (let v = 0; v < wam.length; v++) {
          menge = parseInt(wam[v].Menge ? wam[v].Menge : 0, 10)
          if (wam[v].Lenum && wam[v].Lenum.indexOf(umg) !== -1) {
            counterUmg += menge
          }
          counter += menge
        }
        // added beacause SAP not return updated WAMs list
        //counter = counter + (deleteItems ? -1 : 1)
      } else {
        counterUmg = detectedUmg + (deleteItems ? -1 : 1)
        counter = counter + (deleteItems ? -1 : 1)
      }
      /*else if (res.attributes) {
        counter = res.attributes.totalItems || 0
      } else {
        counter = counter + (deleteItems ? -1 : 1)
      }
      */

      if (messageType === 'W') {
        showToast({
          title: __(T.misc.warning),
          description: res.attributes.message,
          status: 'warning',
        })
      }

      for (t = 0; t < lastTagEpc.length; t++) pendingEpcs.push(lastTagEpc[t])
      if (!deleteItems) {
        await AppStore.increaseDailyEncodedItems(this.operation.code)
      }
      Sounds.success()
      this.setState(
        {
          items: items,
          detected: counter,
          identifiers: tmpIdentifiers,
          associationStatus: 1,
          pendingEpcs: pendingEpcs,
          detectedUmg: counterUmg,
        },
        this.resetRead,
      )
    } else {
      Sounds.error()
      this.removeReaderTag(lastTagEpc)
      // errore sap
      this.setState({
        identifiers: tmpIdentifiers,
        associationStatus: 2,
        pendingEpcs: pendingEpcs,
      })
      showToastError(res.attributes.message || 'Unknown error', __(T.error.error), true, this.onCloseError)
    }
  }

  reset = () => {
    const { lastTagEpc } = this.state
    this.inBatch = false
    this.removeReaderTag(lastTagEpc)
    this.setState(
      {
        identifiers: [],
        associationStatus: 0,
        forceUpdateContent: false,
        lastItem: undefined,
        lastTagEpc: [],
      },
      this.restartAntenna,
    )
  }

  removeReaderTag = (tags) => {
    //si rimuovono i tag letti dal reader
    if (tags.length > 0) {
      RfidReader.removeTags(tags)
    }
  }

  onCloseError = () => {
    this.resetRead()
  }

  destroyResetRead = () => {
    if (!this.timerReset) return
    clearTimeout(this.timerReset)
    this.timerReset = undefined
  }

  resetRead = () => {
    if (this.operation.resetReadingsAfterOperation > 0) {
      this.timerReset = setTimeout(() => {
        this.inBatch = false
        this.setState(
          {
            identifiers: [],
            associationStatus: 0,
            forceUpdateContent: false,
            lastItem: undefined,
          },
          this.restartAntenna,
        )
      }, this.operation.resetReadingsAfterOperation || 500)
    }
  }

  restartAntenna = async () => {
    if (this.antennaRef && this.antennaRef.current) {
      await this.antennaRef.current.startReader()
    }
  }

  onClear = async () => {
    const ok = await askUserConfirmation(__(T.misc.attention), __(T.misc.clear_items))
    if (ok) {
      RfidReader.clear()
      this.setState({
        items: [],
        identifiers: [],
        associationStatus: 0,
        forceUpdateContent: false,
        lastItem: undefined,
        expectedUmg: 0,
        detectedUmg: 0,
        umg: '',
        umgResetCounter: this.state.umgResetCounter + 1,
        umgVerified: false,
        umgVerifying: false,
      })
    }
  }

  changeUmg = async (txt) => {
    if (txt !== this.state.umg && !this.state.umgVerifying) {
      if (this.antennaRef && this.antennaRef.current) {
        await this.antennaRef.current.stopReader()
      }
      this.setState({
        umg: txt,
        umgVerified: false,
        umgVerifying: false,
        items: [],
        detectedUmg: 0,
        expectedUmg: 0,
      })
    }
  }

  doVerifyUmg = () => {
    this.setState(
      {
        umgVerifying: true,
        processing: true,
      },
      this.verifyUmg,
    )
  }

  verifyUmg = async () => {
    const { umg, checkCountry, checkShowCase, reloadUmgsCounter } = this.state
    if (umg === '') {
      this.setState({
        umgVerifying: false,
        processing: false,
      })
      showToastError(__(T.error.item_session_list_create_session), __(T.error.error), true)
      return
    }
    try {
      const result = await SimpleItemSessionList.create_session(
        this.locationState.formData.sessionListId,
        umg,
        '', //description
        { checkCountry: checkCountry, checkShowcases: checkShowCase },
      )
      if (result && result.id) {
        if (!RfidReader.isReading()) {
          RfidReader.start()
        }

        const itemsDetected = await this.getDetectedItem(this.locationState.formData.sessionListId, result.id)
        let itm
        let qty = 0
        let detected = 0
        let expected = 0
        const items: any[] = []
        if (result.externalSystemResponse) {
          expected = result.externalSystemResponse.CapiMdc || 0
        }
        if (result.sessionList && result.sessionList.externalSystemResponse) {
          const wam = result.sessionList.externalSystemResponse.Wam
            ? result.sessionList.externalSystemResponse.Wam.item || []
            : []
          for (let v = 0; v < wam.length; v++) {
            if (wam[v].Lenum && wam[v].Lenum.indexOf(umg) !== -1) {
              qty = parseInt(wam[v].Menge, 10) || 1
              detected += qty
              itm = itemsDetected.find((el) => el.attributes?.wam === wam[v].Zwam)
              if (itm) {
                for (let l = 0; l < qty; l++) {
                  items.push({ item: itm })
                }
              } else {
                for (let l = 0; l < qty; l++) {
                  items.push({
                    item: {
                      fakeItemID: true,
                      attributes: {
                        wam: wam[v].Zwam || '',
                      },
                      identifiers: [],
                      product: {
                        id: undefined,
                        attributes: {},
                      },
                      status: undefined,
                    },
                  })
                }
              }
            }
          }
        }
        this.setState({
          umgVerifying: false,
          umgVerified: true,
          processing: false,
          sessionId: result.id,
          reloadUmgsCounter: reloadUmgsCounter + 1,
          items: items,
          detectedUmg: detected,
          expectedUmg: expected,
        })
      } else {
        showToastError(__(T.error.item_session_list_create_session), __(T.error.error), true)
        this.setState({
          umgVerifying: false,
          processing: false,
        })
      }
    } catch (error) {
      this.setState({
        umg: '',
        umgResetCounter: this.state.umgResetCounter + 1,
        umgVerified: false,
        umgVerifying: false,
        processing: false,
      })
      showToastError(error, __(T.error.error), true)
    }
  }

  _onChangeCheckCountry = () => {
    this.setState({ checkCountry: !this.state.checkCountry })
  }

  _onChangeCheckShowCase = () => {
    this.setState({ checkShowCase: !this.state.checkShowCase })
  }

  _onChangeDeleteItems = () => {
    const { pendingEpcs } = this.state
    if (pendingEpcs.length > 0) {
      RfidReader.removeTags(pendingEpcs)
    }
    this.setState({ deleteItems: !this.state.deleteItems, pendingEpcs: [] })
  }

  goBack = () => {
    if (this.formSchema.length) {
      Router.navigate('/simple-item-session-list/encodingInboundSap/:opCode', {
        opCode: this.operation.code,
      })
    } else {
      Router.navigate('/')
    }
  }

  getHeaderRight = () => {
    const { deleteItems, antennaOn } = this.state
    return (
      <>
        <BoxDelete>
          <ToggleField onChange={() => this._onChangeDeleteItems()} checked={deleteItems} deleteIcon />
        </BoxDelete>
        <EncodingDailyItems code={this.operation.code} style={{ minWidth: 100, marginLeft: 10 }} />
        <Box bg="#D8D8D8" width={1} height={50} ml={15} />
        <AntennaStatus started={antennaOn} />
      </>
    )
  }

  getIdentifierMessage = (status, association) => {
    if (status === 2) {
      return __(T.misc.error_tag)
    } else if (status === 1) {
      return __(T.misc.confirmed)
    }
    return association ? '' : __(T.misc.reading)
  }

  getTagLayout = () => {
    return {
      size: '12pt',
      lineHeight: '14pt',
      mb: '5px',
      mr: '5px',
      sizeSub: '10pt',
      mrIcon: 5,
      padding: '10px 5px',
    }
  }

  getCounterLayout = () => {
    return {
      spacerHeight: 10,
      sizeText: '16px',
      lineHeightText: '20px',
      size: '64px',
      interline: '78px',
      minHeight: '100px',
    }
  }

  _confirmParcel = async () => {
    if (this.antennaRef && this.antennaRef.current) {
      await this.antennaRef.current.stopReader()
    }
    this.setState({ processing: true }, this._doConfirmParcel)
  }

  _doConfirmParcel = async () => {
    const { items, sessionId, reloadUmgsCounter, umgResetCounter, detected } = this.state

    const itemIds: string[] = []
    for (let i = 0; i < items.length; i++) {
      itemIds.push(items[i].item.id)
    }

    try {
      const result = await SimpleItemSessionList.confirm_session(sessionId, itemIds)
      if (result && result.id) {
        this.setState(
          {
            items: [],
            sessionId: '',
            pendingEpcs: [],
            umg: '',
            umgResetCounter: umgResetCounter + 1,
            umgVerified: false,
            umgVerifying: false,
            reloadUmgsCounter: reloadUmgsCounter + 1,
            processing: false,
            lastTagEpc: [],
            deleteItems: false,
            associationStatus: 0,
            identifiers: [],
            forceUpdateContent: false,
            lastItem: undefined,
            expectedUmg: 0,
            detectedUmg: 0,
          },
          this._view,
        )
      } else {
        showToastError(__(T.error.item_session_list_confirm), __(T.error.error), true)
        this.setState({ processing: false })
      }
    } catch (error) {
      showToastError(error, __(T.error.error), true)
      this.setState({ processing: false })
    }
  }

  _view = () => {
    const { formData, detected } = this.state
    Router.navigate(
      '/simple-item-session-list/encodingInboundSap/:opCode/view',
      { opCode: this.operation.code },
      { state: { formData: formData, detected: detected } },
    )
  }

  render() {
    const {
      items,
      formData,
      loading,
      processing,
      umgVerified,
      umgVerifying,
      checkCountry,
      checkShowCase,
      umgResetCounter,
      expected,
      detected,
      associationStatus,
      identifiers,
      detectedUmg,
      expectedUmg,
    } = this.state
    const tagLayout = this.getTagLayout()
    return (
      <Page
        title={this.operation.description}
        onBackPress={() => this.goBack()}
        loading={loading}
        forceDetails="top"
        header={{
          details: {
            data: formData,
            formSchema: this.formSchema,
          },
        }}
        headerRight={this.getHeaderRight()}
        enableEmulation
      >
        <Box flex row>
          <Page.Sidebar width={400} style={{ paddingRight: 15 }}>
            <EncodingInboundSapHeader
              onChange={this.changeUmg}
              onEnter={this.doVerifyUmg}
              umgResetCounter={umgResetCounter}
              umgVerified={umgVerified}
              umgVerifying={umgVerifying}
              checkCountry={checkCountry}
              onChangeCheckCountry={this._onChangeCheckCountry}
              checkShowCase={checkShowCase}
              onChangeCheckShowCase={this._onChangeCheckShowCase}
              inputLabel={__(T.fields.umg)}
            />
            <TagCounter
              detected={detectedUmg}
              //expected={expectedUmg}
              layout={this.getCounterLayout()}
              smallCounter={{ detected: detected, expected: expected }}
              title={__UP(T.custom.total_parcel)}
              smallCounterLabel={__(T.custom.total_list)}
            />
            {umgVerified && identifiers.length > 0 && (
              <Box flex width={'calc(100% + 5px)'}>
                <Box row flex>
                  {identifiers.map((element, index) => (
                    <ImbustatriceTagRow
                      iconWidth={34}
                      iconHeight={34}
                      key={'identifiers_' + index}
                      type={1}
                      status={element.status}
                      title={element.label}
                      subtitle={element.subtitle}
                      minHeight={70}
                      layout={tagLayout}
                      enabledSpinner
                    />
                  ))}
                </Box>
                <ImbustatriceTagRow
                  type={2}
                  iconWidth={34}
                  iconHeight={34}
                  status={associationStatus}
                  title={__UP(__(T.misc.association))}
                  subtitle={this.getIdentifierMessage(associationStatus, true)}
                  minHeight={70}
                  layout={{ ...tagLayout, mb: '10px' }}
                  enabledSpinner
                />
              </Box>
            )}
            <AntennaButton
              ref={this.antennaRef}
              onClear={this.onClear}
              hideClear={items.length === 0}
              disabled={!umgVerified}
            />
          </Page.Sidebar>
          <Box flex bg={'#FFF'}>
            <Page.Content notBoxed style={{ margin: 0 }}>
              <Box ml={15} mr={15} mt={15} flex>
                <OperationReadingList
                  extension={SimpleItemSessionListExtensions}
                  items={items}
                  operation={this.operation}
                />
              </Box>
              {items.length > 0 && (
                <Box m={15}>
                  <Button variant="primary" type="button" onClick={() => this._confirmParcel()}>
                    {__(T.misc.confirm_parcel)}
                  </Button>
                </Box>
              )}
            </Page.Content>
          </Box>
        </Box>
        {processing && <FullLoadingLayer message={__(T.messages.operation_in_progress)} />}
      </Page>
    )
  }
}

const BoxDelete = styled(Box)`
  border: 1px solid #dcdcdc;
  background-color: #fbfbfb;
  padding: 15px 10px 15px 7px;
  border-radius: 10px;
`

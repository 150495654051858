/* eslint-disable max-classes-per-file */
import { Component } from 'react'
import { TmrItem, ProductInfoField, AttributeMappings, TmrSimpleAttributesMap } from 'stylewhere/api'
import { Spinner } from '@chakra-ui/react'
import { Box, Card, Modal, ProductInfoBox, Spacer, ModalTitle } from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'

interface Props {
  onClose: () => void
  item: TmrItem
  visible: boolean
}

interface State {
  item?: TmrItem
  fields?: ProductInfoField[]
  attributes?: TmrSimpleAttributesMap[]
  loader: boolean
  viewProductAttribute: boolean
}

export class SgtinInfoModal extends Component<Props, State> {
  state: State = {
    item: undefined,
    fields: undefined,
    attributes: undefined,
    loader: true,
    viewProductAttribute: true,
  }

  productFields: ProductInfoField[] = [
    { path: 'attributes.barcodeBC.value', label: __(T.misc.barcode_bc) },
    { path: 'color.value', label: __(T.misc.color) },
    { path: 'material.value', label: __(T.misc.material) },
    { path: 'size.value', label: __(T.misc.size) },
    { path: 'style.value', label: __(T.misc.style) },
  ]

  async componentDidMount() {
    this.init()
  }

  init = async () => {
    const { item } = this.props

    const cols: ProductInfoField[] = await AttributeMappings.getProductAttribute(item.product, this.productFields, [
      'barcodeBC',
    ])
    const attrs: TmrSimpleAttributesMap[] = await AttributeMappings.getItemAttribute(item)

    this.setState({ fields: cols, attributes: attrs, item, loader: false })
  }

  getProductFields = () => {
    const { fields, viewProductAttribute } = this.state
    if (viewProductAttribute) {
      return fields || this.productFields
    }
    return this.productFields
  }

  managerViewProductAttribute = () => {
    this.setState({ viewProductAttribute: !this.state.viewProductAttribute })
  }

  render() {
    const { visible, onClose } = this.props
    const { attributes, loader, viewProductAttribute, item } = this.state
    return (
      <Modal size="6xl" onClose={onClose} visible={visible} fullContent showCloseButton>
        <Box flex row>
          <Box
            flex={3}
            ml={35}
            style={{
              minWidth: 0,
              height: 'calc(-70px + 100vh)',
              maxHeight: 'calc(-70px + 100vh)',
            }}
          >
            <ModalTitle title={__(T.misc.item_info)} data-testid={`identifiers-modal-title`} style={{ flex: 0, paddingTop: 20, paddingBottom: 15 }} />
            <Box
              flex
              ph={35}
              pb={0}
              pr={0}
              pl={0}
              mr={35}
              mb={20}
              style={{
                height: 'calc(80vh - 40px)',
                maxHeight: 'calc(80vh - 40px)',
                overflow: 'auto',
                overflowX: 'hidden',
              }}
            >
              {!loader && item && (
                <>
                  <ProductInfoBox
                    product={item.product}
                    fields={this.getProductFields()}
                    viewMore={viewProductAttribute}
                    paddingCard={'0px 15px 0px 0px'}
                    shadowCard={'unset'}
                  />
                  <Spacer />
                  {attributes && Array.isArray(attributes) && attributes.length > 0 && (
                    <Card mb={10} flex title="Item">
                      {attributes.map((attribute) => (
                        <ProductInfoBox.Field label={attribute.label} value={attribute.value} />
                      ))}
                    </Card>
                  )}
                </>
              )}
              {loader && (
                <Box mt={20} center>
                  <Spinner thickness="5px" speed="0.65s" color="#000" size="xl" />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    )
  }
}

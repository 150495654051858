import { ReactNode } from 'react'
import { GroupedItemListProps } from 'stylewhere/api'
import { GroupedItemRow, List } from 'stylewhere/components'
import { T, __ } from 'stylewhere/shared/i18n'
import { sortGroupeditemList, sortGroupeditemListStatus } from 'stylewhere/utils'

export function GroupedItemList({
  rows,
  loading = false,
  onRemoveItem,
  onActionItem,
  operation,
  hideProductInfo,
  disabledStartText,
  mb,
  itemZoneBase,
  configuration,
  disabledCounter,
  removeModeEnabled,
  emptyMessage,
  hideIdentifiersByType,
  hideIdentifiersZones,
  overrideProductInfoFields,
}: GroupedItemListProps) {
  const rowRender = (item, index) => {
    return (
      <GroupedItemRow
        hideProductInfo={hideProductInfo}
        key={index}
        decodedItem={item}
        operation={operation}
        onRemoveItem={onRemoveItem}
        onActionItem={onActionItem}
        mb={mb || 10}
        itemZoneBase={itemZoneBase}
        configuration={configuration}
        disabledCounter={disabledCounter}
        removeModeEnabled={removeModeEnabled}
        hideIdentifiersByType={hideIdentifiersByType}
        hideIdentifiersZones={hideIdentifiersZones}
        overrideProductInfoFields={overrideProductInfoFields}
      />
    )
  }

  if (loading) {
    return (
      <>
        <GroupedItemRow.Skeleton />
        <GroupedItemRow.Skeleton />
      </>
    )
  }

  if (disabledStartText) {
    return null
  }

  const getDataRow = () => {
    const orderedRows = operation?.newReadingsFirst
      ? [...rows].reverse()
      : rows.sort(sortGroupeditemList).sort(sortGroupeditemListStatus)
    return orderedRows
  }

  return (
    <List
      data={getDataRow()}
      emptyMessage={
        emptyMessage === null ? undefined : emptyMessage ? (
          (emptyMessage as ReactNode)
        ) : (
          <span style={{ whiteSpace: 'break-spaces' }}>{__(T.messages.press_start_to_read)}</span>
        )
      }
      rowRender={rowRender}
    />
  )
}

import { Component } from 'react'
import { OperationStart } from 'stylewhere/components'
import { EncodingExtensions } from 'stylewhere/extensions'
import {
  Router,
  FormSchemaData,
  FormSchema,
  OperationConfig,
} from 'stylewhere/shared'

interface State {
  loading: boolean
}

export default class EncodingOutboundSapStart extends Component<State> {
  state = {
    loading: false,
  }

  onSubmit = async (formData: FormSchemaData, operation: OperationConfig, schema: FormSchema) => {
    Router.navigate(
      '/encoding/encodingOutboundSap/:opCode/encode',
      { opCode: operation.code },
      { state: { formData: formData || {} } }
    )
  }

  render() {
    return (
      <OperationStart
        submitPath={'/encoding/encodingOutboundSap/:opCode/encode'}
        extensions={EncodingExtensions}
        onSubmit={this.onSubmit}
        formRowField
      />
    )
  }
}

import { Box } from 'stylewhere/components'
import styled from '@emotion/styled'
import { T, __ } from 'stylewhere/i18n'

export function RoundedLabel({
  label,
  value = '---',
  style,
  maxWidth,
  ...props
}: {
  label?: string
  value?: string | null
  style?: React.CSSProperties
  maxWidth?: number
}) {
  const getColor = () => {
    switch (value?.toLowerCase()) {
      case __(T.misc.unknown_product).toLowerCase():
      case __(T.misc.unknown_tag).toLowerCase():
        return { backgroundColor: '#FFEFC6', color: '#D6A52A' }
      case __(T.misc.invalid_tag).toLowerCase():
        return { backgroundColor: '#FFD4CB', color: '#EF4141' }
      case __(T.misc.stock).toLowerCase():
        return { backgroundColor: '#C6EAFF', color: '#3796ED' }
      case __(T.misc.valid).toLowerCase():
        return { backgroundColor: '#99FEC5', color: '#1BBA60' }

      default:
        return { backgroundColor: '#EDEDED' }
    }
  }

  if (value === '---') return <></>

  return (
    <Box
      row
      mr={15}
      style={{
        borderRadius: 5,
        marginTop: 5,
        marginBottom: 5,
        backgroundColor: getColor().backgroundColor,
        maxWidth: maxWidth,
        ...style,
      }}
      pv={5}
      ph={10}
      {...props}
    >
      {label && (
        <SmallLabel data-testid={`rounded-label-label`} style={{ color: getColor().color }}>
          {label.toUpperCase()}
        </SmallLabel>
      )}
      {value && (
        <SmallValue data-testid={`rounded-label-value`} style={{ color: getColor().color }}>
          {value ?? ' ---'}
        </SmallValue>
      )}
    </Box>
  )
}

const SmallValue = styled.label`
  font-weight: 900;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
const SmallLabel = styled.label`
  font-weight: 400;
  font-size: 16px;
  white-space: nowrap;
  margin-right: 5px;
`

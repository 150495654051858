import { Component } from 'react'
import { Box, ItemEventRow } from 'stylewhere/components'
import { TmrFullItemEvent } from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'
import styled from '@emotion/styled'

interface Props {
  events?: TmrFullItemEvent[]
}

export class ItemEventBox extends Component<Props> {
  render() {
    const { events } = this.props
    return (
      <Box
        ml={0}
        mr={0}
        pl={30}
        pr={30}
        pt={25}
        pb={25}
        mb={0}
        bgGrey
        flex={2}
        style={{
          height: 'calc(-70px + 100vh)',
          maxHeight: 'calc(-70px + 100vh)',
          overflow: 'auto',
        }}
      >
        <Title data-testid={`item-event-title`}>{__(T.misc.item_events)}</Title>
        {!events && <Subtitle data-testid={`item-event-subtitle`}>{__(T.misc.loading_data)}</Subtitle>}
        {events && events.length > 0 && (
          <>
            {events.map((event, index) => (
              <ItemEventRow key={index} event={event} first={index === 0} last={index === events.length - 1} />
            ))}
          </>
        )}
        {events && events.length === 0 && (
          <Subtitle data-testid={`item-event-subtitle`}>{__(T.misc.empty_item_events)}</Subtitle>
        )}
      </Box>
    )
  }
}

const Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: #000000;

  opacity: 0.4;
  margin-bottom: 20px;
`

const Subtitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #000000;
  opacity: 0.8;
  margin-bottom: 20px;
`

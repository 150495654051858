import { EmulatedTmrTag } from 'stylewhere/api'

export function isNewEmulationFormat(request: string): boolean {
  return request.match(/\{.*\}?/) !== null
}

// Alternative regex for handling different order combinations
const epcRegex = /epc\s*:\s*(?<epc>[^,:}]+)/gm
const tidRegex = /tid\s*:\s*(?<tid>[^,:}]+)/gm
const uidRegex = /uid\s*:\s*(?<uid>[^,:}]+)/gm

function findAllMatches(regex: RegExp, str: string, matchName: string, limit = 5): string[] {
  const matches = []
  let match,
    index = 0
  while ((match = regex.exec(str)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (match.index === regex.lastIndex) {
      regex.lastIndex++
    }

    if (match.groups[matchName]) {
      const value = match.groups[matchName].trim()
      if (value) {
        matches.push(value)
      }
    }

    index++
    if (index >= limit) {
      break
    }
  }
  return matches
}

export function isValidNewEmulationFormat(request: string): boolean {
  // First check if it's in the general format
  if (!isNewEmulationFormat(request)) {
    return false
  }

  // Split by comma and closing brace to handle multiple tag definitions
  const tagStrings = request
    .split('}')
    .filter((f) => f !== '') // rimuove gli elementi vuoti
    .map((s) => (s.endsWith('}') ? s : s + '}')) // rimette la graffa di chiusura usata per lo split
    .map((s) => s.replace(/([^{])*{/, '{')) // rimuove tutto ciò che c'è prima della graffa di apertura

  for (const tagString of tagStrings) {
    if (!tagString.trim()) continue

    // If not in valid format, do additional checks
    const epcs = findAllMatches(epcRegex, tagString, 'epc')
    const tids = findAllMatches(tidRegex, tagString, 'tid')
    const uids = findAllMatches(uidRegex, tagString, 'uid')

    let ok = false
    if (epcs.length === 1) {
      ok = true
    }
    if (uids.length === 1) {
      ok = true
    }
    if (!ok) {
      return false
    }
    if (tids.length > 1) {
      return false
    }
  }

  return true
}

export function parseNewEmulationFormat(request: string): EmulatedTmrTag[] {
  const emulations: EmulatedTmrTag[] = []

  // First check if it's in the general format
  if (!isNewEmulationFormat(request)) {
    return emulations
  }

  // Split by comma and closing brace to handle multiple tag definitions
  const tagStrings = request
    .split('}')
    .filter((f) => f !== '') // rimuove gli elementi vuoti
    .map((s) => (s.endsWith('}') ? s : s + '}')) // rimette la graffa di chiusura usata per lo split
    .map((s) => s.replace(/([^{])*{/, '{')) // rimuove tutto ciò che c'è prima della graffa di apertura

  for (const tagString of tagStrings) {
    if (!tagString.trim()) continue

    // If not in valid format, do additional checks
    const epcs = findAllMatches(epcRegex, tagString, 'epc')
    const tids = findAllMatches(tidRegex, tagString, 'tid')
    const uids = findAllMatches(uidRegex, tagString, 'uid')

    let ok = false
    if (epcs.length === 1) {
      ok = true
    }
    if (uids.length === 1) {
      ok = true
    }
    if (!ok) {
      continue
    }
    if (tids.length > 1) {
      continue
    }

    const epc = epcs[0]
    const tid = tids[0]
    const uid = uids[0]

    // Create appropriate emulation objects based on what was found
    if (epc) {
      emulations.push({ epc, tid })
    }
    if (uid) {
      emulations.push({ uid })
    }
  }

  return emulations
}

import i18n, { TOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import en from 'stylewhere/translations/en'
import it from 'stylewhere/translations/it'
import fr from 'stylewhere/translations/fr'

import { StorageKeyName } from './Storage' // import directly or fail

const resources = {
  en: { translation: en },
  it: { translation: it },
  fr: { translation: fr },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'en',
    supportedLngs: ['en', 'it', 'fr'],
    load: 'all',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: StorageKeyName('language'),
      convertDetectedLanguage: (lng: string) => {
        // remove quotes as our Storage module use JSON.stringify
        if (lng && lng.indexOf('"') !== -1) {
          lng = lng.replace(/"/g, '')
        }
        return lng
      },
    },
  })

////////////////////////////////////////////////////
// HACK: use languages auto-completion
////////////////////////////////////////////////////
interface TranslationCategory {
  [key: string]: string
}

let TRANSLATION_MAP = en

// replace object values with keys
Object.keys(en).forEach((catKey, idx) => {
  const subCat: TranslationCategory = {}
  Object.keys(en[catKey]).forEach((key) => {
    subCat[key] = `${catKey}.${key}`
  })

  if (idx === 0) {
    TRANSLATION_MAP = {} as typeof en
  }

  TRANSLATION_MAP[catKey] = subCat
})
export { TRANSLATION_MAP as T }
////////////////////////////////////////////////////

// eslint-disable-next-line @typescript-eslint/naming-convention
export const __ = (key: string, interpolationMap?: TOptions) => i18n.t(key, interpolationMap)

// eslint-disable-next-line @typescript-eslint/naming-convention
export const __UP = (key: string, interpolationMap?: TOptions) => i18n.t(key, interpolationMap).toUpperCase()

export const changeLanguage = (locale: string) => {
  i18n.changeLanguage(locale)
}

export const getLanguage = () => i18n.language

export default i18n

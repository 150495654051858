import { Component, CSSProperties } from 'react'
import styled from '@emotion/styled'

export interface Props {
  title?: React.ReactNode
  align?: 'left' | 'right' | 'center'
  style?: CSSProperties
}

export class ModalTitle extends Component<Props> {
  render() {
    const { title, align, style } = this.props
    return title ? <Title style={{ ...style, textAlign: align || 'left' }}>{title}</Title> : null
  }
}

const Title = styled.span`
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  padding: 3px;
  flex: 1;
`

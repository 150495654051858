import { Component } from 'react'
import styled from '@emotion/styled'
import { Modal, TextArea, Box, Button, Switch, Spacer, ModalTitle } from 'stylewhere/components'
import { AppStore, Emulation, isNewEmulationFormat, isValidNewEmulationFormat, parseNewEmulationFormat } from 'core/shared'
import { T, __ } from 'stylewhere/i18n'
import { askUserConfirmation } from 'stylewhere/shared/utils'

interface Props {
  title: string
  isOpen?: boolean
  onConfirm: (input?: string) => void
  onClose: () => void
  style?: any
}

interface State {
  input?: string
  defaultValue?: string
  defaultValueCounter?: number
  savedEmulationsList: Emulation[]
}

export class EmulationModal extends Component<Props, State> {
  state: State = {
    savedEmulationsList: [...AppStore.emulationList],
  }

  advancedEmulationExample = async () => {
    const { input, defaultValueCounter } = this.state

    if (input && input.trim() !== '') {
      const proceed = await askUserConfirmation('Warning', 'Do you want to clear the current emulation?', 'No', 'Yes')
      if (!proceed) return
    }
    const emulationText = `{epc:20200202,tid:202200202,uid:UID2},\n{epc:EPC2,tid:TID2,uid:UID2}`
    this.setState({ defaultValue: emulationText, defaultValueCounter: defaultValueCounter ? defaultValueCounter + 1 : 1 })
  }

  saveEmulate = async () => {
    const { input } = this.state
    const name = prompt('Insert emulation name', '')
    if (!name || !input) return

    // eslint-disable-next-line react/no-access-state-in-setstate
    const newEmulations = [...this.state.savedEmulationsList].concat({ name, value: input })
    await AppStore.saveEmulationList(newEmulations)
    this.setState({ savedEmulationsList: newEmulations })
  }

  deleteEmulate = async (name: string) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const filteredEmulations = this.state.savedEmulationsList.filter((em) => em.name !== name)
    await AppStore.saveEmulationList(filteredEmulations)
    this.setState({ savedEmulationsList: filteredEmulations })
  }

  onSubmit = (text?: string) => {
    const emulationText = text ?? this.state.input
    if (isNewEmulationFormat(emulationText)) {
      this.props.onConfirm(String(emulationText).trim())
    } else {
      const cleaned = String(emulationText)
        .trim()
        .replace(/ /g, '')
        .replace(/"/g, '')
        .replace(/'/g, '')
        .replace(/;/g, ',')
        .replace(/,,/g, ',')
        .replace(/\n\r/g, ',')
        .replace(/\n/g, ',')
        .replace(/\t/g, ',')
        .trim()
      this.props.onConfirm(cleaned)
    }
  }

  render() {
    const { isOpen, onClose, title } = this.props
    const { savedEmulationsList, input, defaultValue, defaultValueCounter } = this.state

    const isNewFormat = input ? isNewEmulationFormat(input ?? '') : false
    const rowsNewFormat = isNewFormat ? parseNewEmulationFormat(input ?? '') : []
    const isNewFormatValid = isNewFormat ? isValidNewEmulationFormat(input ?? '') : true

    const hintText = 'Press [CTRL+E] to show again'
    return (
      <Modal visible={isOpen ?? true} onClose={onClose} size="3xl">
        <ModalTitle title={title} />
        <Box row>
          <Box mt={20} style={{ position: 'relative' }} flex={10}>
            <TextArea
              overrideValue={[defaultValue, defaultValueCounter]}
              placeholder={__(T.messages.paste_comma_separeted_tags)}
              onChange={(newValue) => this.setState({ input: newValue })}
              startFocus
              textAreaStyle={{ height: 300, resize: 'none' }}
              onEnter={(_value, event) => {
                if (input && isNewFormatValid) {
                  this.onSubmit()
                  if (event) event.preventDefault()
                }
              }}
            />
            <Box row style={{ position: 'absolute', right: 15, bottom: 20 }}>
              {input && (
                <>
                  <Button
                    title={__(T.misc.save_to_list)}
                    onClick={this.saveEmulate}
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                    variant="default"
                    size="small"
                    disabled={!input || !isNewFormatValid}
                  />
                  <Button
                    title="Emulate"
                    onClick={() => this.onSubmit()}
                    style={{
                      whiteSpace: 'nowrap',
                      marginLeft: 15,
                    }}
                    size="small"
                    disabled={!input || !isNewFormatValid}
                  />
                </>
              )}
              {!input && (
                <Button
                  title={'Advanced Emulation'}
                  onClick={this.advancedEmulationExample}
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                  variant="default"
                  size="small"
                />
              )}
            </Box>
          </Box>
          {!!savedEmulationsList.length && (
            <Box style={{ overflowY: 'scroll' }} height={300} width={200} mt={20} pl={15}>
              <SavedListTitle>Saved List</SavedListTitle>
              {savedEmulationsList.map((elem) => (
                <Box key={elem.name} row flex={0} center mb={10}>
                  <Button
                    style={{ marginRight: 10, flex: 1, whiteSpace: 'nowrap' }}
                    title={elem.name}
                    onClick={() => this.onSubmit(elem.value)}
                    variant="default"
                    size="small"
                  />
                  <Button
                    title="X"
                    onClick={() => {
                      this.deleteEmulate(elem.name)
                    }}
                    variant="default"
                    size="small"
                  />
                </Box>
              ))}
            </Box>
          )}
        </Box>

        <Box row mt={10} vcenter flex>
          <Button variant="default" title="Hide Emulation" onClick={onClose} size="small" />
          <Hint>{hintText}</Hint>
          {isNewFormat && <StatusBox><Status title={rowsNewFormat.map(v => `${v.epc ? ` epc: ${v.epc}` : ''}${v.tid ? ` tid: ${v.tid}` : ''}${v.uid ? ` uid: ${v.uid}` : ''}${v.ndef ? ` ndef: ${v.ndef}` : ''}`).join(";\n")} isError={!isNewFormatValid}>{rowsNewFormat.length}</Status></StatusBox>}
        </Box>
      </Modal>
    )
  }
}

const StatusBox = styled.span`
  text-align: right;
  margin-left: 15px;
  flex: 1;
`
const Status = styled.span<{isError?: boolean}>`
  background: ${({ isError }) => (isError ? '#F27D7D' : '#75EBA8')};
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
`

const Hint = styled.span`
  font-size: 19px;
  font-weight: 400;
  text-align: center;
  color: rgba(51, 51, 51, 0.52);
  margin-left: 15px;
`

const SavedListTitle = styled.span`
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 15px;
`

import { ReactNode, useCallback, useEffect, useState, useRef } from 'react'
import { Route, Switch, Router, Redirect } from 'react-router-dom'
import { ChakraProvider, Spinner } from '@chakra-ui/react'
import { ThemeProvider } from '@emotion/react'
import {
  AppStore,
  keycloak,
  OperationConfig,
  RemoteOperation,
  Router as StyleWhereRouter,
  useVersionCheck,
} from 'stylewhere/shared'
import {
  Box,
  ConfirmModal,
  Modal,
  FeedbackModal,
  UpdateNotification,
  DeviceManagerUpdateNotification,
} from 'stylewhere/components'
import { ModalType } from 'stylewhere/types'
import { theme, chakraTheme } from 'stylewhere/theme'
import { Routes } from 'stylewhere/pages'
import 'react-virtualized/styles.css'

const App: React.FC = () => {
  const [loading, setloading] = useState<boolean>(true)
  const [showConfirm, setshowConfirm] = useState<boolean>(false)
  const [showFeedback, setshowFeedback] = useState<boolean>(false)
  const [modals, setmodals] = useState<ModalType[]>([])
  const [authenticated, setauthenticated] = useState<boolean>(false)
  const [, updateState] = useState<any>()
  const [operations, setOperations] = useState<OperationConfig[]>()
  const forceUpdate = useCallback(() => updateState({}), [])

  const sessionExpiredTimeout = useRef<NodeJS.Timeout | null>(null)
  const keycloakInitialized = useRef(false)
  const appStoreLoaded = useRef(false)

  // Workaround per hot reload
  if (operations !== undefined) {
    RemoteOperation.operations = operations
  }

  // Inizializza il controllo versione
  useVersionCheck({
    getBackendVersion: AppStore.getBackendVersion.bind(AppStore),
  })

  useEffect(() => {
    async function init() {
      AppStore.toggleConfirmModalHandler = (show) => setshowConfirm(show)
      AppStore.toggleFeebackModalHandler = (show) => setshowFeedback(show)
      AppStore.openModal = (modal: ModalType) => setmodals([...modals, modal])
      AppStore.closeModal = (id: string) => {
        const modalIndex = modals.findIndex((modal) => modal.id === id)
        if (modalIndex === -1) return
        modals[modalIndex].visible = false
        setmodals(modals.filter((m) => m.visible))
      }
      AppStore.reloadRouting = () => forceUpdate()
    }
    init()
    return () => {}
  }, [forceUpdate, modals])

  useEffect(() => {
    const onAuthSuccess = async () => {
      !!sessionExpiredTimeout.current && clearTimeout(sessionExpiredTimeout.current)
      sessionExpiredTimeout.current = null

      if (!appStoreLoaded.current) {
        appStoreLoaded.current = true
        await AppStore.loadInitialData()
        setauthenticated(true)
        setloading(false)
        setOperations(RemoteOperation.operations)
      }
    }

    // Usa ref invece di uno stato perché keykloack.init schianta se chiamato due volte,
    // come avviene sempre con StrictMode
    if (!keycloakInitialized.current) {
      keycloakInitialized.current = true

      keycloak.onAuthSuccess = () => {
        onAuthSuccess()
      }

      const onError = () => {
        sessionExpiredTimeout.current = setTimeout(() => {
          setauthenticated(false)
          setloading(false)
        }, 5000)
      }
      keycloak.onAuthError = () => onError()
      keycloak.onAuthRefreshError = () => onError()

      keycloak.onTokenExpired = () => {
        // Refresh expired token
        keycloak.updateToken(5)
      }
      keycloak.onAuthRefreshSuccess = () => {
        // Refresh appstore a resume fetching notifications
        AppStore.loadAuthToken()
      }
      keycloak.init({ onLoad: 'login-required', checkLoginIframe: false })
    }
  }, [setauthenticated])

  const paths = Object.keys(Routes)
  const activeRoutes = paths.filter((path) =>
    authenticated ? !Routes[path].public || Routes[path].private : Routes[path].public,
  )

  return (
    <ChakraProvider theme={chakraTheme}>
      <ThemeProvider theme={theme}>
        {loading && (
          <Box flex center vcenter>
            <Spinner thickness="5px" speed="0.65s" color="#e0e0e0" size="xl" />
          </Box>
        )}

        {!loading && (
          <>
            {/* @ts-expect-error Server Component */}
            <Router history={StyleWhereRouter.history}>
              {/* @ts-expect-error Server Component */}
              <Switch>
                {activeRoutes.map((path) => (
                  // @ts-expect-error Server Component
                  <Route path={path} component={Routes[path].component} key={path} exact />
                ))}
                {/* @ts-expect-error Server Component */}
                <Redirect to={authenticated ? '/' : '/login'} />
              </Switch>

              <UpdateNotification />
              <DeviceManagerUpdateNotification />
            </Router>

            {modals.map((modal) => {
              if ('modal' in modal) return modal.modal as ReactNode

              return (
                <Modal
                  {...modal}
                  key={modal.id}
                  onClose={() => {
                    modal.visible = false
                    setmodals(modals.filter((m) => m.visible))
                    modal?.onClose?.()
                  }}
                >
                  {typeof modal.body === 'function' ? modal.body() : modal.body}
                </Modal>
              )
            })}

            {showConfirm && (
              <ConfirmModal
                title={AppStore.confirmModalParams.title}
                subtitle={AppStore.confirmModalParams.message}
                onClose={() => {
                  AppStore.confirmModalParams?.onCancel?.()
                  setshowConfirm(false)
                }}
                onConfirm={() => {
                  AppStore.confirmModalParams?.onConfirm()
                  setshowConfirm(false)
                }}
                labelClose={AppStore.confirmModalParams.labelClose}
                labelConfirm={AppStore.confirmModalParams.labelConfirm}
                showDetailForProduct={AppStore.confirmModalParams.showDetailForProduct}
              />
            )}
            {showFeedback && (
              <FeedbackModal
                title={AppStore.feedbackModalParam.title}
                subtitle={AppStore.feedbackModalParam.message}
                onClose={() => {
                  AppStore.feedbackModalParam?.onCancel?.()
                  setshowFeedback(false)
                }}
                btnCloseModal={AppStore.feedbackModalParam.btnCloseModal}
              />
            )}
          </>
        )}
      </ThemeProvider>
    </ChakraProvider>
  )
}

export default App

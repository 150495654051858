import { ApiErrorResponse, ApiResponse, create } from 'apisauce'
import qs from 'qs'
import { config } from 'stylewhere/config'
import { AppStore, keycloak, Router } from 'stylewhere/shared'
import { ConnectionError, CustomError, SystemError, Unauthorized } from 'stylewhere/shared/errors'
import { T, __ } from 'stylewhere/shared/i18n'
import { getCustomErrorCodes } from 'stylewhere/shared/utils'

export class BadRequestError extends Error {
  title = ''
  constructor(message, title) {
    super(message)
    this.title = title
  }
}

const DEBUG_MODE_EXCLUDES = ['api/v1/operations/inbound/startParcel']

const getError = (res: ApiResponse<any>) => {
  let error = `${(res && res.status) || 'Unknown'} Status`
  if (res && res.data) {
    const customErrorCodes = getCustomErrorCodes(res.status)
    if (res.data && Object.keys(customErrorCodes).includes(res.data.errorCode)) {
      error = customErrorCodes[res.data.errorCode]
    }
  }
  return error
}

export const getResponseErrorCheckFunc =
  (notifyError = true) =>
  <T = any>(res: ApiResponse<T, any>) => {
    if (res?.status === 0 || res?.problem === 'UNKNOWN_ERROR') {
      throw new ConnectionError(res as ApiErrorResponse<any>)
    }

    if (res && (res.status === 200 || res.status === 204)) {
      return res.data as T
    }

    const customErrorCodes = getCustomErrorCodes(res.status)
    if (res.data && Object.keys(customErrorCodes).includes(res.data.errorCode)) {
      throw new CustomError(res.data.errorCode, customErrorCodes[res.data.errorCode])
    }

    if (res.status === 500) {
      throw new SystemError(500, res.data && res.data.message ? res.data.message : 'Internal Server Error')
    }

    if (
      res.status === 400 &&
      res.config?.url?.indexOf('simpleItemSessionList') !== -1 &&
      res.config?.url?.indexOf('executeAction ') !== -1
    ) {
      throw new Error('')
    }

    if (res.data && res.data.errorCode === 'UNPACK') {
      throw new Error('UNPACK')
    }

    if (res.status === 401 && res.config?.url === '/login') {
      throw new Error('Credential error')
    }

    if (res.status === 401) {
      throw new Error('Not authorized')
    }

    if (res.status === 403) {
      throw new Unauthorized(res as ApiErrorResponse<any>)
    }

    if (res.status === 404) {
      throw new SystemError(404, res?.data?.message ?? '404 Error')
    }

    if (res?.status && res?.status >= 400) {
      const errorTranslation = __(T.serverError[res.data?.errorCode])
      if (errorTranslation) {
        throw new Error(errorTranslation)
      }

      if (res.data && res.data.payload && res.data.payload.info) {
        let message = ''
        let title = __(T.error.error)
        if (
          res.data.payload.info.log &&
          Array.isArray(res.data.payload.info.log) &&
          res.data.payload.info.log.length > 0
        ) {
          for (let l = 0; l < res.data.payload.info.log.length; l++) {
            message += (message !== '' ? ' - ' : '') + res.data.payload.info.log[l].message
          }

          if (res.data.payload.info.message) {
            title = res.data.payload.info.message
          }
        } else if (res.data.payload.info.message) {
          message = res.data.payload.info.message
        }

        if (message !== '') {
          throw new BadRequestError(message, title)
        }
      }

      const errorHasCode = res.data?.errorCode !== undefined || res.data?.messageCode !== undefined
      throw new SystemError(
        res?.status,
        `${res.data?.errorCode ?? res.data?.messageCode ?? ''}${errorHasCode ? ': ' : ''}${
          res.data?.message ?? 'Generic Error'
        }`
      )
    }

    if (res.data && res.data.error?.message) {
      throw new Error('Error')
    }

    if (!res || !res.data) {
      throw new Error('Malformed Response from server')
    }

    if (res.problem) {
      throw new Error('Error')
    }

    return res.data
  }

export const responseErrorCheck = getResponseErrorCheckFunc(true)
export const responseErrorCheckSilent = getResponseErrorCheckFunc(false)

/**
 * Apisauce params serializer adapted to TMR API
 */
const paramsSerializer = (params: any) =>
  qs.stringify(params, {
    // TMR API handles multiple values with format ?code=A&code=B, but default serializer
    // uses format ?code[]=A&code[]=B so override with option arrayFormat: repeat
    arrayFormat: 'repeat',
    // TMR API date format is unix timestamp in milliseconds
    // Note: this will not usually be used as date params are converted to timestamp
    // as soon as they're parsed from user input
    serializeDate: (Date) => Date.getTime().toString()
  })

const api = create({
  baseURL: !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? config.endpoint : config.endpoint,
  timeout: 30000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  paramsSerializer
})

//--------------------- Read Only Mode ---------------------
api.axiosInstance.interceptors.request.use(
  async (requestConfig) => {
    if (
      requestConfig.method === 'post' &&
      process.env.REACT_APP_READONLY_MODE === 'true' &&
      !DEBUG_MODE_EXCLUDES.includes(requestConfig.url ?? '')
    ) {
      console.info('Blocked request(Read only mode enabled):', requestConfig)
      return Promise.reject(new Error('Blocked by "Read only mode"'))
    }

    return requestConfig
  },
  (error) => {
    return Promise.reject(error)
  }
)

api.axiosInstance.interceptors.response.use(
  (response) => response,
  // eslint-disable-next-line consistent-return
  (error) => {
    // if on login page respond with 401
    if (error.request.responseURL.includes('/login')) {
      return Promise.reject(error)
    }
    if (error.response.status === 401) {
      // Redirect if loggedUser fail with 401
      if (error.response.config.url.includes('/loggedUser')) {
        keycloak.logout()
        AppStore.saveAuthToken().then(() => Router.refresh())
      }
    } else {
      return Promise.reject(error)
    }
  }
)

// export const translateErrorMessage = (message: string) => {
//   return message
// }

// export default create({
//   baseURL: 'https://localhost', // this is useless, each resource will define the baseUrl
//   timeout: 30000,
// })

export { api }

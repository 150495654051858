import { useEffect, useRef } from 'react'

export const useIntervalAsync = (callback: () => Promise<void>, delay: number | null, immediate = true) => {
  const savedCallback = useRef<() => Promise<void>>()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (delay === null) return

    if (immediate) {
      savedCallback.current?.()
    }

    const tick = () => {
      savedCallback.current?.()
    }

    const id = setInterval(tick, delay)
    return () => clearInterval(id)
  }, [delay, immediate])
}

import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { FC, useState } from 'react'
import { AppStore } from 'stylewhere/shared'

interface Props {
  onEmulationClick: () => void
  enabled: boolean
}

export const EmulationButton: FC<Props> = ({ onEmulationClick, enabled }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isHidden, setIsHidden] = useState(false)

  if (!enabled || !AppStore.getEmulation() || isHidden) return null

  const handleClick = () => {
    onEmulationClick()
  }

  const handleContextMenu = (e: React.MouseEvent) => {
    e.preventDefault()
    setIsMenuOpen(true)
  }

  return (
    <Menu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
      <MenuButton as={StyledButton} onClick={handleClick} onContextMenu={handleContextMenu}>
        Emulate
      </MenuButton>
      <MenuList>
        <MenuItem onClick={handleClick}>Open Emulation Modal</MenuItem>
        <MenuItem onClick={() => setIsHidden(true)}>Hide Emulation Button</MenuItem>
      </MenuList>
    </Menu>
  )
}

const StyledButton = styled.button`
  position: fixed;
  right: 10px;
  bottom: 10px;
  background: #8b5cf6;
  color: #ffffff;
  border-radius: 20px;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  box-shadow: 0 2px 10px rgba(139, 92, 246, 0.3);
  z-index: 1000;
  display: flex;
  align-items: center;
  user-select: none;

  &:hover {
    background: #7c3aed;
  }
`

import styled from '@emotion/styled'
import { Asn } from 'stylewhere/api'
import { Box, Button, ButtonVariant, Icons, KeyValueRow, PageFormDetail } from 'stylewhere/components'
import { FormSchema, FormSchemaData } from 'stylewhere/shared'
import { T, __ } from 'stylewhere/i18n'

export type HeaderDetail = {
  asn?: Asn
  data: FormSchemaData
  formSchema: FormSchema
  setFormData?: (data: FormSchemaData) => void
  resetFormData?: (data: FormSchemaData) => void
  operationId?: string
  validateForm?: (data: FormSchemaData) => any
}
export type HeaderAction = { text: string; onPress: () => void; variant?: ButtonVariant }

type TempHeaderProps = {
  title: string
  details?: HeaderDetail
  actions?: HeaderAction[]
  children?: any
  onBackPress: (() => void) | false
  rowMode?: boolean
}

export function Header({ title, details, actions, onBackPress, children, rowMode }: TempHeaderProps) {
  return (
    <Wrapper>
      <Container>
        <Box row vcenter style={{ flexShrink: 1 }}>
          {onBackPress && (
            <BackButtonContainer onClick={onBackPress}>
              <Icons.LeftArrow />
            </BackButtonContainer>
          )}
          <TitleStyle data-testid="title" data-cy={title}>
            {title}
          </TitleStyle>
        </Box>
        <RightSide vcenter row justify={'space-between'} style={{ flexGrow: 1 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginRight: 10,
              marginLeft: 10,
              borderRadius: 8,
            }}
          >
            {details && details.asn?.code && (
              <KeyValueRow
                variant="raw"
                label={__(T.misc.shipment)}
                value={details.asn?.code}
                style={{ paddingLeft: 0, paddingRight: 15 }}
              />
            )}

            {details && (
              <PageFormDetail
                rowMode={rowMode}
                changeButtonStyle={{ marginLeft: 10 }}
                detailStyle={{}}
                initData={details.data}
                formSchema={details.formSchema}
                setFormData={details.setFormData}
                resetFormData={details.resetFormData}
                operationId={details.operationId}
                validateForm={details.validateForm}
              />
            )}
          </div>
        </RightSide>
        <Box style={{ flexShrink: 1 }}>
          {actions?.map((action) => (
            <Button
              key={action.text}
              onClick={action.onPress}
              variant={action.variant}
              style={{ marginLeft: 10 }}
              size="medium"
            >
              {action.text}
            </Button>
          ))}
          {children}
        </Box>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.headerHeight};
`

const Container = styled.div`
  flex: 1;
  height: ${({ theme }) => theme.headerHeight};
  background-color: #eaeaea;
  padding: 0 ${({ theme }) => theme.padding};
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  overflow: hidden;
`

const BackButtonContainer = styled.div`
  width: 48px;
  height: 48px;
  background-color: white;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
  box-shadow: -1px 1px 6px #00000024;
`

const TitleStyle = styled.h1`
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  padding-left: 20px;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
`

const RightSide = styled(Box)`
  flex: 1;
  justify-content: right;
  height: -webkit-fill-available;
`

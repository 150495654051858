import React, { Component } from 'react'
import {
  AntennaButton,
  Page,
  Spacer,
  TagCounter,
  Box,
  Button,
  OperationReadingCounters,
  OperationReadingList,
  Stoplight,
  FullLoadingLayer,
  RemoveMode,
} from 'stylewhere/components'
import { config } from 'stylewhere/config'
import { DecodedItem, TransferToZone } from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'
import {
  Router,
  RemoteOperation,
  TransferToZoneOperationConfig,
  OperationReadingProps,
  OperationReadingState,
  AppStore,
  RfidReader,
  getDataFromSchema,
  OperationReadingProvider,
} from 'stylewhere/shared'
import { TransferToZoneExtensions } from 'stylewhere/extensions'
import {
  showToast,
  showToastError,
  filterErrorItems,
  filterIgnoredItems,
  filterIgnoredAndErrorItems,
  askUserConfirmation,
  hasAsyncConfirm,
  RETRY_SSE_TIME,
  getSseEndpoint,
  isModalError,
} from 'stylewhere/utils'

interface State extends OperationReadingState {
  confirming: boolean
  lastAsyncID: string
  sseStatus: boolean
  retrySseCount: number
  removeModeEnabled: boolean
}

export default class TransferToZoneReading extends Component<OperationReadingProps<State>, State> {
  antennaRef
  matchParams = Router.getMatchParams(this.props)
  locationState = Router.getLocationState<State>(this.props)
  operation = RemoteOperation.getOperationConfig<TransferToZoneOperationConfig>(this.matchParams.opCode)
  formSchema = TransferToZoneExtensions.formSchema(this.operation)
  sse: any
  isModal = false
  operationRemoveModeAllowed = this.operation.removeMode && ['sku', 'rfid'].includes(this.operation.removeMode)
  isRemoveModeBySku = this.operation.removeMode === 'sku'
  isRemoveModeByRfidRead = this.operation.removeMode === 'rfid'

  constructor(props) {
    super(props)
    this.antennaRef = React.createRef()
  }

  state: State = {
    items: [],
    loading: true,
    formData: this.locationState.formData ?? {},
    confirming: false,
    lastAsyncID: '',
    sseStatus: false,
    retrySseCount: 0,
    removeModeEnabled: false,
  }

  componentDidMount() {
    this.isModal = isModalError(this.operation)
    RfidReader.setBatchInterval(this.operation.batchInterval)
    RfidReader.setBatchIntervalTagCount(this.operation.batchIntervalTagCount)
    RfidReader.setBatchIntervalTime(this.operation.batchIntervalTime)
    RfidReader.setAutomaticStop(this.operation.autostopAntennaTimeout > 0)
    RfidReader.setAutomaticStopTime(this.operation.autostopAntennaTimeout)
    OperationReadingProvider.init(this.operation, this.locationState, this.goBack, this.setRfidReaderDecode)
    this.setState({ sseStatus: !hasAsyncConfirm(this.operation) }, this.attachSse)
  }

  componentWillUnmount = () => {
    this.destroySse()
  }

  attachSse = async () => {
    if (!hasAsyncConfirm(this.operation) || !AppStore.loggedUser) {
      return
    }

    const sseUrl = getSseEndpoint(config)
    this.sse = new EventSource(sseUrl, { withCredentials: false })
    this.sse.onerror = () => {
      this.setState({ sseStatus: false, retrySseCount: this.state.retrySseCount + 1 }, this.retrySse)
    }
    this.sse.onopen = (event) => {
      this.setState({ sseStatus: true, retrySseCount: 0 })
    }
    this.sse.onmessage = (event) => {
      this.sseEventMessage(event)
    }
  }

  sseEventMessage = async (event) => {
    const { lastAsyncID } = this.state
    const eventData = event.data ? JSON.parse(event.data) : undefined
    if (eventData && eventData.data) {
      if (eventData.data.asyncExecutionId === lastAsyncID) {
        if (eventData.data.executionStatus === 'OK') {
          try {
            const res = await TransferToZone.getById(eventData.data.details.operationInstanceId)
            this._confirmSuccess(res)
          } catch (error) {
            this._confirmSuccess()
          }
        } else {
          this._confirmFailed(eventData.data.details || '')
        }
      }
    }
  }

  retrySse = () => {
    this.destroySse()
    if (this.state.retrySseCount === 1) {
      this.attachSse()
    } else {
      setTimeout(() => {
        this.attachSse()
      }, RETRY_SSE_TIME)
    }
  }

  destroySse = () => {
    if (this.sse) {
      this.sse.close()
      this.sse = undefined
    }
  }

  getDecodeRequest = () => {
    const decodePayload = getDataFromSchema(this.state.formData, this.formSchema)
    decodePayload.operationId = this.operation.id
    return {
      url: TransferToZone.batchValidateEndpoint(),
      payload: decodePayload,
    }
  }

  setRfidReaderDecode = () => {
    RfidReader.setOnDecodedItemCallback(this.onDecodedItemCallback, this.getDecodeRequest())
  }

  onDecodedItemCallback = async (itemMapFromReader: { [tagCode: string]: DecodedItem }) => {
    const { items, formData, removeModeEnabled } = this.state

    if (removeModeEnabled && this.isRemoveModeByRfidRead) {
      const itemsToRemove = Object.values(itemMapFromReader)
      itemsToRemove.forEach((item) => this.removeItem(item))
    } else {
      const itemsUpdated = await OperationReadingProvider.processDecodedItems(
        this.operation,
        itemMapFromReader,
        items,
        formData,
        TransferToZoneExtensions
      )
      this.setState({ items: itemsUpdated })
    }
  }

  removeItem = (decodedItem: DecodedItem) => {
    const items = OperationReadingProvider.removeItem(decodedItem, this.state.items)
    this.setState({ items })
  }

  initRemoveModeByRfid = () => {
    if (this.isRemoveModeByRfidRead) {
      RfidReader.clear()
    }
  }

  onConfirm = async () => {
    const { items } = this.state
    if (items.filter(filterErrorItems).length > 0) {
      showToastError(__(T.error.confirm_not_allowed_with_errors), __(T.error.error), true)
    } else {
      this.setState({ confirming: true }, this._confirm)
    }
  }

  _confirm = async () => {
    const { items, formData } = this.state
    const asyncJobs = hasAsyncConfirm(this.operation)
    try {
      if (this.antennaRef && this.antennaRef.current) {
        await this.antennaRef.current.stopReader()
      }
      const confirmData = getDataFromSchema(formData, this.formSchema)
      try {
        await TransferToZoneExtensions.beforeConfirm(this.operation, confirmData, items)
      } catch (error) {
        this.setState({ confirming: false })
        return
      }

      if (!confirmData.originPlaceId) {
        if (!AppStore.defaultWorkstation?.placeId) throw new Error('Missing workstation place')
        confirmData.originPlaceId = AppStore.defaultWorkstation?.placeId
      }

      const result: any = await TransferToZone.confirm(
        {
          ...confirmData,
          operationId: this.operation.id,
          //originPlaceId: AppStore.defaultWorkstation.placeId,
          itemIds: items.filter(filterIgnoredAndErrorItems).flatMap(({ item }) => (item?.id ? item.id : [])),
        },
        asyncJobs
      )
      if (asyncJobs) {
        if (result && result.asyncExecutionId) {
          this.setState({ lastAsyncID: result.asyncExecutionId })
        } else {
          this._confirmFailed()
        }
      } else {
        this._confirmSuccess(result)
      }
    } catch (err) {
      this.setState({ confirming: false })
      showToastError(err, __(T.error.error), this.isModal)
    }
  }

  _confirmFailed = (message?: string) => {
    showToastError(message && message != '' ? message : __(T.error.operation_confirm_error), __(T.error.error), true)
    this.setState({ confirming: false })
  }

  _confirmSuccess = async (result?) => {
    const { formData } = this.state
    const confirmData = getDataFromSchema(formData, this.formSchema)
    await TransferToZoneExtensions.afterConfirm(this.operation, confirmData, result || {})
    this.setState({ confirming: false })
    showToast({
      title: __(T.misc.success),
      description: __(T.messages.generic_success, { code: this.operation.description }),
      status: 'success',
    })
    if (['hideSuccessModal', 'disabled'].includes(this.operation.postConfirmAction)) {
      this.goBack()
    } else {
      if (
        await askUserConfirmation(
          __(T.confirm.post_confirm_action_title),
          this.operation.postConfirmAction === 'keepInput'
            ? __(T.confirm.post_confirm_action_keep_input)
            : __(T.confirm.post_confirm_action_change_input),
          __(T.misc.no),
          __(T.misc.yes)
        )
      ) {
        if (this.operation.postConfirmAction === 'keepInput') {
          this.onClear()
        } else {
          this.goBack()
        }
      } else {
        this.goDashboard()
      }
    }
  }

  goDashboard = () => {
    Router.navigate('/')
  }

  goBack = () => {
    let backForm = this.formSchema.length > 1
    if (this.formSchema.length === 1) {
      backForm = !this.formSchema[0].directSubmit
    }

    if (backForm) {
      let path = '/transfer-to-zone/'
      if (this.operation.templatePath && this.operation.templatePath !== '') path += this.operation.templatePath + '/'
      //@ts-ignore
      Router.navigate(path + ':opCode', { opCode: this.operation.code })
    } else {
      this.goDashboard()
    }
  }

  onClear = () => {
    RfidReader.clear()
    this.setState({ items: [] })
  }

  showConfirmButton() {
    const { items, confirming } = this.state
    if (items.filter(filterIgnoredItems).length > 0)
      return <Button loading={confirming} title={__(T.misc.confirm)} onClick={this.onConfirm} />
    return null
  }

  isFormChangeActive = () => {
    let active = this.formSchema.length > 1
    if (this.formSchema.length === 1) {
      active = !this.formSchema[0].directSubmit
    }
    return active
  }

  onRemoveChange: (checked: boolean) => void = (removeModeEnabled) => {
    this.setState({ removeModeEnabled }, this.initRemoveModeByRfid)
  }

  render() {
    const { items, formData, loading, sseStatus, removeModeEnabled } = this.state
    return (
      <Page
        title={this.operation.description}
        headerRight={
          <>
            {hasAsyncConfirm(this.operation) ? <Stoplight ml={10} active={sseStatus} /> : undefined}
            {this.operationRemoveModeAllowed && <RemoveMode onRemoveChange={this.onRemoveChange} />}
          </>
        }
        onBackPress={() => this.goBack()}
        alertOnBackPress={this.operation.showAlertOnExit && items.length > 0}
        loading={loading}
        header={{
          details: this.isFormChangeActive()
            ? {
                data: formData,
                formSchema: this.formSchema,
                operationId: this.operation.id,
                setFormData: async (fd) => {
                  if (!(await TransferToZoneExtensions.formDataIsValid(fd, this.operation, this.formSchema))) return
                  this.setState({ formData: fd })
                  this.setRfidReaderDecode()
                },
                resetFormData: async (fd) => {
                  this.setState({ formData: fd })
                },
              }
            : undefined,
        }}
        enableEmulation
      >
        <Page.Sidebar>
          <Box flex style={{ overflowY: 'auto' }}>
            <TagCounter detected={items.filter(filterIgnoredItems).length} />
            <AntennaButton
              ref={this.antennaRef}
              decodeRequest={this.getDecodeRequest()}
              onItemDecoded={this.onDecodedItemCallback}
              onClear={this.onClear}
              hideClear={items.length === 0}
            />
            <Spacer />
            <OperationReadingCounters operation={this.operation} items={items} />
          </Box>
          {this.showConfirmButton()}
        </Page.Sidebar>
        <Page.Content notBoxed>
          <OperationReadingList
            removeItemCallback={this.removeItem}
            extension={TransferToZoneExtensions}
            items={items}
            operation={this.operation}
            removeModeEnabled={removeModeEnabled && this.isRemoveModeBySku}
          />
        </Page.Content>
        {!sseStatus && (
          <FullLoadingLayer
            message={!sseStatus ? __(T.messages.connection_in_progress) : __(T.messages.operation_in_progress)}
          />
        )}
      </Page>
    )
  }
}

class SystemError extends Error {
  errorCode: any
  constructor(errorCode: number, message: string) {
    super()
    this.name = 'SystemError'
    this.errorCode = errorCode
    this.message = message
  }
}

export default SystemError

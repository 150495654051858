/* eslint-disable max-classes-per-file */
import React, { Component } from 'react'
import {
  TmrItem,
  ProductInfoField,
  TmrFullItemEvent,
  AttributeMappings,
  TmrSimpleAttributesMap,
  ItemEvents,
  Items,
  IdentifierType,
} from 'stylewhere/api'
import { Spinner } from '@chakra-ui/react'
import {
  Box,
  Card,
  IdentifierRow,
  Modal,
  ProductInfoBox,
  Spacer,
  ItemEventBox,
  ModalTitle,
} from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'
import { ItemComponentRow } from '../ItemComponentRow'

interface Props {
  onClose: () => void
  item?: TmrItem
  itemId?: string
  visible: boolean
  hideIdentifiersByType?: IdentifierType[]
  overrideProductInfoFields?: ProductInfoField[]
  sortIdentifiersBy?: string[]
  showIdentifierStatus?: boolean
  excludeAttributeMappings?: string[]
  skipIdentifierFiltering?: boolean
  customItemAttributes?: (item?: TmrItem) => TmrSimpleAttributesMap[]
}

interface State {
  item?: TmrItem
  events?: TmrFullItemEvent[]
  fields?: ProductInfoField[]
  attributes?: TmrSimpleAttributesMap[]
  componentAttributes?: TmrSimpleAttributesMap[][]
  loader: boolean
  viewProductAttribute: boolean
}

export class ItemInfoModal extends Component<Props, State> {
  state: State = {
    item: undefined,
    events: undefined,
    fields: undefined,
    attributes: undefined,
    componentAttributes: undefined,
    loader: true,
    viewProductAttribute: false,
  }

  productFields: ProductInfoField[] = [
    { path: 'color.value', label: __(T.misc.color) },
    { path: 'material.value', label: __(T.misc.material) },
    { path: 'size.value', label: __(T.misc.size) },
    { path: 'style.value', label: __(T.misc.style) },
  ]

  async componentDidMount() {
    this.init()
  }

  init = async () => {
    const { item, itemId, excludeAttributeMappings, customItemAttributes } = this.props

    let ret
    if (item) {
      ret = item
    } else if (itemId) {
      ret = await Items.get<TmrItem>(itemId).then((res) => res)
    }

    if (ret && ret.id) {
      const excludeParams = excludeAttributeMappings || ['barcodeBC']
      const cols: ProductInfoField[] = await AttributeMappings.getProductAttribute(
        ret.product,
        this.productFields,
        excludeParams,
      )

      let attrs: TmrSimpleAttributesMap[] = await AttributeMappings.getItemAttribute(ret)

      if (customItemAttributes) {
        const customAttrs = customItemAttributes(ret)
        if (customAttrs && customAttrs.length > 0) {
          attrs = [...customAttrs, ...attrs]
        }
      }

      let events: any = { content: [] }
      try {
        events = await ItemEvents.get(ret.id, {
          size: 100,
          sort: 'creationDate,DESC',
        })
        if (ret.components && ret.components.length > 0) {
          const componentAttributes: TmrSimpleAttributesMap[][] =
            await AttributeMappings.getItemComponentAttributes(ret)
          this.setState({ componentAttributes })
        }
      } catch (e) {}
      this.setState({ fields: cols, attributes: attrs, item: ret, events: events ? events.content : [], loader: false })
    }
  }

  getProductFields = () => {
    const { overrideProductInfoFields } = this.props
    const { fields, viewProductAttribute } = this.state
    if (overrideProductInfoFields) {
      return overrideProductInfoFields
    }
    if (viewProductAttribute) {
      return fields || this.productFields
    }
    return this.productFields
  }

  managerViewProductAttribute = () => {
    this.setState({ viewProductAttribute: !this.state.viewProductAttribute })
  }

  render() {
    const {
      visible,
      onClose,
      hideIdentifiersByType,
      showIdentifierStatus,
      sortIdentifiersBy,
      skipIdentifierFiltering,
    } = this.props
    const { attributes, events, loader, viewProductAttribute, item, componentAttributes } = this.state
    let filteredIdentifiers = []

    if (item && item.identifiers) {
      if (skipIdentifierFiltering) {
        filteredIdentifiers = [...item.identifiers]
      } else {
        filteredIdentifiers = item.identifiers
          .filter((idf) => idf.status !== 'REPLACED_ASSOCIATION')
          .filter((idf) => !hideIdentifiersByType || !hideIdentifiersByType.includes(idf.type))
      }
      if (sortIdentifiersBy && sortIdentifiersBy.length) {
        filteredIdentifiers = [...filteredIdentifiers].sort((a, b) => {
          const aIndex = sortIdentifiersBy.indexOf(a.role)
          const bIndex = sortIdentifiersBy.indexOf(b.role)
          if (aIndex !== -1 && bIndex !== -1) return aIndex - bIndex
          if (aIndex !== -1) return -1
          if (bIndex !== -1) return 1
          return 0
        })
      }
    }

    return (
      <Modal size="6xl" onClose={onClose} visible={visible} fullContent showCloseButton>
        <Box flex row>
          <Box flex={3} ml={35} style={{ minWidth: 0 }}>
            <ModalTitle
              title={__(T.misc.item_info)}
              data-testid={`identifiers-modal-title`}
              style={{ flex: 0, paddingTop: 20, paddingBottom: 15 }}
            />
            <Box
              flex
              ph={35}
              pb={0}
              pr={0}
              pl={0}
              mr={35}
              mb={20}
              style={{
                height: 'calc(80vh - 40px)',
                maxHeight: 'calc(80vh - 40px)',
                overflow: 'auto',
                overflowX: 'hidden',
              }}
            >
              {!loader && item && (
                <>
                  <ProductInfoBox
                    product={item.product}
                    fields={this.getProductFields()}
                    showMore={!loader ? () => this.managerViewProductAttribute() : undefined}
                    viewMore={viewProductAttribute}
                    paddingCard={'0px 15px 0px 0px'}
                    shadowCard={'unset'}
                  />
                  <Spacer />
                  {attributes && Array.isArray(attributes) && attributes.length > 0 && (
                    <Card mb={10} flex title="Item">
                      {attributes.map((attribute) => (
                        <ProductInfoBox.Field label={attribute.label} value={attribute.value} />
                      ))}
                    </Card>
                  )}
                  <Card title="Identifiers">
                    {filteredIdentifiers.map((identifier, index) => (
                      <IdentifierRow
                        identifier={identifier}
                        last={index === filteredIdentifiers.length - 1}
                        showStatus={showIdentifierStatus}
                      />
                    ))}
                  </Card>
                  {componentAttributes && componentAttributes.length > 0 && (
                    <Card title={__(T.fields.item_components)}>
                      {componentAttributes.map((component, index) => (
                        <ItemComponentRow itemComponent={component} last={index === componentAttributes.length - 1} />
                      ))}
                    </Card>
                  )}
                </>
              )}
              {loader && (
                <Box mt={20} center>
                  <Spinner thickness="5px" speed="0.65s" color="#000" size="xl" />
                </Box>
              )}
            </Box>
          </Box>
          <ItemEventBox events={events} />
        </Box>
      </Modal>
    )
  }
}

import { Component } from 'react'
import { TagCounter, AntennaButton, Box } from 'stylewhere/components'
import { DecodedItem } from 'stylewhere/api'
import { filterIgnoredAndErrorItems } from 'stylewhere/shared/utils'

interface Props {
  items: DecodedItem<string>[]
  detected?: number
  unknown?: number
  clear?: () => void
  decodeRequest?: any
  onItemDecoded?: any
}

export class TagInfoBox extends Component<Props> {
  render() {
    const { items, clear, decodeRequest, onItemDecoded } = this.props
    return (
      <Box flex style={{ overflowY: 'auto' }}>
        <TagCounter detected={items.filter(filterIgnoredAndErrorItems).length} />
        <AntennaButton
          decodeRequest={decodeRequest}
          onItemDecoded={onItemDecoded}
          onClear={clear}
          hideClear={items.length === 0}
        />
      </Box>
    )
  }
}

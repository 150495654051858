import { createRef, useCallback, useEffect, useState } from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Icons, Image } from 'stylewhere/components'
import { theme } from 'stylewhere/theme'

const errorColor = '#dd4949'

interface InputProps extends Partial<HTMLInputElement> {
  onEnter?: (value: string) => void
  error?: string
  barcode?: boolean
  autoFocus?: boolean
  style?: React.CSSProperties | any
  image?: any
  onChange?: (value: string) => void
  startFocus?: boolean
  label?: React.ReactNode
  debounce?: number
  showClear?: boolean
  customIcon?: React.ReactNode
  showLoading?: boolean
  resetCounter?: number
  defaultValue?: any
  currentValue?: any
  forceClear?: boolean
  upperCase?: boolean
}

export function Input({
  onEnter,
  error,
  barcode,
  autoFocus,
  style,
  image,
  onChange,
  placeholder,
  defaultValue,
  currentValue,
  type,
  required,
  startFocus,
  label,
  disabled,
  readOnly,
  id,
  debounce,
  min,
  max,
  step,
  minLength,
  maxLength,
  showClear = true,
  customIcon,
  showLoading,
  resetCounter,
  forceClear,
  upperCase,
}: InputProps) {
  const [started, setstarted] = useState(false)
  const [value, setvalue] = useState(defaultValue ?? '')
  const [loading, setloading] = useState(false)
  const [cleared, setcleared] = useState(false)
  const [timer, settimer] = useState<any>(null)
  const [blurred, setblurred] = useState(false)
  const [counterClear, setCounterClear] = useState(0)
  const inputRef = createRef<HTMLInputElement>()

  const focus = useCallback(() => {
    !readOnly && inputRef?.current?.focus()
  }, [inputRef, readOnly])

  const clear = useCallback(() => {
    if (!inputRef.current) return
    inputRef.current.value = ''
    setvalue('')
    onChange && onChange('')
    focus()
  }, [inputRef, onChange, focus])

  useEffect(() => {
    if (currentValue !== undefined) {
      if (inputRef && inputRef.current) {
        inputRef.current.value = currentValue
      }
      setvalue(currentValue)
    }
  }, [currentValue, setvalue, inputRef])

  useEffect(() => {
    if (blurred) {
      if (!autoFocus) return
      setTimeout(() => {
        inputRef?.current?.focus()
      }, 300)
    }
    setblurred(false)
  }, [blurred, autoFocus, inputRef])

  useEffect(() => {
    inputRef?.current?.addEventListener('blur', () => setblurred(true))
  }, [setblurred, inputRef])

  useEffect(() => {
    if (startFocus && !started) {
      setstarted(true)
      focus()
    }
    if (autoFocus && value === '') {
      inputRef?.current?.focus()
    }
    if (!barcode) {
      return
    }
    if (error && !cleared) {
      clear()
      setcleared(true)
    }
    if (!error) {
      setcleared(false)
    }
  }, [error, clear, cleared, setcleared, barcode, inputRef, autoFocus, startFocus, started, focus, value])

  useEffect(() => {
    if (resetCounter && resetCounter > counterClear) {
      clear()
      setCounterClear(resetCounter)
    }
  }, [resetCounter, counterClear, clear])

  const handleChange = async (e) => {
    const newValue = upperCase ? (e.target as any).value.toUpperCase() : (e.target as any).value

    setvalue(newValue)
    if (debounce && e.key !== 'Enter') {
      clearTimeout(timer)
      settimer(
        setTimeout(() => {
          onChange && onChange(newValue)
        }, debounce)
      )
    } else {
      onChange && onChange(newValue)
    }
  }

  const handleKeyUp = async (e) => {
    if (e.key === 'Enter' && !loading) {
      setloading(true)
      setTimeout(async () => {
        onEnter && (await onEnter(value))
        setloading(false)
        focus()
        setcleared(false)
      }, debounce)
    }
  }

  const labelFocus = (e) => {
    focus()
    e.preventDefault()
  }

  const icon = image
  const valueLength = value ? value.length : 0
  const showClearIcon = showClear && !loading && !showLoading && valueLength > 0 && (forceClear || !disabled)
  return (
    <div data-testid={`input`} data-cy={label || value} style={style}>
      <Field className="float-label">
        {label && (
          <Label onClick={labelFocus} text={value || defaultValue} disabled={disabled}>
            {label}
          </Label>
        )}
        <StyledInput
          label={label}
          disabled={disabled || loading}
          readOnly={readOnly ?? false}
          defaultValue={defaultValue}
          error={error}
          ref={inputRef}
          required={required}
          placeholder={placeholder}
          onKeyUp={handleKeyUp}
          onChange={handleChange}
          type={type}
          id={id}
          name={id}
          min={min}
          max={max}
          step={step}
          minLength={minLength}
          maxLength={maxLength}
        />
        <ImageWrap>
          {icon && typeof icon === 'string' && valueLength === 0 && <RightImage src={icon} alt="" />}
          {icon && typeof icon !== 'string' && valueLength === 0 && <RightIcon>{icon}</RightIcon>}
          {showClearIcon && <Icons.Close style={{ marginRight: 5, cursor: 'pointer', width: 24 }} onClick={clear} />}
          {loading || (showLoading && <Icons.Loader style={{ width: 40 }} />)}
          {((valueLength === 0 && showClear) || !showClear) && !loading && !showLoading && customIcon
            ? customIcon
            : null}
        </ImageWrap>
      </Field>
      {error && <ErrorStyle>* {error}</ErrorStyle>}
    </div>
  )
}

export const StyledInput = styled.input<{ error: any; label?: React.ReactNode }>`
  flex: 1;
  height: 65px;
  background: #ffffff;
  width: 100%;
  border: ${({ error, readOnly }) => {
    if (readOnly) {
      return '0'
    }
    return error ? `2px solid ${errorColor}` : '2px solid #D2D2D2'
  }};
  cursor: ${({ readOnly }) => {
    if (readOnly) {
      return 'default'
    }
    return 'auto'
  }};
  border-radius: 10px;
  font-style: normal;
  font-weight: normal;
  color: #333333;
  font-size: 22px;
  line-height: 26px;
  padding-left: 15px;
  padding-right: 50px;
  ${({ label }) => label && `padding-top: 17px;`}
  :focus {
    box-shadow: ${({ readOnly }) => {
      if (readOnly) {
        return 'none'
      }
      return `0 0 0 3px rgba(47, 128, 237, 0.5)`
    }};
    outline: ${({ readOnly }) => {
      if (readOnly) {
        return 'none'
      }
      return `transparent auto 0`
    }};
    border-radius: 10px;
    border: ${({ readOnly, theme: t }) => {
      if (readOnly) {
        return '0'
      }
      return `2px solid ${t.accent}`
    }};
  }
  :disabled {
    //border: 2px solid transparent;
    //color: #000000;
    opacity: 0.5;
    padding-right: 15px;
  }
`

const ImageWrap = styled.div`
  justify-content: center;
  align-items: center;
  position: absolute;
  margin: auto;
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  right: 10px;
`

const RightImage = styled((props) => <Image {...props} />)`
  margin: 10px 5px;
  cursor: pointer;
  min-width: 24px;
`

const RightIcon = styled.div`
  svg {
    margin-right: 5px;
    cursor: pointer;
    min-width: 24px;
  }
`

const ErrorStyle = styled.div`
  margin-left: 10px;
  margin-top: 10px;
  font-weight: bold;
  color: ${errorColor};
`

const activeField = css`
  transform: translate(0, 11px) scale(0.75) !important;
  left: 15px;
  color: ${theme.foreground};
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  line-height: 21px;
`

const Field = styled.div`
  position: relative;
  margin: auto;
  flex: 1;
  display: flex;

  :focus-within label {
    ${activeField}
  }
`

const Label = styled.label<{ text?: string; disabled?: boolean }>`
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: ${({ disabled }) => (disabled ? '#666666' : '#666666')};
  line-height: 26px;
  top: 0px;
  position: absolute;
  left: 15px;
  transform: translate(0, 20px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;

  ${({ text }) => (text ? activeField : '')}
`

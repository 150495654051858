//import { AppConfiguration } from 'stylewhere/types'

export * from 'core/config'

export const merchCategories = ['abbigliamento', 'maglieria', 'accessori', 'calzature', 'pelletteria']

/*
export const config: AppConfiguration = {
  endpoint: process.env.REACT_APP_BACKEND_URL ?? 'https://api.valentino-stw-qty.temera.it/',
  keycloakUrl: process.env.REACT_APP_KEYCLOAK_URL ?? 'https://sso-dev.temera.it/',
  keycloakRealm: process.env.REACT_APP_KEYCLOAK_REALM ?? 'valentino-qty',
  keycloakClientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? 'stw-core-station',

  //datadog
  dataDogApplicationID: process.env.REACT_APP_DATA_DOG_APPLICATION_ID ?? '',
  dataDogClientToken: process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN ?? '',
  dataDogSite: process.env.REACT_APP_DATA_DOG_SITE ?? '',
  dataDogService: process.env.REACT_APP_DATA_DOG_SERVICE ?? '',
  dataDogEnv: process.env.REACT_APP_DATA_DOG_ENV ?? '',
  dataDogSessionSampleRate: process.env.REACT_APP_DATA_DOG_SITE_SESSION_SAMPLE_RATE
    ? parseInt(process.env.REACT_APP_DATA_DOG_SITE_SESSION_SAMPLE_RATE, 10)
    : 100,
  dataDogSessionReplaySampleRate: process.env.REACT_APP_DATA_DOG_SITE_SESSION_REPLAY_SAMPLE_RATE
    ? parseInt(process.env.REACT_APP_DATA_DOG_SITE_SESSION_REPLAY_SAMPLE_RATE, 10)
    : 20,
  dataDogTrackResources:
    process.env.REACT_APP_DATA_DOG_SITE_TRACK_RESOURCES &&
    process.env.REACT_APP_DATA_DOG_SITE_TRACK_RESOURCES === 'true'
      ? true
      : true,
  dataDogTrackLongTasks:
    process.env.REACT_APP_DATA_DOG_SITE_TRACK_LONG_TASKS &&
    process.env.REACT_APP_DATA_DOG_SITE_TRACK_LONG_TASKS === 'true'
      ? true
      : false,
  dataDogTrackUserInteractions:
    process.env.REACT_APP_DATA_DOG_SITE_TRACK_USER_INTERACTIONS &&
    process.env.REACT_APP_DATA_DOG_SITE_TRACK_USER_INTERACTIONS === 'true'
      ? true
      : true,
}
*/
import { ApisauceConfig } from 'apisauce'
import { Items, api, responseErrorCheck, TmrBaseResource } from 'core/api'

export class CustomItemApi extends TmrBaseResource {
  static batchDecode<T>(epcs: string[], workstationPlaceId: string, userId: string, requestConfig?: ApisauceConfig) {
    const paramDecode = {
      itemIdentifierCodes: epcs,
      sgtinFallbackAction: 'CREATE_ITEM',
      itemDecoratorId: 'itemWamDecorator',
      sgtinFallbackItemCreationPlaceId: workstationPlaceId,
      userId: userId,
    }
    return api
      .post<T>(`${Items.endpoint}/decode`, paramDecode, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }
}

import { Component } from 'react'
import { Modal, Box, Button, Input, ModalTitle } from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'

interface Props {
  title: string
  labelInput: string
  isOpen?: boolean
  onConfirm: (input?: string) => void
  onClose: () => void
}

interface State {
  input?: string
}

export class InputModal extends Component<Props, State> {
  state: State = {
    input: undefined,
  }

  onChange = (text) => {
    this.setState({ input: text })
  }

  onSubmit = () => {
    const { input } = this.state
    if (input && input !== '') {
      this.props.onConfirm(input)
    }
  }

  render() {
    const { isOpen, onClose, title, labelInput } = this.props
    return (
      <Modal visible={isOpen ?? true} onClose={onClose} size="3xl">
        <ModalTitle title={title} />
        <Box row>
          <Box mt={20} style={{ position: 'relative' }} flex={10}>
            <Input
              required={true}
              onChange={(newValue) => this.onChange(newValue)}
              id={'input_modal'}
              type={'text'}
              label={labelInput}
              autoFocus={true}
              onEnter={() => this.onSubmit()}
            />
            <Box row center mt={40}>
              <Button
                title={__(T.misc.close)}
                onClick={onClose}
                style={{
                  whiteSpace: 'nowrap',
                }}
                variant="default"
              />
              <Button
                title={__(T.misc.confirm)}
                onClick={() => this.onSubmit()}
                style={{
                  whiteSpace: 'nowrap',
                  marginLeft: 25,
                }}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    )
  }
}

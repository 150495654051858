import { Component } from 'react'
import {
  Box,
  Button,
  Icons,
  Input,
  List,
  Page,
  ShippingRow,
  ShippingStartInput,
  ParcelListRow,
} from 'stylewhere/components'
import { Router, ShippingOperationConfig, FormSchemaData } from 'stylewhere/shared'
import { showToastError } from 'stylewhere/utils'
import { Asn, AsnStatus, Shippings, ShippingSelectData, ShippingParcel } from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'

export interface Props {
  operation: ShippingOperationConfig
  onSelect: (data: ShippingSelectData) => void
  onCreate: (formData: FormSchemaData, operation: ShippingOperationConfig, schema) => void
  onClickReadParcel: () => void
  shipment: boolean
}

interface State {
  list?: ShippingSelectData[]
  search?: string
  loading: boolean
  creating: boolean
  page: number
  totalPages: number
  totalElements: number
  loadingData: boolean
}

const PAGE_SIZE = 25

export class ShippingStartListAndInput extends Component<Props> {
  state: State = {
    list: undefined,
    search: undefined,
    loading: true,
    creating: false,
    page: 0,
    totalPages: 0,
    totalElements: 0,
    loadingData: false,
  }

  isModal = false
  operation: ShippingOperationConfig

  constructor(props: Props) {
    super(props)
    this.operation = props.operation
  }

  componentDidMount() {
    this._init()    
  }

  _init = async () => {
    await this.loadData()
  }

  loadData = () => {
    this.setState({ loading: true }, this.load)
  }

  changeSearch = (search: string) => {
    this.setState({ loading: true, search, page: 0, list: [] }, this.load)
  }

  loadMore = () => {
    this.setState({ loadingData: true, page: this.state.page + 1 }, this.load)
  }

  load = async () => {
    const { operation, shipment } = this.props
    const { page, search, list } = this.state
    try {
      const tmp = list ?? [] 
      const res = await Shippings.getShippingList(shipment, PAGE_SIZE, page, operation, search)
      this.setState({ list: tmp.concat(res.content ?? []), totalPages: res.totalPages, totalElements: res.totalElements, loading: false, loadingData: false })
    } catch (error) {
      showToastError(error, __(T.error.error), this.isModal)
      this.setState({ list: [], loading: false, loadingData: false })
    }
  }

  onRowClick = (asn: ShippingSelectData) => this.props.onSelect(asn)

  shipmentRender = (data: ShippingSelectData) => {
    const { operation, shipment } = this.props
    return shipment ? (
      <ShippingRow
        hideDate={operation.type === 'OUTBOUND'}
        shipping={data as Asn}
        onClick={() => this.onRowClick(data)}
        isInbound={operation.type === 'INBOUND'}
      />
    ) : (
      <ParcelListRow
        parcel={data as ShippingParcel}
        onClick={() => this.onRowClick(data)}
        isInbound={operation.type === 'INBOUND'}
      />
    )
  }

  managerCreating = () => {
    this.setState({ creating: !this.state.creating })
  }

  addCreateButton = () => {
    const { shipment, operation } = this.props
    return (shipment && operation.canCreateShipments) || (!shipment && operation.canCreateParcels)
  }

  render() {
    const { list, loading, creating, loadingData, totalElements, totalPages, page } = this.state
    const { operation, onCreate, shipment } = this.props
    const operationType = operation.type.toLowerCase()
    const withCreateButton = this.addCreateButton()
    return (
      <Page
        title={`${operation.description ?? operationType}`}
        onBackPress={() => {
          creating ? this.managerCreating() : Router.navigate('/')
        }}
      >
        {!creating ? (
          <Page.Content notBoxed>
            <Box p={5} pb={0} mb={20} row justify={'space-between'}>
              <Input
                onChange={this.changeSearch}
                placeholder={`${shipment ? __(T.misc.shipment) : __(T.misc.parcel)}`}
                image={<Icons.Barcode />}
                style={{ width: 325 }}
                autoFocus
                debounce={500}
              />
              {!withCreateButton && <Box />}
              {withCreateButton && (
                <Button
                  title={`${shipment ? __(T.misc.new_shipment) : __(T.misc.new_parcel)}`}
                  onClick={this.managerCreating}
                />
              )}
            </Box>
            <Box p={5} flex={1} mt={0}>
              <List
                title={`${shipment ? __(T.titles.shipments) : __(T.titles.parcels)} (${totalElements})`}
                loading={loading}
                data={list}
                rowRender={this.shipmentRender}
                loadMore={this.loadMore}
                isNextPageLoading={loadingData}
                stopLoadMore={page === totalPages - 1}
              />
            </Box>
            {this.operation.startWithRfidSearch && (
              <Box p={5} pb={0} mt={40} row justify={'flex-end'}>
                <Button title={__(T.misc.read_parcel)} onClick={this.props.onClickReadParcel} />
              </Box>
            )}
          </Page.Content>
        ) : (
          <ShippingStartInput operation={this.operation} onCreate={onCreate} shipment={shipment} />
        )}
      </Page>
    )
  }
}

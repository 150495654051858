import React from 'react'
import styled from '@emotion/styled'

type Position = 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right'

interface Props {
  children: React.ReactNode
  onClick: () => void
  style?: React.CSSProperties
  title?: string
  position?: Position
}

export const FloatingButton: React.FC<Props> = ({ children, onClick, style, title, position = 'bottom-left' }) => {
  return (
    <Button title={title} onClick={onClick} style={style} position={position}>
      {children}
    </Button>
  )
}

interface ButtonProps {
  position: Position
}

const Button = styled.button<ButtonProps>`
  position: fixed;
  ${({ position }) => {
    switch (position) {
      case 'bottom-right':
        return `
          right: 24px;
          bottom: 24px;
        `
      case 'top-right':
        return `
          right: 24px;
          top: 24px;
        `
      case 'top-left':
        return `
          left: 24px;
          top: 24px;
        `
      case 'bottom-left':
      default:
        return `
          left: 24px;
          bottom: 24px;
        `
    }
  }}
  width: 56px;
  height: 56px;
  border-radius: 28px;
  background-color: #1976d2;
  color: white;
  border: none;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  z-index: 1000;

  &:hover {
    background-color: #1565c0;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`

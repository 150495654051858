import { Component } from 'react'
import { T, __ } from 'stylewhere/i18n'
import { Box, Modal, GroupedItemRow, ModalTitle } from 'stylewhere/components'
import { DecodedItem } from 'stylewhere/api'

interface Props {
  unexpectedItems: DecodedItem[]
  onClose: () => void
  visible: boolean
}

export class UnknownItems extends Component<Props> {
  updating = false

  render() {
    const { onClose, visible, unexpectedItems } = this.props
    return (
      <Modal size="6xl" onClose={onClose} visible={visible}>
        <Box vcenter row mb={20}>
          <ModalTitle title={__(T.misc.unexpected_list)} />
        </Box>
        <Box>
          {unexpectedItems.map((item, index) => (
            <GroupedItemRow key={index} decodedItem={item} mb={10} unknownHeight={90} />
          ))}
        </Box>
      </Modal>
    )
  }
}

import { Component } from 'react'
import { Icons, Text, Box } from 'stylewhere/components'
import styled from '@emotion/styled'
import { AppStore } from 'stylewhere/shared'

interface Props {
  code: string
  style?: React.CSSProperties
}

export class EncodingDailyItems extends Component<Props> {
  render() {
    const { code, style } = this.props
    return (
      <ItemEncodedBox
        onClick={() => {
          AppStore.resetDailyEncodedItems(code)
          this.forceUpdate()
        }}
        style={style ? style : { minWidth: 100 }}
        row
        center
        pr={10}
        pl={7}
        pv={10}
      >
        <Icons.Tag width={40} height={40} />
        <Text style={{ marginLeft: 10 }} bold fontSize={30} data-testid={`daily-encoded-items-${code}`} data-cy={code}>
          {AppStore.dailyEncodedItems[code] ?? 0}
        </Text>
      </ItemEncodedBox>
    )
  }
}

const ItemEncodedBox = styled(Box)`
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
`

import styled from '@emotion/styled'
export function VersionBadge() {
  const __BRAND_VERSION__ = process.env.REACT_APP_VERSION || '5.0.0'

  const getEnvironmentLabel = () => {
    const hostname = window.location.hostname

    if (hostname.includes('localhost') || hostname.includes('dev')) return 'DEV'
    if (hostname.includes('qty')) return 'QTY'
    return ''
  }

  const version = __BRAND_VERSION__.split('.').slice(0, 2).join('.') || '0.0'

  return (
    <VersionText>
      RFID STATION v{version} {getEnvironmentLabel()}
    </VersionText>
  )
}

const VersionText = styled.div`
  color: #000;
  font-size: 13px;
  font-weight: 800;
  letter-spacing: 0em;
  position: absolute;
  bottom: 37px;
  right: 5px;
  background-color: #ceff00;
  padding: 2px 12px;
  border-radius: 4px;
`

import { Component } from 'react'
import { ShippingParcel } from 'stylewhere/api'
import { OperationStart } from 'stylewhere/components'
import { SimpleParcelListExtensions } from 'stylewhere/extensions'
import { Routes } from 'stylewhere/pages'
import {
  FormSchema,
  FormSchemaData,
  FormSchemaRouteProps,
  getDataFromSchema,
  Router,
  SimpleParcelListOperationConfig,
  RemoteOperation,
} from 'stylewhere/shared'
import { isModalError, showToastError } from 'stylewhere/utils'

export default class SimpleParcelListStart extends Component<FormSchemaRouteProps<{ opCode: string }>> {
  isModal = false
  submitPath: Routes = '/simple-parcel-list/:opCode/reading'
  matchParams = Router.getMatchParams(this.props)
  operation = RemoteOperation.getOperationConfig<SimpleParcelListOperationConfig>(this.matchParams.opCode)

  componentDidMount() {
    this.isModal = isModalError(this.operation)
  }

  /** La lista di parcel dipende dalle implementazioni e verrà caricata qui */
  async getParcels(formData: FormSchemaData, operation: SimpleParcelListOperationConfig, schema: FormSchema) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const formSchemaData = getDataFromSchema(formData, schema)
    const parcels: ShippingParcel[] = []
    return parcels
  }

  async onSubmit(formData: FormSchemaData, operation: any, schema: FormSchema) {
    try {
      const parcels = await this.getParcels(formData, operation, schema)
      Router.navigate(this.submitPath, { opCode: operation.code }, { state: { formData, parcels } })
    } catch (err) {
      showToastError(err, 'Start Parcel Error', this.isModal)
    }
  }

  render() {
    return (
      <OperationStart
        submitPath={this.submitPath}
        extensions={SimpleParcelListExtensions}
        onSubmit={(...args) => this.onSubmit(...args)}
      />
    )
  }
}

import { api, responseErrorCheck, TmrBaseResource } from 'core/api'
import { InboundConveyor, PrintDataInboundConveyor } from './types'

export class InboundConveyorApi extends TmrBaseResource {
  static endpoint = 'sapInbounds/varco/ingresso'

  static async getInboundConveyor(mission: string, warehouse: string): Promise<InboundConveyor> {
    return api
      .get<InboundConveyor>(
        'sapQualityControl/' + this.endpoint + `/check?mission=${mission}&warehouse=${warehouse}`,
        undefined
      )
      .then(responseErrorCheck)
  }

  static getPrinters(): Promise<any> {
    return api.get('api/v1/printers?page=0&size=1000').then(responseErrorCheck)
  }

  static async printInboundConveyor(printData: PrintDataInboundConveyor): Promise<any> {
    return api.post('sapQualityControl/' + this.endpoint + '/print', printData).then(responseErrorCheck)
  }

  static async confirmInboundConveyor(data: InboundConveyor): Promise<any> {
    return api.post('sapQualityControl/' + this.endpoint + '/confirm', data).then(responseErrorCheck)
  }
}

import styled from '@emotion/styled'
import { MotionStyle } from 'framer-motion'
import { Component, ReactNode } from 'react'
import Hotkeys from 'react-hot-keys'
import {
  Box,
  EmulationButton,
  EmulationModal,
  Header,
  HeaderAction,
  HeaderDetail,
  Icons,
  Modal,
  ModalTitle,
  PageFormDetail,
  ToggleField,
} from 'stylewhere/components'
import {
  AppStore,
  isNewEmulationFormat,
  OperationConfig,
  parseNewEmulationFormat,
  RfidReader,
  Router,
} from 'stylewhere/shared'
import { __, T } from 'stylewhere/shared/i18n'
import { OperationUserSetting } from 'stylewhere/types'
import { __CAPITALIZE } from 'stylewhere/utils'

// eslint-disable-next-line
export type PageHeader = { details?: HeaderDetail; actions?: HeaderAction[] }

interface Props {
  title: string
  onBackPress?: (() => void) | false
  alertOnBackPress?: boolean
  loading?: boolean
  header?: PageHeader | null
  headerRight?: JSX.Element
  children?: any
  enableEmulation?: boolean
  forceDetails?: 'top' | 'right' | false
  contentWrapperColumn?: boolean
  userSettings?: { operation: OperationConfig; settings: OperationUserSetting[] }
  onUserSettingChanged?: (settingName: string, newValue: string | number | boolean) => void
}

interface State {
  showEmulationModal: boolean
  showUserSettingsModal: boolean
  userSettings: { [settingName: string]: string | number | boolean }
  isEmulationMenuOpen: boolean
}

export class Page extends Component<Props, State> {
  static Title = styled(Box)`
    font-weight: bold;
    font-size: 30px;
  `

  static TopBar = ({ children }: { children: React.ReactNode }) => {
    return (
      <Box mb={40} pv={10} row>
        {children}
      </Box>
    )
  }

  static Sidebar = ({
    style,
    width = 360,
    children,
    pl,
    pb,
    height,
    hideBar,
  }: {
    style?: MotionStyle
    width?: string | number
    children: React.ReactNode
    pl?: number
    pb?: number
    height?: string | number
    hideBar?: boolean
  }) => {
    return (
      <Box hideBar={hideBar} style={{ overflowY: 'auto', ...style }} width={width} bgGrey>
        <Box flex pt={15} pb={pb === undefined ? 15 : pb} pl={pl === undefined ? 15 : pl} height={height}>
          {children}
        </Box>
      </Box>
    )
  }

  static Content = ({
    children,
    style,
    notBoxed = false,
    overflowY,
  }: {
    style?: MotionStyle
    children: React.ReactNode
    notBoxed?: boolean
    overflowY?: 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto'
  }) => {
    return (
      <ContentInner overflowY={overflowY} flex style={style} notBoxed={notBoxed}>
        {children}
      </ContentInner>
    )
  }

  state: State = {
    showEmulationModal: false,
    showUserSettingsModal: false,
    userSettings: {},
    isEmulationMenuOpen: false,
  }

  getDataFromHost = () => {
    let host = window.location.host
    let data: string[] = []
    if (host.indexOf('localhost') === -1) {
      host = host.replace('https://', '')
      host = host.replace('station.', '')
      const splitData = host.split('.')
      host = splitData[0]
      host = host.replace('-stw', '')
      data = host.split('-')
    }
    return {
      client: data.length > 0 && data[0] !== 'stylewhere' ? __CAPITALIZE(data[0]) : undefined,
      envinroment: data.length > 1 ? data[1].toUpperCase() : undefined,
    }
  }

  handleUserSettingsInitialization = async () => {
    const { userSettings } = this.props
    if (userSettings) {
      const tmpUserSettings: { [settingName: string]: string | number | boolean } = {}
      for (const setting of userSettings.settings) {
        const storedUserSetting: { value: string | number | boolean; isDefault: boolean } | undefined =
          await AppStore.getUserSetting(setting.name, userSettings.operation.id)
        let defaultValue: string | number | boolean | undefined = undefined
        defaultValue = setting.default
        if (defaultValue === undefined) {
          let operationOptionValue =
            userSettings.operation.options[setting.name] ?? userSettings.operation[setting.name]
          if (operationOptionValue !== undefined) {
            if (typeof operationOptionValue === 'string' && setting.type === 'number') {
              operationOptionValue = Number(operationOptionValue)
            } else if (typeof operationOptionValue === 'string' && setting.type === 'boolean') {
              operationOptionValue = operationOptionValue === 'true'
            }
            defaultValue = operationOptionValue
          } else {
            //fallback
            defaultValue = setting.type === 'boolean' ? false : setting.type === 'number' ? 0 : ''
          }
        }
        if (!storedUserSetting || (storedUserSetting.isDefault && storedUserSetting.value !== defaultValue)) {
          await AppStore.setUserSetting(setting.name, defaultValue, userSettings.operation.id, true)
          tmpUserSettings[setting.name] = defaultValue
        } else {
          tmpUserSettings[setting.name] = storedUserSetting.value
        }
      }
      this.setState({ userSettings: tmpUserSettings })
    }
  }

  async componentDidMount() {
    await this.handleUserSettingsInitialization()
    const data = this.getDataFromHost()
    let title = 'Stylewhere'
    if (data.client) title += ' ' + data.client
    title += ` | RFID Station - ${this.props.title}`
    if (title !== document.title) {
      if (data.envinroment) title = '[' + data.envinroment + '] ' + title
      document.title = title
    }

    if (this.props.alertOnBackPress) {
      // Prevents user from leaving the page and shows a confirmation message.
      // Unfortunately modern browsers removed support for custom alert messages.
      window.addEventListener('beforeunload', this.preventDefaultBeforeUnload)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.preventDefaultBeforeUnload)
  }

  componentDidUpdate(prevProps: Props, _prevState: State) {
    if (prevProps.alertOnBackPress !== this.props.alertOnBackPress) {
      if (this.props.alertOnBackPress) {
        window.addEventListener('beforeunload', this.preventDefaultBeforeUnload)
      } else {
        window.removeEventListener('beforeunload', this.preventDefaultBeforeUnload)
      }
    }
  }

  preventDefaultBeforeUnload(e: BeforeUnloadEvent | PopStateEvent) {
    e.preventDefault()
    e.returnValue = ''
  }

  onEmulationConfirm = (input?: string) => {
    this.setState({ showEmulationModal: false })
    if (!input) return
    if (isNewEmulationFormat(input)) {
      const emulatedTags = parseNewEmulationFormat(input)
      RfidReader.emulateTagAuto(emulatedTags)
    } else {
      const codes: string[] = String(input).replace(/ /g, '').split(',') ?? []
      RfidReader.emulateTagAuto(
        codes.map((code) => (AppStore.emulationIdentifierType === 'UHF' ? { epc: code } : { uid: code })),
      )
    }
  }

  onKeyDown = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ showEmulationModal: !this.state.showEmulationModal })
  }

  getFormSchemaLength = () => {
    const { header } = this.props
    if (header && header.details && header.details.formSchema)
      return header?.details.formSchema.filter((field) => field.type !== 'hidden').length
    return 0
  }

  getAvailableUserSettings = () => {
    const { userSettings } = this.props
    const result: OperationUserSetting[] = []
    if (!userSettings) return result
    userSettings.settings.forEach((setting) => {
      if (setting.visible) result.push(setting)
    })
    return result
  }

  renderUserSettingsButton = () => {
    if (this.getAvailableUserSettings().length === 0) return null
    return (
      <SettingsButton onClick={() => this.setState({ showUserSettingsModal: true })}>
        <Icons.Settings style={{ width: 36, margin: 17, opacity: 0.8 }} />
      </SettingsButton>
    )
  }

  handleUserSettingsChange = async (settingName: string, newValue: string | number | boolean) => {
    if (!this.props.userSettings) return
    await AppStore.setUserSetting(settingName, newValue, this.props.userSettings.operation.id)
    this.setState({ userSettings: { ...this.state.userSettings, [settingName]: newValue } })
    this.props.onUserSettingChanged?.(settingName, newValue)
  }

  renderUserSettingsModal = () => {
    const availableUserSettings = this.getAvailableUserSettings()
    if (availableUserSettings.length === 0) return null

    return (
      <Modal
        visible={this.state.showUserSettingsModal}
        onClose={() => this.setState({ showUserSettingsModal: false })}
        onCloseComplete={() => this.setState({ showUserSettingsModal: false })}
        size="3xl"
        showCloseButton
      >
        <ModalTitle title={__(T.userSettings.title)} />
        <Box mt={20}>
          {availableUserSettings.map((setting) => {
            const label =
              setting.label !== undefined ? setting.label : (__(T.userSettings[setting.name]) ?? setting.name)
            return (
              <Box flex key={setting.name} pv={10} ph={20}>
                <Box row vcenter>
                  {setting.type === 'boolean' && (
                    <ToggleField
                      label={label}
                      checked={this.state.userSettings[setting.name] as boolean}
                      onChange={() => {
                        this.handleUserSettingsChange(setting.name, !(this.state.userSettings[setting.name] as boolean))
                      }}
                    />
                  )}
                  {setting.type === 'string' && (
                    <Box row flex vcenter>
                      <UserSettingsText style={{ marginRight: 30 }}>{label}</UserSettingsText>
                      <UserTextInput
                        type="text"
                        value={this.state.userSettings[setting.name] as string}
                        onChange={(e) => {
                          this.handleUserSettingsChange(setting.name, e.target.value)
                        }}
                      />
                    </Box>
                  )}
                  {setting.type === 'number' && (
                    <Box row flex vcenter>
                      <UserSettingsText style={{ marginRight: 20 }}>{label}</UserSettingsText>
                      <UserTextInput
                        type="number"
                        value={this.state.userSettings[setting.name]?.toString() ?? 0}
                        onChange={(e) => {
                          this.handleUserSettingsChange(
                            setting.name,
                            !Number.isNaN(e.target.value) ? Number(e.target.value) : 0,
                          )
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            )
          })}
        </Box>
      </Modal>
    )
  }

  render() {
    const {
      title,
      onBackPress,
      alertOnBackPress,
      header,
      headerRight,
      children,
      enableEmulation,
      contentWrapperColumn,
      forceDetails = false,
    } = this.props
    const { showEmulationModal } = this.state
    let detailsPosition
    if (header?.details && header.details.formSchema.length > 0) {
      if (forceDetails) {
        detailsPosition = forceDetails
      } else {
        detailsPosition = this.getFormSchemaLength() <= 2 ? 'top' : 'right'
      }
    }
    return (
      <>
        <Hotkeys
          keyName="command+e,ctrl+e,ctrl+m,command+m,alt+m"
          onKeyDown={this.onKeyDown}
          disabled={!enableEmulation || !AppStore.getEmulation()}
        >
          <PageContainer>
            {header !== null && (
              <Header
                title={title}
                actions={header?.actions}
                details={detailsPosition === 'top' ? header?.details : undefined}
                rowMode
                onBackPress={() => {
                  if (alertOnBackPress) {
                    if (confirm(__(T.misc.navBackWarning))) {
                      onBackPress ? onBackPress() : Router.navigate('/')
                    }
                  } else {
                    onBackPress ? onBackPress() : Router.navigate('/')
                  }
                }}
              >
                <Box flex row>
                  {headerRight as ReactNode}
                  {this.renderUserSettingsButton()}
                </Box>
              </Header>
            )}
            <EmulationButton enabled={!!enableEmulation} onEmulationClick={this.onKeyDown} />
            <ContentWrapper flex row={!contentWrapperColumn}>
              {children}
              {header?.details && detailsPosition === 'right' && (
                <LeftHeaderContainer>
                  <PageFormDetail
                    changeButtonStyle={{ margin: 10, marginBottom: 15, marginLeft: 0 }}
                    detailStyle={{ overflowWrap: 'break-word' }}
                    initData={header.details.data}
                    formSchema={header.details.formSchema}
                    setFormData={header.details.setFormData}
                    resetFormData={header.details.resetFormData}
                    operationId={header.details.operationId}
                    validateForm={header.details.validateForm}
                  />
                </LeftHeaderContainer>
              )}
            </ContentWrapper>
          </PageContainer>

          {enableEmulation && showEmulationModal && (
            <EmulationModal
              title="Emulation Modal"
              onClose={() => {
                this.setState({ showEmulationModal: false })
              }}
              onConfirm={this.onEmulationConfirm}
            />
          )}
          {this.renderUserSettingsModal()}
        </Hotkeys>
      </>
    )
  }
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%;
`

const ContentWrapper = styled(Box)`
  overflow: hidden;
  background-color: ${({ theme }) => theme.background1};
`

const ContentInner = styled(Box)<{
  notBoxed?: boolean
  overflowY?: 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto'
}>`
  overflow-y: ${({ overflowY = 'auto' }) => overflowY};
  scrollbar-width: none;
  margin: ${({ theme }) => theme.padding};
  margin-left: 15px;
  box-shadow: ${({ notBoxed }) => (notBoxed ? 'none' : `0 2px 10px rgba(0, 0, 0, 0.05)`)};
  border-radius: ${({ notBoxed }) => (notBoxed ? 'none' : `15px`)};
  background-color: ${({ notBoxed, theme }) => (notBoxed ? 'inherit' : theme.background2)};
`

const LeftHeaderContainer = styled(Box)`
  background: #eaeaea;
  border-radius: 15px;
  margin: 15px;
  margin-left: 0px;
  padding-left: 15px;
  max-width: 20vw;
  min-width: 20vw;
  height: fit-content;
  overflow-y: auto;
  max-height: calc(100vh - 130px);
`

const SettingsButton = styled.div`
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  cursor: pointer;
`

const UserSettingsText = styled.label`
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  text-align: right;
`
const UserTextInput = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: '0 0 0 3px rgba(47, 128, 237, 0.5)';
`

import _ from 'lodash'

class CustomError extends Error {
  errorCode: any
  constructor(errorCode: string, message: string) {
    super()
    this.name = 'CustomError'
    this.errorCode = errorCode
    this.message = message
  }

  static interpolateMessage(message: string | undefined, obj: any): string {
    if (!message || !message.includes('*{')) return message ?? ''
    const matches = message.match(/\*\{\w.+\}/g)
    if (!matches) return message
    let resultMessage = message
    matches.forEach((match) => {
      const key = match.replace('*{', '').replace('}', '')
      const value = _.get(obj, key)
      if (value !== undefined) {
        resultMessage = resultMessage.replace(match, value)
      }
    })
    return resultMessage
  }
}

export default CustomError

import { useState } from 'react'
import styled from '@emotion/styled'
import { Skeleton, SkeletonProps } from '@chakra-ui/react'
import { Box, Icons, Image, SelectItemModal, SelectItemModalProps } from 'stylewhere/components'

type KeyValueRowVariants = 'default' | 'grey' | 'inset' | 'raw'

const SkeletonComponent = Skeleton as React.ComponentType<SkeletonProps>

interface Props {
  label?: any
  value?: any
  labelStyle?: React.CSSProperties
  valueStyle?: React.CSSProperties
  style?: React.CSSProperties
  image?: any
  onClick?: () => void
  variant?: KeyValueRowVariants
  optionModal?: SelectItemModalProps
  onPress?: () => void
  loading?: boolean
  borderColor?: string
  whiteSpace?: 'normal' | 'pre' | 'nowrap' | 'pre-wrap' | 'pre-line' | 'break-spaces'
  children?: any
}

export function KeyValueRow({
  label,
  value,
  labelStyle,
  valueStyle,
  style,
  image,
  onClick,
  variant,
  optionModal,
  loading,
  borderColor,
  whiteSpace,
  children,
}: Props) {
  const [openOptions, setopenOptions] = useState(false)
  const onKeyValueRowClick = () => {
    if (onClick) onClick()
    if (optionModal?.options) setopenOptions(!openOptions)
  }

  const renderImage = () => {
    const icon = image
    if (!icon) return <></>
    if (typeof icon !== 'string') {
      return <RightIcon>{icon}</RightIcon>
    }
    return <Image style={{ width: 35 }} alt="" src={icon} />
  }

  if (loading)
    return (
      <KeyValueRowContainer data-testid={`keyvalue-row-container-skeleton`} vcenter variant={variant} style={style}>
        <KeyValueContainer>
          <KeyText data-testid="key-text" style={labelStyle}>
            {label}
          </KeyText>
          <ValueText data-testid="value-text" whiteSpace={whiteSpace} style={valueStyle}>
            <SkeletonComponent
              startColor="#E6E6E6"
              endColor="#c8c8c8"
              isLoaded={!loading}
              height="18px"
              width="100px"
              mt="5px"
            />
          </ValueText>
        </KeyValueContainer>
      </KeyValueRowContainer>
    )

  return (
    <>
      <KeyValueRowContainer
        data-testid={`keyvalue-row-container`}
        data-cy={label || value}
        style={style}
        borderColor={borderColor}
        variant={variant}
        onClick={onKeyValueRowClick}
      >
        {!children && (
          <KeyValueContainer flex>
            <KeyText data-testid="key-text" style={labelStyle}>
              {label}
            </KeyText>
            <ValueText data-testid="value-text" whiteSpace={whiteSpace} style={valueStyle}>
              {value || '---'}
            </ValueText>
          </KeyValueContainer>
        )}
        {!!children && children}
        {renderImage()}
        {optionModal && <Icons.ArrowDown />}
      </KeyValueRowContainer>
      {optionModal && openOptions && (
        <SelectItemModal {...optionModal} visible={openOptions} onClose={() => setopenOptions(false)} />
      )}
    </>
  )
}

const RightIcon = styled.div`
  svg {
    cursor: pointer;
    width: 35px;
  }
`

const KeyValueRowContainer = styled(Box)<{ borderColor?: string; variant?: KeyValueRowVariants }>`
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  min-height: 74px;
  ${({ borderColor }) => (borderColor ? `border: 2px solid ${borderColor};` : undefined)}
  ${({ variant }) => {
    switch (variant) {
      case 'grey':
        return ` background-color: #F5F5F5`
      case 'inset':
        return ` background-color: #E8E8E8;box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);`
      case 'raw':
        return `background-color: transparent;box-shadow: 0 1px 1px transparent;`
      default:
        return `background-color: #ffffff;box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);`
    }
  }}
`

const KeyValueContainer = styled(Box)`
  width: 100%;
`

const KeyText = styled.div`
  font-size: 16px;
  line-height: 19px;
  display: flex;
  color: grey;
  align-items: center;
`
const ValueText = styled.div<{ whiteSpace?: 'normal' | 'pre' | 'nowrap' | 'pre-wrap' | 'pre-line' | 'break-spaces' }>`
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  width: auto;
  align-items: center;
  color: #333333;
  white-space: ${({ whiteSpace }) => whiteSpace ?? 'nowrap'};
  text-overflow: ellipsis;
  overflow: hidden;
`

import { Component } from 'react'
import styled from '@emotion/styled'
import { Box, Image, SmallTagCounter } from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'
import { getProductCode, hasChecklist } from 'stylewhere/shared/utils'
import { AppStore, OperationConfig } from 'stylewhere/shared'
import { ParcelContentEntry, Products, ShippingParcel } from 'stylewhere/api'

interface Props {
  entries?: ParcelContentEntry[]
  operation: OperationConfig
  parcel: ShippingParcel
  viewHeader: boolean
}

export class ParcelRowItemList extends Component<Props> {
  getLabelHeader = (): string => {
    return `${__(T.misc.style)} / ${__(T.misc.material)} / ${__(T.misc.color)} / ${__(T.misc.size)}`
  }

  getLabel = (product): string => {
    const labelArr: string[] = []
    if (product) {
      ;['style', 'material', 'color', 'size'].forEach((key) => {
        let value = '---'
        if (product[key]) {
          value =
            product[key]?.description && product[key].description != null
              ? product[key]?.description
              : product[key]?.value
              ? product[key]?.value
              : '---'
        }
        labelArr.push(value)
      })
    }
    const label = labelArr.join(' / ')
    return label !== '--- / --- / --- / ---' ? label : '---'
  }

  getCode = (decodedItem) => {
    return decodedItem.identifierCode || undefined
  }

  getIdentifiers = (decodedItem) => {
    let identifiers = decodedItem.item && decodedItem.item.identifiers ? decodedItem.item.identifiers : []
    identifiers = identifiers.filter((idf) => idf.status !== 'REPLACED_ASSOCIATION')
    return identifiers.filter((idf) => idf.code !== decodedItem.identifierCode)
  }

  isItems = () => {
    return this.props.operation.readingsDisplayMode === 'item'
  }

  render() {
    const { entries, viewHeader, operation } = this.props
    const showImages = AppStore.getShowProductImage()
    return (
      <Box flex mb={15}>
        {viewHeader && (
          <IntestazioneRow row ml={0} mr={0}>
            <Intestazione width={160} />
            <Intestazione width={250}>{__(T.misc.code)}</Intestazione>
            <Intestazione flex>{this.getLabelHeader()}</Intestazione>
            <Intestazione width={240} noBorder center>
              {__(T.misc.only_quantity)}
            </Intestazione>
          </IntestazioneRow>
        )}
        {entries &&
          entries.map((item, index) => {
            const product = this.isItems() ? item.item.product : item.product
            return (
              <ItemRow key={'item-list-' + index} row ml={0} mr={0} last={index === entries.length - 1}>
                <ItemColumn width={160} pt={10} pb={10}>
                  {product && showImages && (
                    <Box center>
                      <Image width={80} height={80} src={Products.getImageUrl(product)} />
                    </Box>
                  )}
                </ItemColumn>
                <ItemColumn pt={10} pb={10} width={250}>
                  {getProductCode(product)}
                </ItemColumn>
                <ItemColumn pt={10} pb={10} flex>
                  {this.getLabel(product)}
                </ItemColumn>
                <ItemColumn pt={10} pb={10} width={240} noBorder>
                  <SmallTagCounter
                    detected={item.detected ?? 0}
                    expected={hasChecklist(operation) ? item.expected ?? 0 : undefined}
                    unexpected={item.unexpected ?? 0}
                    alignSelf={'center'}
                  />
                </ItemColumn>
              </ItemRow>
            )
          })}
      </Box>
    )
  }
}

const IntestazioneRow = styled(Box)`
  border-radius: 10px 10px 0px 0px;
  background-color: #f6f6f6;
  overflow: hidden;
  display: flex;
  flex-direction: row;
`

const Intestazione = styled(Box)<{ noBorder?: boolean }>`
  height: 65px;
  padding: 0px 15px;
  color: #000000;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  justify-content: center;
  border-right: ${(props) => (props.noBorder ? '0px' : '1px solid #F1F1F1')};
`

const ItemRow = styled(Box)<{ last?: boolean }>`
  border: 1px solid #f1f1f1;
  ${(props) => (props.last ? 'border-radius: 0px 0px 10px 10px' : '')}
`

const ItemColumn = styled(Box)<{ noBorder?: boolean }>`
  padding: 0px 15px;
  color: #000000;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  justify-content: center;
  border-right: ${(props) => (props.noBorder ? '0px' : '1px solid #F1F1F1')};
`

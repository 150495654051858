import { Component } from 'react'
import {
  DecodedItem,
  GroupedItemsByProduct,
  IdentifierType,
  ProductInfoField,
  ShippingParcelChecklist,
  TmrProduct as StwProduct,
} from 'stylewhere/api'
import { GroupedItemList, GroupedProductList } from 'stylewhere/components'
import { OperationConfig, OperationReadingProvider } from 'stylewhere/shared'

interface Props {
  items: DecodedItem<string>[]
  operation: OperationConfig
  extension: any
  removeItemCallback?: (item: DecodedItem<string>) => void
  actionItemCallback?: (item: DecodedItem<string>) => void
  checklist?: ShippingParcelChecklist | null
  products?: { [upc: string]: StwProduct }
  removeModeEnabled?: boolean
  emptyMessage?: string | JSX.Element | null
  hideIdentifiersByType?: IdentifierType[]
  hideIdentifiersZones?: boolean
  overrideProductInfoFields?: ProductInfoField[]
}

export class OperationReadingList extends Component<Props> {
  render() {
    const {
      operation,
      checklist,
      products,
      removeItemCallback,
      actionItemCallback,
      items,
      extension,
      removeModeEnabled,
      emptyMessage,
      hideIdentifiersByType,
      hideIdentifiersZones,
      overrideProductInfoFields,
    } = this.props
    const groupByProduct = operation.readingsDisplayMode === 'groupedByProduct'
    const rows = OperationReadingProvider.groupItems(
      items,
      groupByProduct,
      operation,
      extension,
      checklist,
      products,
      true
    )
    if (operation.readingsDisplayMode === 'groupedByProduct') {
      return (
        <GroupedProductList
          operation={operation}
          rows={rows as GroupedItemsByProduct[]}
          onRemoveItem={removeItemCallback}
          onActionItem={actionItemCallback}
          removeModeEnabled={removeModeEnabled}
          emptyMessage={emptyMessage}
          hideIdentifiersByType={hideIdentifiersByType}
          hideIdentifiersZones={hideIdentifiersZones}
          overrideProductInfoFields={overrideProductInfoFields}
        />
      )
    }
    if (operation.readingsDisplayMode === 'item') {
      return (
        <GroupedItemList
          operation={operation}
          rows={rows as DecodedItem[]}
          onRemoveItem={removeItemCallback}
          onActionItem={actionItemCallback}
          itemZoneBase={items.length > 0 && items[0] && items[0].zone ? items[0].zone.id : ''}
          emptyMessage={emptyMessage}
          hideIdentifiersByType={hideIdentifiersByType}
          overrideProductInfoFields={overrideProductInfoFields}
        />
      )
    }
    throw new Error(`Unsupported operation readingsDisplayMode ${operation.readingsDisplayMode}`)
  }
}

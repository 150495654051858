import { Component } from 'react'
import styled from '@emotion/styled'
import {
  Page,
  LeftHeader,
  KeyValueRow,
  Spacer,
  Box,
  UserInfoModal,
  SettingsModal,
  Icons,
  DashboardOperationsButtons,
  DashboardUserNoPlace,
  BugModal,
} from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'
import { TmrWorkstation, TmrPlace, Users } from 'stylewhere/api'
import { AppStore, Router } from 'stylewhere/shared'
import { showToastError } from 'stylewhere/utils'

interface State {
  userModalVisible: boolean
  settingsModalVisible: boolean
  refreshCounter: number
}

export default class Dashboard extends Component<{}, State> {
  state: State = {
    refreshCounter: 0,
    userModalVisible: false,
    settingsModalVisible: false,
  }

  setuserModalVisible = (userModalVisible: boolean) => this.setState({ userModalVisible })

  setsettingsModalVisible = (settingsModalVisible: boolean) => this.setState({ settingsModalVisible })

  selectDefaultWorkstation = async (workstation: TmrWorkstation) => {
    await AppStore.saveDefaultWorkstation(workstation)
    Router.refresh()
  }

  getWorkstationValue = () => {
    if (!AppStore.defaultWorkstation) {
      return '---'
    }
    return AppStore.defaultWorkstation.description || AppStore.defaultWorkstation.code
  }

  onConfirmUserInfo = async (places: TmrPlace[], attrs: any) => {
    const user = AppStore.loggedUser
    if (user) {
      let attributes = user.attributes || {}
      attributes = {
        ...attributes,
        ...attrs,
      }
      const placesToSave: string[] = []
      for (let p = 0; p < places.length; p++) {
        placesToSave.push(places[p].id)
      }
      if (user.userPlaces && user.userPlaces.length > 0) {
        for (let p = 0; p < user.userPlaces.length; p++) {
          placesToSave.push(user.userPlaces[p].id)
        }
      }

      const success = await Users.initUserInfo(placesToSave, attributes, AppStore.getUserSelfInitializationPolicy())
      if (success) {
        window.location.reload()
      } else {
        showToastError(__(T.error.self_user_data))
      }
    }
  }

  refreshOperations = () => {
    //this.setState({ refreshCounter: this.state.refreshCounter + 1 })
    window.location.reload()
  }

  render() {
    const { userModalVisible, settingsModalVisible, refreshCounter } = this.state
    return !AppStore.canEditUserSelf() ? (
      <Page title="Dashboard" header={null}>
        <Box flex row>
          <LeftHeader refreshOperation={this.refreshOperations}>
            <KeyValueRow
              style={{ width: '100%' }}
              onClick={() => this.setuserModalVisible(!userModalVisible)}
              label={__(T.misc.user)}
              value={AppStore.loggedUser?.username}
              image={<Icons.Info />}
            />
            <Spacer />
            <KeyValueRow
              label={__(T.misc.workstation)}
              style={{ width: '100%' }}
              value={this.getWorkstationValue()}
              optionModal={{
                onSelect: (item) => this.selectDefaultWorkstation(item[0]),
                field: 'description',
                options: AppStore.workstations ?? [],
                selected: [AppStore.defaultWorkstation],
                title: __(T.titles.select_workstation),
                searchable: true,
              }}
            />
          </LeftHeader>
          <RightContainer>
            <DashboardOperationsButtons refreshCounter={refreshCounter} />
          </RightContainer>          
        </Box>
        <BugModal enableSentry={true} enableDatadog={true} />
        {userModalVisible && (
          <UserInfoModal
            user={AppStore.loggedUser}
            onClose={() => this.setuserModalVisible(false)}
            visible={userModalVisible}
          />
        )}
        {settingsModalVisible && (
          <SettingsModal onClose={() => this.setsettingsModalVisible(false)} visible={settingsModalVisible} />
        )}
      </Page>
    ) : (
      <DashboardUserNoPlace onConfirmUserInfo={this.onConfirmUserInfo} />
    )
  }
}

const RightContainer = styled.div`
  display: flex;
  flex: 1.5;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

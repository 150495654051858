import { Shippings } from 'stylewhere/api'
import { ShippingOperationConfig } from 'stylewhere/shared'
import { T, __ } from 'stylewhere/shared/i18n'
import { PackingOperationConfig } from 'stylewhere/shared/RemoteOperation'
import { askUserConfirmation } from 'stylewhere/shared/utils'
import { Extensions } from './Extensions'

export class ShippingExtensions extends Extensions {
  static checkPackingUnpack = async (
    error: Error,
    parcelCode: string,
    operation: ShippingOperationConfig,
    onUnpackConfirm?: () => void | Promise<void>,
    onUnpackDismiss?: () => void | Promise<void>
  ) => {
    if (
      error.message === 'UNPACK' &&
      operation.type === 'PACKING' &&
      parcelCode &&
      (operation as PackingOperationConfig).allowUnpack !== 'false'
    ) {
      if (await askUserConfirmation(__(T.misc.warning), __(T.confirm.unpack), __(T.misc.cancel), __(T.misc.yes))) {
        await Shippings.unpackPacking(operation as PackingOperationConfig, parcelCode)
        onUnpackConfirm && (await onUnpackConfirm())
        return true
      } else {
        onUnpackDismiss && (await onUnpackDismiss())
        return true
      }
    } else {
      return false
    }
  }
}

import styled from '@emotion/styled'
import { Box, Button, Modal, ModalProps, ProductInfoBox } from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'
import React from 'react'
import { ConfirmModalParams, Items, Products, TmrItem, TmrProduct } from 'stylewhere/api'
import { Skeleton } from '@chakra-ui/react'
import { getEncodingProductFields } from 'stylewhere/shared/utils'

interface Props {
  title: string
  subtitle?: string
  isOpen?: boolean
  onConfirm?: () => void
  onClose: () => void
  style?: any
  labelClose?: string
  labelConfirm?: string
  /** if present show a product information box inside the confirmation modal */
  showDetailForProduct?: ConfirmModalParams['showDetailForProduct']
}

export const ConfirmModal = (props: Props) => {
  const { isOpen, onClose, title, onConfirm, subtitle, labelClose, labelConfirm, showDetailForProduct: p } = props
  const [detailProduct, setDetailProduct] = React.useState<TmrProduct | undefined>(undefined)
  const [productLoading, setProductLoading] = React.useState<boolean>(false)

  const btn1 = { flex: 1, minWidth: 'auto', height: 60 }
  const btn2 = { flex: 1, minWidth: 'auto', marginLeft: 15, height: 60 }

  React.useEffect(() => {
    async function fetchProductDetail() {
      if (p && (p.productCode || p.identifierCode)) {
        try {
          setProductLoading(true)
          if (p.productCode) {
            const products = await Products.searchCodes([p.productCode])
            if (products.content.length) {
              setDetailProduct(products.content[0])
            }
          } else if (p.identifierCode) {
            const items = await Items.search<TmrItem>({ identifierCodes: [p.identifierCode] })
            if (items.content.length) {
              const product = items.content[0].product
              if (product) {
                setDetailProduct(product)
              }
            }
          }
        } catch (error) {
          console.error(error)
        } finally {
          setProductLoading(false)
        }
      }
    }
    fetchProductDetail()
  }, [p])

  const size: ModalProps['size'] = p ? '2xl' : 'xl'

  return (
    <Modal visible={isOpen ?? true} onClose={onClose} size={size}>
      <Box>
        <Title>{title}</Title>
        <Description>{subtitle}</Description>
      </Box>

      {p && (
        <Skeleton isLoaded={!productLoading}>
          <Box mb={25}>
            <ProductInfoBox
              product={detailProduct}
              paddingCard={'20px 20px 20px 20px'}
              fields={getEncodingProductFields()}
            />
          </Box>
        </Skeleton>
      )}

      <Box row mt={10}>
        <Button variant="default" title={labelClose || __(T.misc.cancel)} onClick={onClose} style={btn1} />
        <Button title={labelConfirm || __(T.misc.confirm)} onClick={onConfirm} style={btn2} />
      </Box>
    </Modal>
  )
}

const Title = styled.span`
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
`

const Description = styled.label`
  font-weight: 500;
  padding: 25px 15px;
  font-size: 21px;
  white-space: pre-line;
  text-align: center;
  opacity: 0.5;
`

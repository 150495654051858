import { Component } from 'react'
import { Spinner, SpinnerProps } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { Box } from 'stylewhere/components'
import { Encodings } from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'

const SpinnerComponent = Spinner as React.ComponentType<SpinnerProps>

interface Props {
  wam?: string
  initialType?: string
  operationId?: string
}

interface State {
  order?: any
  loading: boolean
}

export class ProductInfoBoxWam extends Component<Props, State> {
  state: State = {
    order: undefined,
    loading: false,
  }

  componentDidMount() {
    const { initialType, wam, operationId } = this.props
    if (initialType === 'wam' && operationId && wam) {
      this.setState({ loading: true }, this._init)
    }
  }

  _init = async () => {
    const { wam, operationId, initialType } = this.props
    if (initialType === 'wam' && operationId && wam) {
      try {
        const res = await Encodings.findOrder(operationId, wam)
        if (res) {
          this.setState({ order: res, loading: false })
        } else {
          this.setState({ loading: false })
        }
      } catch (e) {
        this.setState({ loading: false })
      }
    }
  }

  static Field = ({ label, value = '---' }: { label?: string; value?: string }) => (
    <ContainerValue row>
      <LabelText data-testid="label-text">{label}</LabelText>
      <ValueText data-testid="value-text">{value}</ValueText>
    </ContainerValue>
  )

  getAttributeValue = (key: string) => {
    const { order } = this.state
    return order &&
      order.productionOrder &&
      order.productionOrder.attributes &&
      order.productionOrder.attributes[key] &&
      order.productionOrder.attributes[key] !== ''
      ? order.productionOrder.attributes[key]
      : '---'
  }

  render() {
    const { order, loading } = this.state
    return order ? (
      <CardContainerWam data-testid={`product-info-box-wam`} data-cy={order?.productionOrder?.code}>
        <Box justify={'space-between'} row>
          <Title>{'Work Order'}</Title>
        </Box>
        <Box>
          <ProductInfoBoxWam.Field label={__(T.custom.order_number)} value={this.getAttributeValue('numeroCommessa')} />
          <ProductInfoBoxWam.Field label={__(T.misc.material)} value={this.getAttributeValue('material')} />
          <ProductInfoBoxWam.Field label={__(T.custom.addon)} value={this.getAttributeValue('addonCode')} />
        </Box>
      </CardContainerWam>
    ) : loading ? (
      <CardContainerWam center>
        <SpinnerComponent thickness="5px" speed="0.65s" color="#454545" />
      </CardContainerWam>
    ) : null
  }
}

const CardContainerWam = styled(Box)`
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px;
  margin: 0px;
  margin-top: 10px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
`
const ContainerValue = styled(Box)`
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 10px;
  padding-bottom: 10px;
  &:last-child {
    border-bottom: 0 solid #e0e0e0;
  }
`
const Title = styled.div`
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #000000;
  opacity: 0.4;
  margin-bottom: 20px;
`
const LabelText = styled.div`
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
`
const ValueText = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: right;
`

import { Component } from 'react'
import { Asn } from 'stylewhere/api'
import { List, RowProps, StatusChip } from 'stylewhere/components'
import { T, __ } from 'stylewhere/shared/i18n'
import { getDate, getAsnParcelStatusChip } from 'stylewhere/shared/utils'

interface Props extends RowProps {
  shipping: Asn
  onClick: () => void
  hideDate?: boolean
  isInbound?: boolean
}

export class ShippingRow extends Component<Props> {
  render() {
    const { shipping, onClick, hideDate, isInbound, ...rest } = this.props
    return (
      <List.Row mr={3} ml={3} pl={20} pr={20} pt={20} pb={20} onClick={onClick} {...rest}>
        <List.Cell maxWidth={'20%'} p={5} label={__(T.misc.shipment)} value={shipping.code} />
        <List.Cell flex={0} width={240} p={5} label={__(T.misc.status)}>
          <StatusChip
            status={shipping.status || '?'}
            customStatusType={getAsnParcelStatusChip(shipping.status ?? '')}
            pv={1}
            fontSize={16}
          />
        </List.Cell>
        <List.Cell
          p={5}
          flex={1}
          label={isInbound ? __(T.misc.origin) : __(T.misc.destination)}
          value={isInbound ? shipping.originPlace?.description || '--' : shipping.destinationPlace?.description || '--'}
        />
        {!hideDate && (
          <List.Cell
            maxWidth={200}
            p={5}
            label={__(T.misc.shipping_date)}
            value={shipping.shippingDate ? getDate(new Date(shipping.shippingDate).getTime()) : '--'}
          />
        )}
        <List.Cell
          flex={0}
          width={80}
          p={5}
          label={__(T.misc.parcels)}
          value={`${shipping.receivedParcels}/${shipping.expectedParcels}`}
        />
      </List.Row>
    )
  }
}

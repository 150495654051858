import { useEffect, useCallback } from 'react'
import { AppStore, useIntervalAsync } from 'stylewhere/shared'

export interface VersionCheckConfig {
  getBackendVersion: () => Promise<any>
  backendVersionStorageKey?: string
  frontendVersionStorageKey?: string
  checkInterval?: number | null
}

export const VERSION_CHECK_EVENTS = {
  VERSION_CHECK_START: 'version-check-start',
  VERSION_CHECK_COMPLETE: 'version-check-complete',
  VERSION_UPDATE_DETECTED: 'version-update-detected',
} as const

export const useVersionCheck = ({
  getBackendVersion,
  backendVersionStorageKey = 'rfid_station_backend_version',
  frontendVersionStorageKey = 'rfid_station_frontend_version',
  checkInterval = 5 * 60 * 1000,
}: VersionCheckConfig) => {
  const isAuthenticated = !!AppStore.loggedUser

  const checkVersion = useCallback(async () => {
    const checkFrontendVersion = async () => {
      try {
        const response = await fetch('/version.json', {
          headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            Pragma: 'no-cache',
            Expires: '0',
          },
        })

        if (!response.ok) {
          console.error('Errore nel recupero della versione frontend')
          return false
        }

        const versionInfo = await response.json()
        const currentVersion = versionInfo.version

        if (!currentVersion) return false

        const savedVersion = localStorage.getItem(frontendVersionStorageKey)

        if (savedVersion && savedVersion !== currentVersion) {
          localStorage.setItem(frontendVersionStorageKey, currentVersion)
          return true
        } else if (!savedVersion) {
          localStorage.setItem(frontendVersionStorageKey, currentVersion)
        }

        return false
      } catch (error) {
        console.error('Errore nel controllo versione frontend:', error)
        return false
      }
    }

    try {
      if (!isAuthenticated) {
        return { hasUpdate: false }
      }

      window.dispatchEvent(new CustomEvent(VERSION_CHECK_EVENTS.VERSION_CHECK_START))

      let hasBackendUpdate = false
      let hasFrontendUpdate = false

      // Controlla versione backend
      const currentVersion = await getBackendVersion()

      if (currentVersion && Object.keys(currentVersion).length > 0) {
        const savedVersion = localStorage.getItem(backendVersionStorageKey)

        if (savedVersion && JSON.stringify(currentVersion) !== savedVersion) {
          localStorage.setItem(backendVersionStorageKey, JSON.stringify(currentVersion))
          hasBackendUpdate = true
        } else if (!savedVersion) {
          localStorage.setItem(backendVersionStorageKey, JSON.stringify(currentVersion))
        }
      }

      // Controlla versione frontend
      hasFrontendUpdate = await checkFrontendVersion()

      const result = {
        hasUpdate: hasBackendUpdate || hasFrontendUpdate,
        hasBackendUpdate,
        hasFrontendUpdate,
      }

      window.dispatchEvent(
        new CustomEvent(VERSION_CHECK_EVENTS.VERSION_CHECK_COMPLETE, {
          detail: result,
        })
      )

      if (result.hasUpdate) {
        window.dispatchEvent(
          new CustomEvent(VERSION_CHECK_EVENTS.VERSION_UPDATE_DETECTED, {
            detail: result,
          })
        )
      }

      return result
    } catch (error) {
      console.error('Errore nel controllo versione:', error)
      return { hasUpdate: false }
    }
  }, [isAuthenticated, getBackendVersion, backendVersionStorageKey, frontendVersionStorageKey])

  useEffect(() => {
    if (isAuthenticated) {
      checkVersion()
    }
  }, [isAuthenticated, checkVersion])

  useIntervalAsync(async () => {
    if (isAuthenticated) {
      await checkVersion()
    }
  }, checkInterval)

  return { checkVersion }
}

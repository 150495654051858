import styled from '@emotion/styled'
import { Component } from 'react'
import Lottie from 'react-lottie'
import { DecodeRequest, DecodedItem, TmrTag } from 'stylewhere/api'
import { animations } from 'stylewhere/assets'
import { Box, Button, Icons, Input, Modal, Spacer, Text, TextArea } from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'
import { RfidReader } from 'stylewhere/shared'
import { sleep } from 'stylewhere/shared/utils'

/**
 * BarcodeModeValidateMode:
 * "batch" - the modal accepts multiline input and tags are validated in a single batchValidate,
 * "splittedBatch" - the modal accepts multiline input but each barcode is validated in separated batchValidates,
 * "itemByItem" - the modal accepts only one line and tag validation happens when hitting the Enter key
 */
export type BarcodeModeValidateMode = 'batch' | 'splittedBatch' | 'itemByItem'
export type BarcodeModeModalOptions = {
  validateMode?: BarcodeModeValidateMode
  reOpenAfterSubmit?: boolean
}
export type BarcodeModeOptions =
  | { displayMode: 'modal'; modalOptions?: BarcodeModeModalOptions }
  | { displayMode: 'inputReplacesAntennaButton' }

interface Props {
  mode: 'antenna' | 'barcode'
  onStart?: () => void
  onItemDecoded?: (items: { [epc: string]: DecodedItem }, tags?: TmrTag[]) => void
  onTagReadCallback?: (tag: TmrTag) => Promise<void>
  setOnDecodedItemCallback?: () => void
  onClear?: () => void
  onError?: () => void
  decodeFunction?: (epcs: string[]) => Promise<any>
  decodeRequest?: DecodeRequest
  icon?: string
  content?: JSX.Element
  style?: React.CSSProperties
  buttonStyle?: React.CSSProperties
  leftBottom?: boolean
  hideClear?: boolean
  showPendingTags?: boolean
  disabled?: boolean
  iconOnly?: boolean
  initReaderTags?: string[]
  overrideStartLabel?: string
  barcodeModeOptions?: BarcodeModeOptions
}

interface State {
  reading: boolean
  starting: boolean
  pendingTags: number
  loading: boolean
  clearing: boolean
  barcodeModalOpen: boolean
  barcodeInputString: string
}

export class AntennaButton extends Component<Props, State> {
  static defaultProps = {
    mode: 'antenna',
    leftBottom: false,
  }

  state: State = {
    reading: false,
    starting: false,
    pendingTags: 0,
    loading: false,
    clearing: false,
    barcodeModalOpen: false,
    barcodeInputString: '',
  }

  constructor(props: Props) {
    super(props)
    const { onItemDecoded, decodeFunction, decodeRequest, onTagReadCallback, setOnDecodedItemCallback } = this.props
    RfidReader.initialize()
    RfidReader.onStartCallbackAntennaButton = this.onStartCallback
    RfidReader.onStopCallbackAntennaButton = this.onStopCallback
    if (onItemDecoded) {
      RfidReader.onDecodedItemCallback = onItemDecoded
    }
    if (decodeFunction) {
      RfidReader.decodeFunction = decodeFunction
    }
    if (decodeRequest) {
      RfidReader.decodeRequest = decodeRequest
    }
    if (onTagReadCallback) {
      RfidReader.onTagReadCallback = onTagReadCallback
    }
    if (setOnDecodedItemCallback) {
      setOnDecodedItemCallback()
    }
    RfidReader.onPendingTagsChangeAntennaButton = (pendingTags: number) => this.setState({ pendingTags })
  }

  shouldComponentUpdate = (nextProps) => {
    // aggiunta gestione per rimuovere in automatico dei tag UHF dal reader se passati in props al componente
    // gestione fatta in questo componente per evitare che initialize fatto al componentDidMount resetti i tags dopo che siano stati impostati dalla schermata padre
    if (
      this.props.initReaderTags &&
      nextProps.initReaderTags &&
      JSON.stringify(nextProps.initReaderTags) !== JSON.stringify(this.props.initReaderTags)
    ) {
      RfidReader.initUhfTags(nextProps.initReaderTags)
    }
    return true
  }

  componentWillUnmount() {
    if (this.props.mode === 'antenna') this.stop()
    RfidReader.clear()
  }

  async start(): Promise<boolean> {
    if (RfidReader.isReading()) return false
    const { onError, onStart } = this.props
    this.setState({ starting: true })
    onStart?.()
    const result = await RfidReader.start(undefined, undefined, onError)
    this.setState({ starting: false })
    return result
  }

  async stop(): Promise<boolean> {
    return RfidReader.isReading() ? RfidReader.stop() : false
  }

  onStartCallback = () => this.setState({ reading: true })

  onStopCallback = () => this.setState({ reading: false })

  onClickAntennaButton = async () => {
    const { loading } = this.state
    if (loading || this.props.disabled) return
    if (this.props.mode === 'antenna') {
      this.setState({ loading: true })
      try {
        RfidReader.isReading() ? await this.stop() : await this.start()
      } catch (error) {
        //ignore
      }
      this.setState({ loading: false })
    } else if (this.props.mode === 'barcode') {
      this.setState({ barcodeModalOpen: true })
    }
  }

  onClear = async () => {
    const { clearing } = this.state
    const { onClear } = this.props
    if (clearing) return
    this.setState({ clearing: true })
    try {
      RfidReader.clear()
      onClear && (await onClear())
    } catch (error) {
      //ignore
    }
    this.setState({ clearing: false })
  }

  renderPlayIcon = () => {
    const { starting, loading, reading } = this.state
    const style = {
      width: 28,
      height: 28,
    }
    if (reading) {
      return <Icons.Pause style={style} />
    }
    if (starting || loading) {
      return <Icons.Loader style={style} />
    }

    return <Icons.Play style={{ ...style, marginLeft: this.props.iconOnly ? 10 : 0 }} />
  }

  renderClearIcon = () => {
    const { clearing } = this.state
    const style = {
      width: 28,
      height: 28,
    }
    if (clearing) {
      return <Icons.Loader style={style} />
    }

    return <Icons.Retry style={style} />
  }

  startReader = async () => {
    if (!RfidReader.isReading()) {
      await this.onClickAntennaButton()
    }
  }

  stopReader = async () => {
    if (RfidReader.isReading()) {
      await this.onClickAntennaButton()
    }
  }

  submitBarcodeInput = () => {
    const { barcodeModeOptions } = this.props
    const { barcodeInputString } = this.state
    if (!barcodeModeOptions) return
    const barcode = barcodeInputString.trim()
    this.setState({ barcodeModalOpen: false, barcodeInputString: '' }, async () => {
      if (!barcode) return
      const codes: string[] = String(barcode).replace(/ /g, '').replace(',', '\n').split('\n') ?? []
      if (barcodeModeOptions.displayMode === 'modal') {
        if (barcodeModeOptions.modalOptions.validateMode === 'splittedBatch') {
          for (const code of codes) {
            await RfidReader.emulateTagAuto({ epc: code })
            await sleep((RfidReader.debounceDecodeTime || 300) + 200)
          }
        } else {
          await RfidReader.emulateTagAuto(codes.map((code) => ({ epc: code })))
        }
        setTimeout(() => {
          const reOpenAfterSubmit =
            this.props.barcodeModeOptions.displayMode === 'modal' &&
            this.props.barcodeModeOptions.modalOptions?.reOpenAfterSubmit
          if (reOpenAfterSubmit) {
            this.setState({ barcodeModalOpen: true })
          }
        }, 2000)
      } else {
        // displayMode is "inputReplacesAntennaButton"
        await RfidReader.emulateTagAuto(codes.map((code) => ({ epc: code })))
      }
    })
  }

  render() {
    const {
      mode,
      style,
      buttonStyle,
      onClear,
      hideClear,
      showPendingTags = true,
      iconOnly,
      disabled,
      overrideStartLabel,
      barcodeModeOptions,
    } = this.props
    const { reading, pendingTags, barcodeModalOpen, barcodeInputString } = this.state
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animations.Onde,
      rendererSettings: {
        preserveAspectRatio: 'none',
      },
    }

    const barcodeInputReplacingAntennaButton =
      mode === 'barcode' && barcodeModeOptions?.displayMode === 'inputReplacesAntennaButton'

    return (
      <>
        {barcodeInputReplacingAntennaButton ? (
          <Box style={{ backgroundColor: 'white', borderRadius: 10, padding: 10, opacity: disabled ? 0.6 : 1 }}>
            <Text style={{ fontWeight: 'bold', marginBottom: 4 }}>{__(T.userSettings.barcodeItemRead)}</Text>
            <Input
              key={barcodeInputString}
              disabled={disabled}
              value={barcodeInputString}
              onEnter={(txt) => this.setState({ barcodeInputString: txt }, () => this.submitBarcodeInput())}
            />
          </Box>
        ) : (
          <Container row center style={style}>
            <ButtonControl
              data-testid={`antenna-button`}
              style={{ ...buttonStyle }}
              row
              center
              reading={reading}
              iconOnly={iconOnly}
              onClick={this.onClickAntennaButton}
              disabled={disabled}
            >
              {this.renderPlayIcon()}
              {!reading && !iconOnly && (
                <Box flex center>
                  {overrideStartLabel ?? (mode === 'antenna' ? __(T.misc.start) : __(T.misc.input))}
                </Box>
              )}
            </ButtonControl>
            {reading && !iconOnly && (
              <div onClick={this.onClickAntennaButton}>
                <Lottie isClickToPauseDisabled style={{ minWidth: 200, height: 70 }} options={defaultOptions} />
              </div>
            )}
            {!hideClear && onClear && !reading && (
              <>
                <Spacer style={{ height: 5, width: 5, minHeight: 5, minWidth: 5 }} />
                <ButtonClear style={buttonStyle} row center onClick={this.onClear || undefined}>
                  {this.renderClearIcon()}
                  {!iconOnly && (
                    <Box flex center>
                      {__(T.misc.clear)}
                    </Box>
                  )}
                </ButtonClear>
              </>
            )}
          </Container>
        )}
        {showPendingTags && pendingTags > 0 && (
          <PendingContainer row>
            {__(T.misc.pending_tags)} <div style={{ fontWeight: 900 }}>{pendingTags}</div>
          </PendingContainer>
        )}
        {barcodeModalOpen && (
          <Modal
            onClose={() => this.setState({ barcodeModalOpen: false })}
            visible={barcodeModalOpen}
            size="4xl"
            showCloseButton={true}
            title={__(T.misc.rfid_barcodes)}
            align="center"
            padding="8"
            paddingDivContent={0}
          >
            <Box flex>
              <TextArea
                placeholder={__(T.messages.input_rfid_barcodes)}
                startFocus
                onChange={(newValue) => this.setState({ barcodeInputString: newValue })}
                onEnter={() => {
                  if (
                    barcodeModeOptions.displayMode === 'modal' &&
                    barcodeModeOptions.modalOptions.validateMode === 'itemByItem'
                  )
                    this.submitBarcodeInput()
                }}
                textAreaStyle={{ height: 300, resize: 'none' }}
              />
              <Button
                title={__(T.misc.submit)}
                onClick={() => this.submitBarcodeInput()}
                style={{
                  marginTop: 25,
                  alignSelf: 'end',
                }}
                size="medium"
              />
            </Box>
          </Modal>
        )}
      </>
    )
  }
}

const PendingContainer = styled(Box)`
  margin-top: 15px;
  background: #e4e4e4;
  border-radius: 50px;
  padding: 10px 22px;
  font-weight: 500;
  font-size: 18px;
  justify-content: space-between;
`

const Container = styled(Box)`
  background-color: white;
  box-shadow: 0 1px 5px 0 #00000014;
  border-radius: 50px;
  padding: 7px;
`

const ButtonControl = styled(Box)<{ reading?: boolean; iconOnly?: boolean; disabled?: boolean }>`
  flex: ${(props) => (props.reading ? 0 : 1)};
  /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05), inset 0 -1px 4px rgba(0, 0, 0, 0.1); */
  border-radius: 50px;
  height: 70px;
  min-width: 70px;
  width: ${({ iconOnly }) => (iconOnly ? '70px' : 'unset')};
  cursor: pointer;
  font-weight: 900;
  padding: ${({ iconOnly }) => (iconOnly ? '0px' : '0 20px')};
  font-size: 22px;
  position: relative;
  background-color: ${({ reading }) => (reading ? '#525252' : '#ceff00')};
  margin-right: ${({ reading, iconOnly }) => (reading && !iconOnly ? '5px' : '0')};
  opacity: ${({ disabled }) => (disabled ? '0.5 !important' : '1')};
`

const ButtonClear = styled(ButtonControl)`
  background-color: #e9e9e9;
`

import { Component, ReactNode } from 'react'
import { Box, Button, Icons, Input, List, Page } from 'stylewhere/components'
import { Extensions } from 'stylewhere/extensions/Extensions'
import { Routes } from 'stylewhere/pages'
import { OperationConfig, Router } from 'stylewhere/shared'
import { T, __ } from 'stylewhere/shared/i18n'
import { capitalize } from 'stylewhere/shared/utils'

interface Props {
  submitPath?: Routes
  extensions?: typeof Extensions
  operation: OperationConfig
  onRowClick?: (item: any) => void
  data?: any[]
  customRowRender?: (item: any, index?: number) => ReactNode
  onChangeSearch?: (value: string) => void
  hideNewButton?: boolean
  title?: string
}

export class OperationList extends Component<Props> {
  rowRender = (item: any, index: number): ReactNode => {
    const { customRowRender, onRowClick } = this.props
    if (customRowRender) return customRowRender(item, index)

    return <List.Row onClick={onRowClick}>{item.description}</List.Row>
  }

  render() {
    const { operation, onChangeSearch, hideNewButton, submitPath, data } = this.props
    const operationType = operation.type.toLowerCase()
    return (
      <Page title={`${operation.description ?? operationType}`} onBackPress={() => Router.navigate('/')}>
        <Page.Content notBoxed>
          <Box p={5} pb={0} mb={40} row justify={'space-between'}>
            {!!onChangeSearch && (
              <Input
                onChange={onChangeSearch}
                placeholder={`${__(T.misc.search)} ${operationType}`}
                image={<Icons.Barcode />}
                style={{ width: 325 }}
                autoFocus
                debounce={300}
              />
            )}
            {!onChangeSearch && <Box />}
            {!hideNewButton && !!submitPath && (
              <Button
                title={`${__(T.misc.new)} ${operationType}`}
                onClick={() => Router.navigate(submitPath, { opCode: operation.code, code: '' })}
              />
            )}
          </Box>
          <List
            title={`${this.props.title ?? capitalize(operationType)} (${data?.length ?? 0})`}
            loading={!data}
            data={data}
            rowRender={this.rowRender}
          />
        </Page.Content>
      </Page>
    )
  }
}

import { Component } from 'react'
import styled from '@emotion/styled'
import { Box, RoundedLabel } from 'stylewhere/components'
import { ProductInfoField, TmrProduct } from 'stylewhere/api'
import { getProductCode } from 'stylewhere/shared/utils'
import { __, T } from 'stylewhere/shared/i18n'
import _ from 'lodash'

interface Props {
  product: TmrProduct
  overrideProductInfoFields?: ProductInfoField[]
}

const productInfos: ProductInfoField[] = [
  { label: __(T.misc.size), path: 'product.size.value' },
  { label: __(T.misc.color), path: 'product.color.value' },
]

export class GroupedProductRowInfo extends Component<Props> {
  render() {
    const { product, overrideProductInfoFields } = this.props
    return (
      <Box flex ph={15} style={{ alignSelf: 'center' }}>
        <Box mb={product && product.id ? 10 : 0}>
          <ProductCode data-testid={`product-code`}>{getProductCode(product)}</ProductCode>
          {product.description && (
            <ProductDescription data-testid={`product-description`}>{product.description}</ProductDescription>
          )}
        </Box>
        {product && product.id && (
          <Box row style={{ justifyContent: 'flex-start', flexWrap: 'wrap' }}>
            {(overrideProductInfoFields ?? productInfos).map((info) => (
              <RoundedLabel
                key={info.label}
                data-testid={`product-info-${info.label}`}
                label={info.label}
                value={_.get(product, info.path ?? '', 'n/a')}
              />
            ))}
          </Box>
        )}
      </Box>
    )
  }
}

const ProductDescription = styled.label`
  font-size: 15px;
  word-break: break-word;
`
const ProductCode = styled.label`
  font-weight: bold;
  font-size: 20pt;
  word-break: break-word;
`

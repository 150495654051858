import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import * as Sentry from '@sentry/react'
import { Modal, Button, TextArea, Input, Icons, FloatingButton } from 'stylewhere/components'
import { __ } from 'stylewhere/i18n'
import { AppStore } from 'stylewhere/shared'
import { showToastError, showToast } from 'stylewhere/utils'
import { datadogRum } from '@datadog/browser-rum'

interface BugModalProps {
  enableSentry?: boolean
  enableDatadog?: boolean
}

export const BugModal: React.FC<BugModalProps> = ({ enableSentry = true, enableDatadog = false }) => {
  const [shouldShow, setShouldShow] = useState(false)
  const [visible, setVisible] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [name, setName] = useState(
    AppStore.loggedUser ? `${AppStore.loggedUser.name} ${AppStore.loggedUser.surname || ''}`.trim() : '',
  )
  const [email, setEmail] = useState(AppStore.loggedUser?.email || '')
  const [description, setDescription] = useState('')

  useEffect(() => {
    const checkSentryAvailability = async () => {
      const enabled = await AppStore.getDataDogApplicationSettings('enableSentryMonitoring', 'True')
      const feedbackEnabled = await AppStore.getDataDogApplicationSettings('enableSentryFeedbackUser', 'True')
      const isDevelopment = process.env.NODE_ENV === 'development'

      setShouldShow(
        enabled.toLowerCase() === 'true' && feedbackEnabled.toLowerCase() === 'true' && !isDevelopment && enableSentry,
      )
    }

    checkSentryAvailability()
  }, [enableSentry])

  if (!shouldShow) {
    return null
  }

  const sendToSentry = async () => {
    const feedbackEvent = new Error('User Feedback')
    const eventId = Sentry.captureException(feedbackEvent, {
      level: 'info',
      extra: {
        feedback: description,
        timestamp: new Date().toISOString(),
        source: 'custom_modal',
      },
    })

    if (!eventId) {
      throw new Error("Errore nella generazione dell'evento Sentry")
    }

    Sentry.captureUserFeedback({
      event_id: eventId,
      name,
      email,
      comments: description,
    })
  }

  const sendToDatadog = async () => {
    return new Promise<void>((resolve) => {
      datadogRum?.addAction('User Feedback', {
        type: 'custom',
        feedback_type: 'bug_report',
        user_name: name,
        user_email: email,
        description: description,
        timestamp: new Date().toISOString(),
        user_id: AppStore.loggedUser?.id,
        source: 'bug_modal',
        dd: { sessionSampleRate: 100 },
      })

      // Verifica che datadogRum sia inizializzato
      if (!datadogRum) {
        console.warn('DatadogRum non è inizializzato!')
      }

      resolve()
    })
  }

  const handleSubmit = async () => {
    if (!AppStore.loggedUser?.id) {
      showToastError(__('È necessario essere autenticati per inviare feedback'))
      return
    }

    try {
      setIsSubmitting(true)

      const promises: Promise<any>[] = []
      if (enableSentry) {
        promises.push(sendToSentry())
      }
      if (enableDatadog) {
        promises.push(sendToDatadog())
      }

      await Promise.all(promises)

      setVisible(false)
      showToast({
        title: __('titles.report_problem'),
        description: __('messages.sentry.success'),
        status: 'success',
        duration: 5000,
      })

      setDescription('')
      setEmail(AppStore.loggedUser?.email || '')
      setName(AppStore.loggedUser ? `${AppStore.loggedUser.name} ${AppStore.loggedUser.surname || ''}`.trim() : '')
    } catch (error) {
      console.error("Errore nell'invio del feedback:", error)
      showToastError(__('error.generic'))
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <FloatingButton position="bottom-right" onClick={() => setVisible(true)} title={__('titles.report_problem')}>
        <Icons.Bug />
      </FloatingButton>

      <Modal size="2xl" title={__('titles.report_problem')} visible={visible} onClose={() => setVisible(false)}>
        <ModalWrapper>
          <Container>
            <Description>{__('misc.sentry.report_problem')}</Description>

            <Input label={__('misc.name')} defaultValue={name} onChange={setName} disabled={isSubmitting} />

            <Input label={__('misc.email')} defaultValue={email} onChange={setEmail} disabled={isSubmitting} />

            <TextAreaWrapper>
              <TextArea
                label={__('misc.description')}
                defaultValue={description}
                onChange={setDescription}
                required
                disabled={isSubmitting}
              />
            </TextAreaWrapper>

            <ButtonContainer>
              <Button onClick={handleSubmit} disabled={isSubmitting || !description.trim()}>
                {isSubmitting ? __('Invio in corso...') : __('button.submit')}
              </Button>
            </ButtonContainer>
          </Container>
        </ModalWrapper>
      </Modal>
    </>
  )
}

const ModalWrapper = styled.div`
  max-width: 90vw;
`

const TextAreaWrapper = styled.div`
  textarea {
    min-height: 120px !important;
    resize: vertical;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`

const Description = styled.p`
  font-size: 16px;
  color: #666;
  margin: 0;
  margin-bottom: 8px;
`

import { ToastId } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { useCallback } from 'react'
import { Button, Icons } from 'stylewhere/components'
import { SystemError } from 'stylewhere/shared/errors'
import { closeModal, openModal } from 'stylewhere/shared/utils'
import { ErrorDetailsModal } from './modals/ErrorDetailsModal'
import { T, __ } from 'stylewhere/shared/i18n'

export interface CustomToastProps {
  id?: ToastId
  title: string
  description: string
  onClose?: () => void
  err?: SystemError
}

function errorToErrorDescription(err?: SystemError) {
  if (err instanceof Error) {
    return err.message
  }
  if (typeof err === 'string') {
    return err
  }
  return 'Unknown error'
}

export const CustomToast = (props: CustomToastProps) => {
  const { id, onClose, title, description, err } = props

  const errorDescription = errorToErrorDescription(err)
  const onClickExpand = useCallback(() => {
    const MODAL_ID = `errorDetailsModal_${id ?? ''}`
    openModal({
      id: MODAL_ID,
      modal: (
        <ErrorDetailsModal
          errorDetail={errorDescription}
          isOpen
          title={'Error Details'}
          onClose={() => closeModal(MODAL_ID)}
        />
      )
    })
  }, [errorDescription, id])

  return (
    <CustomToastBox>
      <IconBox>
        <Icons.Close className={'svgColored'} style={{ color: 'white' }} />
      </IconBox>
      <TextBox>
        <Title>{title}</Title>
        <Description>{description}</Description>
        {err && (
          <Button variant="white" size="small" onClick={onClickExpand}>
            {__(T.misc.detail)}
          </Button>
        )}
        {/* {err && <ExpandBox onClick={onClickExpand}>{description}</ExpandBox>} */}
      </TextBox>
      <CloseBox>
        <Icons.Cross
          width={11}
          height={11}
          className={'svgColored'}
          style={{ color: 'white', cursor: 'pointer' }}
          onClick={onClose}
        />
      </CloseBox>
    </CustomToastBox>
  )
}

const CustomToastBox = styled.div`
  padding: 10px;
  font-style: normal;
  border-radius: 5px;
  background-color: #ef4444;
  color: white;
  display: flex;
`
const CloseBox = styled.div`
  padding: 5px;
`
const IconBox = styled.div`
  padding: 5px;
`
const TextBox = styled.div`
  padding: 2px;
`
const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  padding: 2px;
`
const Description = styled.div`
  padding: 2px;
  padding-bottom: 10px;
`


import { Component } from 'react'
import styled from '@emotion/styled'
import { Box, Image, Spacer, Icons } from 'stylewhere/components'
import { ProductInfoField, Products, TmrProduct } from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'
import { getField } from 'stylewhere/utils'
import { MotionStyle } from 'framer-motion'

interface Props {
  title?: string
  subtitle?: string
  imageUrl?: string
  data?: any
  fields?: ProductInfoField[]
  showMore?: any
  viewMore?: boolean
  onCloseClick?: () => void
  style?: MotionStyle
}

export class InfoBox extends Component<Props> {
  static Field = ({ label, value = '---' }: { label?: string; value?: string }) => (
    <ContainerValue row>
      <LabelText data-testid="label-text">{label}</LabelText>
      <ValueText data-testid="value-text">{value}</ValueText>
    </ContainerValue>
  )

  getValue = (field: ProductInfoField) => {
    const { data } = this.props
    if (field.path) return getField(data, field.path)
    if (field.paths) return field.paths.map((path) => getField(data, path) as string).join(' - ')
    return '---'
  }

  render() {
    const { title, style, subtitle, imageUrl, fields = [], showMore, viewMore, onCloseClick } = this.props
    return (
      <CardContainer data-testid={`info-box`} data-cy={title} style={style}>
        <Box justify={'space-between'} row>
          <Box>
            {title && <Title>{title}</Title>}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </Box>
          {onCloseClick && (
            <Box onClick={onCloseClick}>
              <Icons.Close />
            </Box>
          )}
        </Box>
        <Box row>
          {imageUrl && (
            <>
              <Image transparent width={120} variant="boxed" src={imageUrl} />
              <Spacer />
            </>
          )}

          <Box flex>
            {fields.map((field) => (
              <InfoBox.Field label={field.label} value={this.getValue(field) ?? '---'} />
            ))}
            {showMore && showMore !== undefined && (
              <ViewAttributeBox onClick={showMore}>
                {viewMore ? __(T.misc.hide_more) : __(T.misc.show_more)}
              </ViewAttributeBox>
            )}
          </Box>
        </Box>
      </CardContainer>
    )
  }
}

const CardContainer = styled(Box)`
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px;
  margin: 2px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
`

const ContainerValue = styled(Box)`
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 10px;
  padding-bottom: 10px;
  &:last-child {
    border-bottom: 0 solid #e0e0e0;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
`

const Title = styled.div`
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;

  color: #000000;

  opacity: 0.4;
  margin-bottom: 15px;
`

const Subtitle = styled.div`
  font-size: 22px;
  line-height: 0px;
  font-weight: 500;
  display: flex;
  align-items: center;

  color: #000000;

  margin-bottom: 30px;
`

const LabelText = styled.div`
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
`
const ValueText = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: right;
`

const CodeLabel = styled.span`
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  display: flex;
  align-items: center;
  word-break: break-all;
`

const DescriptionLabel = styled.span`
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
`

const ViewAttributeBox = styled(Box)`
  font-size: 14px;
  line-height: 16px;
  text-align: right;
`

import React, { Component } from 'react'
import {
  DecodedItem,
  Shippings,
  ShippingParcel,
  Parcels,
  TmrProduct as StwProduct,
  ShippingParcelChecklist,
} from 'stylewhere/api'
import { T, __, __UP } from 'stylewhere/i18n'
import {
  Router,
  RemoteOperation,
  AppStore,
  OperationReadingState,
  OperationReadingProps,
  checkRequiredField,
  getDataFromSchema,
  RfidReader,
  OperationReadingProvider,
} from 'stylewhere/shared'
import { config } from 'stylewhere/config'
import { ShippingExtensions } from 'stylewhere/extensions'
import { ShippingOperationConfig } from 'stylewhere/shared/RemoteOperation'
import {
  AntennaButton,
  Box,
  Button,
  OperationReadingCounters,
  OperationReadingList,
  Page,
  Spacer,
  TagCounter,
  Stoplight,
  FullLoadingLayer,
  Modal,
  ParcelInfoRow,
  Text,
} from 'stylewhere/components'
import {
  showToast,
  showToastError,
  filterErrorItems,
  filterIgnoredItems,
  filterIgnoredAndErrorItems,
  askUserConfirmation,
  getChecklistItemsCount,
  validateChecklistReadings,
  hasAsyncConfirm,
  RETRY_SSE_TIME,
  getSseEndpoint,
  checkItemsCounterError,
  openModal,
  closeModal,
  isModalError,
  hasChecklist,
} from 'stylewhere/shared/utils'
import { CustomError } from 'stylewhere/shared/errors'

interface State extends OperationReadingState {
  parcel?: ShippingParcel
  confirming: boolean
  confirmedItems: DecodedItem<string>[]
  lastAsyncID: string
  sseStatus: boolean
  retrySseCount: number
  expected: number
  detected: number
  checklistId: string
  checklist?: ShippingParcelChecklist | null
  products?: { [upc: string]: StwProduct }
  closing: boolean
  showCloseButton: boolean
}

export default class ShippingReading extends Component<OperationReadingProps<State>, State> {
  antennaRef
  matchParams = Router.getMatchParams(this.props)
  locationState = Router.getLocationState<State>(this.props)
  operation = RemoteOperation.getOperationConfig<ShippingOperationConfig>(this.matchParams.opCode)
  formSchema = ShippingExtensions.formSchema(this.operation, { parcel: this.locationState.parcel })
  sse: any
  isModal = false

  state: State = {
    items: [],
    confirmedItems: [],
    loading: true,
    formData: this.locationState.formData ?? {},
    confirming: false,
    lastAsyncID: '',
    sseStatus: false,
    retrySseCount: 0,
    expected: 0,
    detected: 0,
    checklistId: '',
    checklist: undefined,
    closing: false,
    showCloseButton: false,
  }

  constructor(props) {
    super(props)
    this.antennaRef = React.createRef()
  }

  isBatchReadingMode = this.operation.persistParcelContentScannings === 'onBatchRead'

  async componentDidMount() {
    if (!this.locationState.formData || !this.locationState.checklist || this.locationState.checklist === null) {
      this.goBack()
      return
    }

    this.isModal = isModalError(this.operation)
    RfidReader.setBatchInterval(this.operation.batchInterval)
    RfidReader.setBatchIntervalTagCount(this.operation.batchIntervalTagCount)
    RfidReader.setBatchIntervalTime(this.operation.batchIntervalTime)
    RfidReader.setAutomaticStop(this.operation.autostopAntennaTimeout > 0)
    RfidReader.setAutomaticStopTime(this.operation.autostopAntennaTimeout)
    OperationReadingProvider.init(this.operation, this.locationState, this.goBack, this.setRfidReaderDecode)

    let parcel: ShippingParcel | undefined = this.locationState.parcel
    if (this.isBatchReadingMode) {
      try {
        if (!checkRequiredField(this.state.formData, this.formSchema)) {
          await this.openRequiredFieldsModal()
        }
        const createParcelResponse = await this.createParcel()
        parcel = createParcelResponse?.parcel
        if (!parcel) throw new Error('Missing parcel')
      } catch (error) {
        showToastError(error, __(T.error.error), this.isModal)
      }
    }
    const checklist = this.locationState.checklist
    this.setState(
      {
        parcel,
        sseStatus: !hasAsyncConfirm(this.operation),
        detected: checklist && checklist !== null ? checklist.totalDetectedExpected ?? 0 : 0,
        expected: checklist && checklist !== null ? checklist.totalExpected ?? 0 : 0,
        checklistId: checklist && checklist !== null ? checklist.id : '',
        checklist: checklist,
        products: this.locationState.products,
      },
      this.attachSse
    )
  }

  componentWillUnmount = () => {
    this.destroySse()
  }

  attachSse = async () => {
    if (!hasAsyncConfirm(this.operation) || !AppStore.loggedUser) {
      return
    }
    const sseUrl = getSseEndpoint(config)
    this.sse = new EventSource(sseUrl, { withCredentials: false })
    this.sse.onerror = () => {
      this.setState({ sseStatus: false, retrySseCount: this.state.retrySseCount + 1 }, this.retrySse)
    }
    this.sse.onopen = (event) => {
      this.setState({ sseStatus: true, retrySseCount: 0 })
    }
    this.sse.onmessage = (event) => {
      this.sseEventMessage(event)
    }
  }

  sseEventMessage = async (event) => {
    const { lastAsyncID } = this.state
    const eventData = event.data ? JSON.parse(event.data) : undefined
    if (eventData && eventData.data) {
      if (eventData.data.asyncExecutionId === lastAsyncID) {
        if (eventData.data.executionStatus === 'OK') {
          try {
            const res = await Parcels.getById(eventData.data.details.operationInstanceId)
            this._confirmSuccess(res)
          } catch (error) {
            this._confirmSuccess()
          }
        } else {
          this._confirmFailed(eventData.data.details || '')
        }
      }
    }
  }

  retrySse = () => {
    this.destroySse()
    if (this.state.retrySseCount === 1) {
      this.attachSse()
    } else {
      setTimeout(() => {
        this.attachSse()
      }, RETRY_SSE_TIME)
    }
  }

  destroySse = () => {
    if (this.sse) {
      this.sse.close()
      this.sse = undefined
    }
  }

  getDecodeRequest = () => {
    const { parcel } = this.state
    const decodePayload = getDataFromSchema(this.state.formData, this.formSchema)
    decodePayload.operationId = this.operation.id
    decodePayload.parcelCode = parcel?.code
    return {
      url: Shippings.batchValidateEndpoint(this.operation),
      payload: {
        ...decodePayload,
        asn: undefined,
        // asn: { ...decodePayload.asn, originPlaceId: AppStore.defaultWorkstation?.placeId }
      },
    }
  }

  setRfidReaderDecode = () => {
    RfidReader.setOnDecodedItemCallback(this.onDecodedItemCallback, this.getDecodeRequest())
  }

  updateParcel = async (action: 'INSERT' | 'UPDATE' | 'CLEAR' | 'REMOVE', itemIds: string[], parcelCode: string) => {
    const parcel = await Shippings.updateParcel(this.operation, {
      operationId: this.operation.id,
      itemIds,
      action,
      parcelCode,
    })
    return parcel
  }

  onDecodedItemCallback = async (itemMapFromReader: { [tagCode: string]: DecodedItem }) => {
    const { items, formData } = this.state
    const processedItems: DecodedItem[] = await OperationReadingProvider.processDecodedItems(
      this.operation,
      itemMapFromReader,
      items,
      formData,
      ShippingExtensions
    )
    if (this.isBatchReadingMode) {
      if (!this.state.parcel) return
      try {
        await this.updateParcel(
          'INSERT',
          processedItems
            .filter((itm) =>
              Object.values(itemMapFromReader)
                .map((readerItem) => readerItem.item?.id)
                .includes(itm.item?.id)
            )
            .filter(filterIgnoredAndErrorItems)
            .flatMap(({ item }) => (item?.id ? item.id : [])),
          this.state.parcel?.code
        )
      } catch (error) {
        showToastError(error, __(T.error.error), this.isModal)
      }
    }
    this.setState({ items: processedItems, showCloseButton: false })
  }

  async removeItem(decodedItem: DecodedItem) {
    if (this.isBatchReadingMode) {
      if (!this.state.parcel) return
      await this.updateParcel(
        'REMOVE',
        Object.values(decodedItem)
          .map((itm) => itm.item?.id)
          .filter(Boolean) as string[],
        this.state.parcel?.code
      )
    }
    const items = OperationReadingProvider.removeItem(decodedItem, this.state.items)
    this.setState({ items })
  }

  onConfirm = async () => {
    const { items } = this.state
    if (items.filter(filterErrorItems).length > 0) {
      showToastError(__(T.error.confirm_not_allowed_with_errors), __(T.error.error), true)
    } else {
      this.setState({ confirming: true }, this._confirm)
    }
  }

  onClose = async () => {
    const { items } = this.state
    if (items.filter(filterErrorItems).length > 0) {
      showToastError(__(T.error.close_not_allowed_with_errors), __(T.error.error), true)
    } else {
      this.setState({ closing: true }, this._close)
    }
  }

  createParcel = async () => {
    let parcel = this.state.parcel ?? this.locationState.parcel
    const { items, formData } = this.state
    const confirmData = getDataFromSchema(formData, this.formSchema)
    if (!parcel) {
      parcel = await this.startParcel(confirmData)
    }
    return { parcel, confirmData, items }
  }

  startParcel = async (confirmData) => {
    if (!AppStore.defaultWorkstation?.placeId) throw new Error('Missing workstation place')
    const payload = {
      operationId: this.operation.id,
      attributes: confirmData.attributes ?? {},
      ...confirmData,
      checklistId: this.state.checklistId,
      //asn: { ...confirmData.asn },
    }
    try {
      const parcel = await Shippings.startParcel(this.operation, payload)
      return parcel
    } catch (error) {
      if (error instanceof CustomError) {
        showToastError(
          `${CustomError.interpolateMessage(error.message, payload)}. [${error.errorCode}]`,
          __(T.error.error),
          this.isModal
        )
        return
      }
      console.error(error)
    }
  }

  openRequiredFieldsModal = async (isShowingOnConfirm = false) => {
    try {
      await new Promise((resolve, reject) => {
        AppStore.callbacks.openModalForm(
          resolve,
          reject,
          isShowingOnConfirm ? __UP(T.misc.update_and_confirm) : __UP(T.misc.confirm),
          __UP(T.misc.back)
        )
      })
    } catch (error) {
      this.setState({ confirming: false })
      return
    }
  }

  _confirm = async () => {
    const { checklist, items, products } = this.state
    const asyncJobs = hasAsyncConfirm(this.operation)
    try {
      const { unexpecteds, overQty } = getChecklistItemsCount(
        items,
        this.operation,
        ShippingExtensions,
        checklist,
        products
      )
      if (this.operation.canConfirmWithOverQuantity === 'no' && overQty > 0)
        throw new Error(__(T.error.confirm_with_overqty))
      if (this.operation.canConfirmWithUnexpected === 'no' && unexpecteds > 0)
        throw new Error(__(T.error.confirm_with_unexpected))
      if (this.antennaRef && this.antennaRef.current) {
        await this.antennaRef.current.stopReader()
      }

      //check if some data of schema is required and undefined: if check is false then open Modal Form
      if (this.operation.showModalOnConfirm || !checkRequiredField(this.state.formData, this.formSchema)) {
        await this.openRequiredFieldsModal(true)
      }

      if (hasChecklist(this.operation) && this.state.parcel?.checklist) {
        const { validationResult, message } = validateChecklistReadings(
          this.state.items,
          this.operation,
          ShippingExtensions,
          this.state.checklist,
          this.state.products
        )
        if (validationResult === 'KO') {
          showToastError(new Error(message), __(T.error.error), this.isModal)
          this.setState({ confirming: false })
          return
        }
        if (validationResult === 'WARNING') {
          const askConfirmResult = await askUserConfirmation(
            __(T.misc.checklist),
            message,
            __(T.misc.no),
            __(T.misc.yes)
          )
          if (!askConfirmResult) {
            this.setState({ confirming: false })
            return
          }
        }
      }

      const parcelResponse = await this.createParcel()
      let parcel: ShippingParcel | undefined = parcelResponse?.parcel
      const confirmData: Record<PropertyKey, any> = parcelResponse?.confirmData ?? {}
      const newItems = parcelResponse?.items ?? []

      if (!parcel) throw new Error('Missing parcel')

      await ShippingExtensions.beforeConfirm(this.operation, confirmData, newItems)

      if (!this.isBatchReadingMode) {
        await this.updateParcel('CLEAR', [], parcel.code)
        parcel = await this.updateParcel(
          'INSERT',
          newItems
            .filter(filterIgnoredAndErrorItems)
            .filter(({ item }) => item && !this.state.confirmedItems.map((ci) => ci.item?.id).includes(item.id)) // Non manda in conferma gli items già confermati
            .flatMap(({ item }) => (item?.id ? item.id : [])),
          parcel.code
        )
      }

      const confirmParcel = async (shippedWithForce = false) => {
        const attributes = shippedWithForce ? { ...confirmData.attributes, shippedWithForce } : confirmData.attributes

        return Shippings.confirmParcel(
          this.operation,
          {
            parcelCode: parcel?.code,
            operationId: this.operation.id,
            operationPlaceId: AppStore.defaultWorkstation!.placeId,
            destinationZoneId: confirmData.destinationZoneId || '',
            ...confirmData,
            attributes,
          },
          false,
          asyncJobs
        )
      }

      try {
        const result = await confirmParcel(false)

        if (asyncJobs) {
          if (result && (result as any).asyncExecutionId) {
            this.setState({ lastAsyncID: (result as any).asyncExecutionId })
          } else {
            this._confirmFailed()
          }
        } else {
          await this.print(parcel?.code)
          this._confirmSuccess(result)
        }
      } catch (error: any) {
        const errorMessage = error?.message || ''

        if (errorMessage.includes('PACKAGING_CONFIRM_PARCEL_NOT_SHIPPABLE_ERROR')) {
          throw error
        }

        if (errorMessage.includes('PACKAGING_CONFIRM_PARCEL_SHIPPED_WITH_FORCE_ERROR')) {
          const messageToShow = errorMessage.replace('PACKAGING_CONFIRM_PARCEL_SHIPPED_WITH_FORCE_ERROR', '')
          const confirmForce = await askUserConfirmation(
            __(T.misc.warning),
            `${__(T.messages.confirm_force_shipment)} ${messageToShow}`,
            __(T.misc.cancel),
            __(T.misc.confirm)
          )

          if (!confirmForce) {
            this.setState({ confirming: false })
            return
          }

          const result = await confirmParcel(true)

          if (asyncJobs) {
            if (result && (result as any).asyncExecutionId) {
              this.setState({ lastAsyncID: (result as any).asyncExecutionId })
            } else {
              this._confirmFailed()
            }
          } else {
            await this.print(parcel?.code)
            this._confirmSuccess(result)
          }
        } else {
          throw error
        }
      }
    } catch (err) {
      this.setState({ confirming: false })
      if (err instanceof CustomError) {
        const payloadData = getDataFromSchema(this.state.formData, this.formSchema)
        showToastError(
          `${CustomError.interpolateMessage(err.message, payloadData)}. [${err.errorCode}]`,
          __(T.error.error),
          this.isModal
        )
        return
      }
      showToastError(err, __(T.error.error), this.isModal)
    }
  }

  print = async (parcelCode, parcelQuantity?) => {
    const { checklist, items } = this.state
    const pickingListAttributes = {
      operation_id: checklist?.code,
      items_quantity: parcelQuantity ?? items.length,
      ...checklist?.attributes,
    }
    try {
      const operationAttributes = this.operation.attributes as any
      const customIp = AppStore.defaultWorkstation?.attributes.cartonizationUSAPrinterIP
      const printerIp = !!customIp && customIp !== '' ? customIp : operationAttributes.printerIP
      if (!printerIp) throw new Error(__(T.error.missing_printer_id))
      const r = await Shippings.print(parcelCode, pickingListAttributes, printerIp)
    } catch (err) {
      console.error(err)
    }
  }

  _close = async () => {
    try {
      if (this.antennaRef && this.antennaRef.current) {
        await this.antennaRef.current.stopReader()
      }
      const { checklistId } = this.state
      if (!checklistId) throw new Error('Missing checklistId')
      const result: any = await Shippings.closeParcel(this.operation, this.state.checklistId)
      if (result) {
        showToast({
          title: __(T.misc.success),
          description: __(T.messages.generic_success, { code: this.operation.description }),
          status: 'success',
        })
        this.goBack()
      } else {
        throw new Error(this.operation.description)
      }
    } catch (err) {
      this.setState({ closing: false })
      showToastError(err, __(T.error.error), this.isModal)
    }
  }

  _confirmFailed = (message?: string) => {
    showToastError(message && message != '' ? message : __(T.error.operation_confirm_error), __(T.error.error), true)
    this.setState({ confirming: false })
  }

  _confirmSuccess = async (result?) => {
    const { formData, expected, detected, items, checklist, products } = this.state
    const confirmData = getDataFromSchema(formData, this.formSchema)
    await ShippingExtensions.afterConfirm(this.operation, confirmData, result || {})
    this.setState({ confirming: false })
    showToast({
      title: __(T.misc.success),
      description: __(T.messages.generic_success, { code: this.operation.description }),
      status: 'success',
    })
    const { missings } = getChecklistItemsCount(items, this.operation, ShippingExtensions, checklist, products)
    if (missings === 0) return this._close()
    this.setState({ showCloseButton: true })
    if (['hideSuccessModal', 'disabled'].includes(this.operation.postConfirmAction)) {
      this.goBack()
    } else {
      if (this.operation.postConfirmAction === 'keepInput') {
        this.onClearKeepInput()
      } else {
        this.goBack()
      }
    }
  }

  goDashboard = () => {
    Router.navigate('/')
  }

  goBack = () => {
    if (this.formSchema.length) {
      Router.navigate('/shipping/cartonization/:opCode', { opCode: this.operation.code })
    } else {
      this.goDashboard()
    }
  }

  onClear = async () => {
    const { parcel } = this.state
    if (this.isBatchReadingMode && parcel) {
      await this.updateParcel('CLEAR', [], parcel.code)
    }
    this.setState({ items: [] })
    RfidReader.clear()
  }

  onClearKeepInput = async () => {
    const { formData, items, confirmedItems } = this.state
    //const { expecteds, unexpecteds, overQty } = getChecklistItemsCount(items, parcel.checklist)
    const dectected = this.state.detected + items.filter(filterIgnoredAndErrorItems).length
    if (this.isBatchReadingMode) {
      const confirmData = getDataFromSchema(formData, this.formSchema)
      const parcel = await this.startParcel(confirmData)
      this.setState({
        confirmedItems: [...confirmedItems, ...items],
        items: [],
        parcel: parcel,
        detected: dectected,
      })
    } else {
      this.setState({
        confirmedItems: [...confirmedItems, ...items],
        items: [],
        parcel: undefined,
        detected: dectected,
      })
    }
    RfidReader.clear()
  }

  showConfirmButton() {
    const { items, confirming } = this.state
    if (items.filter(filterIgnoredItems).length > 0)
      return (
        <Button style={{ marginTop: 15 }} loading={confirming} title={__(T.misc.confirm)} onClick={this.onConfirm} />
      )
    return null
  }

  showCloseButton() {
    const { closing, items, detected, showCloseButton } = this.state
    if ((items.filter(filterIgnoredItems).length === 0 && detected > 1) || showCloseButton)
      return <Button style={{ marginTop: 15 }} loading={closing} title={__(T.misc.close)} onClick={this.onClose} />
    return null
  }

  setFormData = async (fd) => {
    if (!(await ShippingExtensions.formDataIsValid(fd, this.operation, this.formSchema))) return
    this.setState({ formData: fd })
    this.setRfidReaderDecode()
  }

  showItemDetail = () => {
    const { items, checklist, products } = this.state
    openModal({
      id: 'product-modal',
      modal: (
        <Modal onClose={() => closeModal('product-modal')} isCentered visible size="4xl">
          <Box height={'80vh'}>
            <OperationReadingList
              removeItemCallback={this.removeItem}
              extension={ShippingExtensions}
              items={items}
              operation={this.operation}
              checklist={checklist}
              products={products}
            />
          </Box>
        </Modal>
      ),
    })
  }

  showParcelDetail = async () => {
    const parcels: any = await Shippings.parcelByChecklist(this.operation, this.state.checklistId)
    openModal({
      id: 'parcel-modal',
      modal: (
        <Modal onClose={() => closeModal('parcel-modal')} isCentered visible size="4xl">
          <Box height={'80vh'}>
            {parcels.content.length === 0 && (
              <Box style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontWeight: 'bold' }}>{__(T.misc.no_carton_found)}</Text>
              </Box>
            )}
            {parcels.content.map((parcel) => (
              <ParcelInfoRow parcel={parcel} onPrint={() => this.print(parcel.code, parcel.parcelEntryQuantity)} />
            ))}
          </Box>
        </Modal>
      ),
    })
  }

  render() {
    const { items, confirmedItems, formData, loading, closing, checklist, products, sseStatus, expected, detected } =
      this.state
    let tagCounterExpecteds = 0
    let tagCounterUnexpecteds = 0
    if (checklist) {
      const { expecteds, unexpecteds, overQty } = getChecklistItemsCount(
        items,
        this.operation,
        ShippingExtensions,
        checklist,
        products
      )
      tagCounterExpecteds = expecteds
      tagCounterUnexpecteds = unexpecteds + overQty
    }
    return (
      <Page
        title={this.operation.description}
        onBackPress={() => this.goBack()}
        loading={loading}
        headerRight={hasAsyncConfirm(this.operation) ? <Stoplight active={sseStatus} /> : undefined}
        header={{
          details: {
            data: formData,
            formSchema: this.formSchema,
            setFormData: hasChecklist(this.operation) ? undefined : this.setFormData,
            resetFormData: async (fd) => {
              this.setState({ formData: fd })
            },
            operationId: this.operation.id,
          },
        }}
        enableEmulation
      >
        <Page.Sidebar>
          <Box flex style={{ overflowY: 'auto' }}>
            <Box flex>
              <TagCounter
                detected={items
                  .filter(filterIgnoredItems)
                  .reduce(
                    (acc, item) => acc + OperationReadingProvider.getItemIntrinsicQuantity(item, ShippingExtensions),
                    0
                  )}
                expected={tagCounterExpecteds}
                unexpected={tagCounterUnexpecteds}
                smallCounter={{ detected: detected, expected: expected }}
                onPressSmallCounter={this.showParcelDetail}
                onPress={this.showItemDetail}
                showExpected={false}
              />
            </Box>
            <AntennaButton
              ref={this.antennaRef}
              decodeRequest={this.getDecodeRequest()}
              onItemDecoded={this.onDecodedItemCallback}
              onClear={this.onClear}
              hideClear={items.length === 0}
            />
            {checkItemsCounterError(items) && (
              <>
                <Spacer />
                <OperationReadingCounters operation={this.operation} items={items} />
              </>
            )}
          </Box>
          {this.showConfirmButton()}
          {this.showCloseButton()}
        </Page.Sidebar>
        <Page.Content notBoxed>
          <OperationReadingList
            removeItemCallback={this.removeItem}
            extension={ShippingExtensions}
            items={[...items, ...confirmedItems]}
            operation={this.operation}
            checklist={checklist}
            products={products}
          />
        </Page.Content>
        {(!sseStatus || closing) && (
          <FullLoadingLayer
            message={!sseStatus ? __(T.messages.connection_in_progress) : __(T.messages.operation_in_progress)}
          />
        )}
      </Page>
    )
  }
}

import { Component } from 'react'
import { Box, Modal, Spacer, Chip, Button, KeyValueRow, ModalTitle } from 'stylewhere/components'
import { Auth, TmrRole, TmrUser } from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'
import { AppStore } from 'stylewhere/shared'

interface Props {
  user?: TmrUser
  onClose: () => void
  visible: boolean
}

export class UserInfoModal extends Component<Props> {
  onLogout = async () => {
    this.props.onClose()
    await Auth.logout()
  }

  getUserRoles() {
    const { user } = this.props
    let roles = user?.roles ?? []

    if (user?.admin) {
      const adminRole = { id: 'admin', code: 'admin', description: 'Admin' } as TmrRole
      roles = [adminRole, ...roles]
    }

    return roles
  }

  render() {
    const { user, onClose, visible } = this.props
    const userRoles = this.getUserRoles()

    return (
      <Modal size="xl" onClose={onClose} visible={visible}>
        <Box vcenter row mb={30}>
          <ModalTitle title={__(T.titles.user_info)} />
          {AppStore.loggedUser?.id === user?.id && (
            <Button size="small" title={__(T.misc.logout)} onClick={this.onLogout} variant="secondary" />
          )}
        </Box>

        <Box>
          <Box row>
            <KeyValueRow style={{ flex: 1 }} variant="grey" label={__(T.misc.username)} value={user?.username} />
            <Spacer />
            <KeyValueRow
              style={{ flex: 1 }}
              variant="grey"
              label={__(T.misc.name)}
              value={`${user?.name ?? '---'} ${user?.surname ?? ''}`}
            />
          </Box>
          <Spacer />
          <KeyValueRow variant="grey" label={__(T.misc.email)} value={user?.email} />
          <Spacer />
          <KeyValueRow
            whiteSpace="normal"
            variant="grey"
            label={__(T.misc.place)}
            value={user?.userPlaces?.map((place) => place.description).join(' - ')}
          />
          <Spacer />
          <KeyValueRow
            variant="grey"
            value={
              <Box style={{ overflowX: 'scroll' }} hideBar row mt={5} p={2}>
                {userRoles.map((role) => (
                  <Chip key={role.code} id={role.code} style={{ marginRight: 10 }}>
                    {role.description}
                  </Chip>
                ))}
              </Box>
            }
          />
        </Box>
      </Modal>
    )
  }
}

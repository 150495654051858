import { Component } from 'react'
import { Box, Tab, EncodingDailyItems, AntennaStatus } from 'stylewhere/components'
import { EncodingExtensions } from 'stylewhere/extensions'
import { EncodingOperationConfig } from 'stylewhere/shared/RemoteOperation'

interface Props {
  operation: EncodingOperationConfig
  options: any
  antennaOn?: boolean
  onTabClick?: any
  children?: any
}

export class EncodingRightHeader extends Component<Props> {
  render() {
    const { operation, options, antennaOn, onTabClick, children } = this.props
    return (
      <Box row>
        {children ?? null}
        <EncodingDailyItems code={operation.code} style={{ minWidth: 130, marginRight: 10, borderRadius: 15 }} />
        <Box mr={20} vcenter>
          <Tab
            onclick={onTabClick}
            options={options}
            onOptionSelected={(opt) => EncodingExtensions.getOptionEncodingPage(opt, operation)}
          />
        </Box>
        <Box bg="#D8D8D8" width={1} />
        <AntennaStatus started={antennaOn || false} />
      </Box>
    )
  }
}

import { T, __ } from 'stylewhere/shared/i18n'
import {
  Box,
  Button,
  CustomImage,
  Icons,
  ImagesGallery,
  InboundWorkstationMeasurementsModal,
  KeyValueRow,
  Spacer,
  Table,
} from 'custom/valentino-qc/components'
import { useCallback, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import {
  Defect,
  DefectsTable,
  InboundWorkstationItemConfirmation,
  InboundWorkstationItemInfo,
  SizeAndColorPropery,
} from 'custom/valentino-qc/api/types'
import { dataURLtoBlob, showToastError } from 'custom/valentino-qc/shared/utils'
import { InboundWorkstationApi } from 'custom/valentino-qc/api'
import Webcam from 'react-webcam'
import { Textarea } from '@chakra-ui/react'
import { PhotoProvider } from 'react-photo-view'

type ControlType = 'FIRST' | 'SECOND'
export type ControlStatus = 'OK_VALDAGNO' | 'OK_REPAIR' | 'KO_VALDAGNO' | 'OK_RISC' | undefined

interface Props {
  item?: InboundWorkstationItemInfo
  type: 'single' | 'massive'
  parentDefects?: Defect[]
  childDefects?: Defect[]
  onParentDefectSelected: (defect: Defect) => void
  onChildDefectSelected: (defect: Defect) => void
  clearSelectedDefects: () => void
  onItemConfirmed: (item: InboundWorkstationItemConfirmation, callback: () => void) => void
  currentSelectedMerch?: string
  measurementFields?: any
  mission?: string
  warehouse?: string

  control_status?: ControlStatus
  setControlStatus: (status: ControlStatus) => void
  selectedSizeAndColor: any
  setSelectedSizeAndColors: (sizeColor: any) => void
  measures?: { [key: string]: string }
  setMeasures: (measures: { [key: string]: string }) => void
  defects?: DefectsTable[]
  updateAddedDefects?: (defects: DefectsTable[]) => void
  notes?: string
  setNotes: (notes: string) => void
  server_images?: string[]
}

export default function QualityControlPanelWorkstation({
  item,
  type,
  parentDefects,
  childDefects,
  currentSelectedMerch,
  onChildDefectSelected,
  onParentDefectSelected,
  clearSelectedDefects,
  updateAddedDefects,
  measurementFields,
  mission,
  warehouse,
  onItemConfirmed,

  control_status,
  setControlStatus,
  selectedSizeAndColor,
  setSelectedSizeAndColors,
  measures,
  setMeasures,
  defects = [],
  notes = '',
  setNotes,
  server_images,
}: Props) {
  const [control_type, setControlType] = useState<ControlType>('FIRST')
  const [selected_parent_cause, setSelectedParentCause] = useState<any>(undefined)
  const [selected_cause, setSelectedCause] = useState<any>(undefined)
  const [photo, setPhoto] = useState<any>(undefined)
  //const [selectedSizeAndColor, setSelectedSizeAndColors] = useState<any>(undefined)
  const [sizesAndColors, setSizesAndColors] = useState<SizeAndColorPropery[]>([])
  const [allMeasuresList, setAllMeasuresList] = useState<
    {
      key: string
      label: string
      type: string
    }[]
  >(measurementFields?.find((f) => f.key === currentSelectedMerch?.toUpperCase())?.fields || {})
  const [showMeasurementsModal, setShowMeasurementsModal] = useState(false)
  const [showCamera, setShowCamera] = useState(false)
  const [imagesSrc, setImagesSrc] = useState<any[]>([])
  const webcamRef = useRef(null)
  const [imageLoading, setImageLoading] = useState(false)
  const causeTableStructure = [
    {
      key: 'macrofamily',
      label: 'Macrofamiglia',
      customRender: (cause: DefectsTable, column) => <Box center>{cause.parent!.description}</Box>,
      width: 120,
    },
    {
      key: 'code',
      label: 'Codice',
      customRender: (cause: DefectsTable, column) => (
        <Box center>{cause.child?.attributes?.find((el) => el.key === 'sapCode')?.value ?? ''}</Box>
      ),
      flex: 1,
    },
    {
      key: 'causal',
      label: 'Causale',
      customRender: (cause: DefectsTable, column) => <Box center>{cause.child.description}</Box>,
      width: 150,
    },
    {
      key: 'delete',
      label: 'Elimina',
      customRender: (cause, column) => (
        <Box center>
          <Icons.Delete onClick={() => deleteCause(cause)} />
        </Box>
      ),
      width: 86,
    },
  ]

  const onControlTypeChange = (controlType: ControlType) => {
    setControlType(controlType)
    setControlStatus(undefined)
  }

  const deleteCause = (cause: any) => {
    const newAddedDefects = defects.filter((d) => d.id !== cause.id)
    updateAddedDefects && updateAddedDefects(newAddedDefects)
  }

  const addDefects = () => {
    try {
      const isDefectAlreadyAdded = defects.filter((d) => d.id === selected_cause.value.id).length > 0
      if (isDefectAlreadyAdded) return showToastError('Causale già aggiunta')
      if (defects.length === 5) throw new Error("E' possibile aggiungere massimo 5 difetti")
      if (selected_parent_cause && selected_cause) {
        const newAddedDefects = [
          ...defects,
          { id: selected_cause.value.id, parent: selected_parent_cause.value, child: selected_cause.value },
        ]

        setSelectedParentCause(undefined)
        setSelectedCause(undefined)
        clearSelectedDefects()
        updateAddedDefects && updateAddedDefects(newAddedDefects)
      }
    } catch (error: any) {
      showToastError(error.message || 'Errore aggiunta causale')
    }
  }

  const confirmQualityControlItem = async () => {
    try {
      if (!control_type) throw new Error('Selezionare tipo controllo')
      if (!control_status) throw new Error('Selezionare stato controllo')
      //if (!selectedSizeAndColor) throw new Error('Selezionare taglia e colore')
      if (control_status === 'KO_VALDAGNO' && defects.length === 0)
        throw new Error('Selezionare almeno una causale per lo status KO')

      let itemData =
        control_type === 'FIRST'
          ? { ...item?.firstSapInboundItem, cqType: 'FIRST' }
          : { ...item?.secondSapInboundItem, cqType: 'SECOND' }
      if (control_type === 'SECOND' && !item?.secondSapInboundItem)
        itemData = { ...item?.firstSapInboundItem, cqType: 'SECOND', id: undefined }

      //populate defects
      itemData.defects = []
      defects.forEach((defect) => {
        itemData.defects?.push(defect.child)
      })
      /***** */

      //populate measurements
      itemData.measurements = measures
      /***** */

      //populate old images, so BE doesn't replace them
      //@ts-ignore
      itemData.existingImages = (item?.firstSapInboundItem?.images as string[]) || []

      //populate images
      // Convert each image source to a blob, then read it as an ArrayBuffer, convert it to a byte array, and finally, resolve it within a Promise
      const imagePromises = imagesSrc.map(
        (img) =>
          new Promise((resolve, reject) => {
            const imageBlob = dataURLtoBlob(img)
            const reader = new FileReader()

            reader.onloadend = function () {
              // The result attribute contains the contents of the blob as an ArrayBuffer
              //@ts-ignore
              const byteArray = new Uint8Array(reader.result)
              resolve({ content: Array.from(byteArray), extension: 'jpg' })
            }

            reader.onerror = reject

            reader.readAsArrayBuffer(imageBlob)
          })
      )

      // Use Promise.all to wait for all FileReader operations to complete
      await Promise.all(imagePromises)
        .then((images) => {
          //@ts-ignore
          itemData.images = images
          // Now itemData.images is an array of objects with content as a byte array and extension 'jpg'
          // You can proceed with any operations that depend on itemData.images being fully populated
        })
        .catch((error) => {
          itemData.images = []
        })

      itemData.status = control_status
      if (selectedSizeAndColor) itemData.sizeColor = selectedSizeAndColor.value
      itemData.notes = notes

      const itemPayload: InboundWorkstationItemConfirmation = {
        mission: mission || '',
        warehouse: warehouse || '',
        //@ts-ignore
        item: itemData,
      }

      console.log('itemPayload', itemPayload)

      onItemConfirmed(itemPayload, () => {
        setSelectedSizeAndColors(undefined)
        setControlStatus(undefined)
        setImagesSrc([])
        updateAddedDefects && updateAddedDefects([])
      })
    } catch (error: any) {
      showToastError(error.message || 'Errore conferma controllo qualità')
    }
  }

  // create a capture function
  const captureImage = useCallback(() => {
    setImageLoading(true)
    if (!webcamRef?.current) return
    //@ts-ignore
    const imageSrc = webcamRef.current.getScreenshot()

    setImagesSrc([...imagesSrc, imageSrc])
    setImageLoading(false)
  }, [webcamRef, imagesSrc])

  const toggleCamera = () => {
    setShowCamera(!showCamera)
  }

  useEffect(() => {
    setSelectedParentCause(undefined)
    setSelectedCause(undefined)
  }, [currentSelectedMerch])

  useEffect(() => {
    setAllMeasuresList(measurementFields?.find((f) => f.key === currentSelectedMerch?.toUpperCase())?.fields || [])
  }, [measurementFields, currentSelectedMerch])

  useEffect(() => {
    async function fetchSizeAndColor() {
      try {
        if (!item) return

        const material =
          item?.firstSapInboundItem?.matNr ||
          item?.document?.attributes?.find((a) => a.key === 'material')?.value ||
          item?.sessionItem?.attributes?.find((a) => a.key === 'material')?.value

        if (!material) throw new Error('Il pezzo non ha un materiale associato')
        if (!material) {
          setSizesAndColors([])
          return
        }

        const res = await InboundWorkstationApi.getSizeColorByMaterial(material)
        if (!res || !res.content || res.content.length === 0) return
        setSizesAndColors(res.content)
      } catch (error: any) {
        showToastError(error.message || 'Errore recupero taglie e colori')
      }
    }
    fetchSizeAndColor()
    setSelectedParentCause(undefined)
    setSelectedCause(undefined)
  }, [item])

  let itemData = item?.firstSapInboundItem
  control_type === 'SECOND' && item?.secondSapInboundItem && (itemData = item?.secondSapInboundItem)

  return (
    <Box row mt={20} style={{ gap: 10 }}>
      <Box flex>
        <Box row>
          <Label>EPC:</Label>
          <Value>{itemData?.epc ?? '---'}</Value>
        </Box>
        <Box row center mt={15}>
          <Button
            title={'I° Controllo'}
            variant={control_type === 'FIRST' ? 'primary' : 'ignore'}
            onClick={() => onControlTypeChange('FIRST')}
          />
          <Spacer width={15} />
          <Button
            title={'II° Controllo'}
            variant={control_type === 'SECOND' ? 'primary' : 'ignore'}
            onClick={() => onControlTypeChange('SECOND')}
          />
        </Box>
        <Box row center justify="space-between" mt={27}>
          <ControlStatusBox
            active={control_status === 'OK_VALDAGNO' || control_status === 'OK_REPAIR'}
            backgroundColor={'#1fd655'}
            onClick={() => setControlStatus('OK_VALDAGNO')}
          >
            OK
          </ControlStatusBox>
          <ControlStatusBox
            active={control_status === 'KO_VALDAGNO'}
            backgroundColor={'#CD5642'}
            onClick={() => setControlStatus('KO_VALDAGNO')}
          >
            {' '}
            KO{' '}
          </ControlStatusBox>
          <ControlStatusBox
            active={control_status === 'OK_RISC'}
            backgroundColor={'#E7A03C'}
            onClick={() => setControlStatus('OK_RISC')}
          >
            {' '}
            RISC
          </ControlStatusBox>
        </Box>
        <Box row center justify="space-between" mt={15} mb={15}>
          {type === 'single' && <Button title="FOTO" variant="default" onClick={toggleCamera} />}
          {/* <Button title="TAGLIA/COLORE" variant="default" /> */}
          <KeyValueRow
            label={__(T.misc.size_color)}
            //style={{ width: '100%' }}
            value={selectedSizeAndColor?.label}
            optionModal={{
              onSelect: (sizeColor) => setSelectedSizeAndColors(sizeColor[0]),
              field: 'label',
              options:
                sizesAndColors.map((s) => {
                  return { value: s.value, label: s.value }
                }) || [],
              selected: [selectedSizeAndColor],
              title: __(T.misc.select_size_color),
              searchable: true,
            }}
          />
          {type === 'single' && (
            <Button title="MISURE" variant="default" onClick={() => setShowMeasurementsModal(true)} />
          )}
        </Box>
        {type === 'single' && (
          <Box>
            <Button
              title="CONFERMA"
              variant="default"
              onClick={confirmQualityControlItem}
              disabled={!item || !control_type || !control_status}
            />
          </Box>
        )}
        {showCamera && (
          <>
            <Spacer height={'15'} />
            <Webcam height={600} width={600} ref={webcamRef} minScreenshotHeight={1080} minScreenshotWidth={1920} />
            <Spacer height={'15'} />
            <Button title="SCATTA" variant="default" onClick={captureImage} disabled={imageLoading} />
          </>
        )}
        {imagesSrc.length > 0 && (
          <Box
            row
            mt={15}
            style={{
              gap: 10,
              overflow: 'auto',
              maxHeight: 200,
              flexWrap: 'wrap',
              maxWidth: '100%',
            }}
          >
            <PhotoProvider>
              {imagesSrc.map((img, i) => (
                <CustomImage image={img} onDelete={() => setImagesSrc(imagesSrc.filter((_, index) => index !== i))} />
              ))}
            </PhotoProvider>
          </Box>
        )}
        <Spacer height={'15'} />
        <ImagesGallery
          imageStyle={{ height: 150, width: 100, objectFit: 'cover', borderRadius: 5, margin: 0 }}
          images={server_images || []}
        />
        {/* <Box row mt={15} justify="space-around" height={60}>
          <Box>
            <Label>CQ Selezionato</Label>
            <Value>{control_status}</Value>
          </Box>
        </Box> */}
        <Box mt={15} ph={15}>
          <Textarea
            value={notes}
            height={200}
            placeholder={__(T.misc.note)}
            onChange={(e) => setNotes(e?.target?.value)}
          />
        </Box>
      </Box>
      <Box flex>
        <Box row mb={15}>
          <KeyValueRow
            label={'Macrofamiglia'}
            style={{ flex: 1 }}
            value={selected_parent_cause?.label}
            optionModal={{
              onSelect: (cat) => {
                setSelectedParentCause(cat[0])
                onParentDefectSelected && onParentDefectSelected(cat[0].value)
              },
              field: 'label',
              options: parentDefects?.map((d) => ({ value: d, label: d.description })) || [],
              selected: [selected_parent_cause],
              title: 'Macrofamiglia',
              searchable: true,
            }}
          />
          <Spacer width={15} />
          <KeyValueRow
            label={'Causale'}
            style={{ flex: 1 }}
            value={selected_cause?.label}
            optionModal={{
              onSelect: (cat) => {
                setSelectedCause(cat[0])
                onChildDefectSelected && onChildDefectSelected(cat[0].value)
              },
              field: 'label',
              options: childDefects?.map((d) => ({ value: d, label: d.description })) || [],
              selected: [selected_cause],
              title: 'Causale',
              searchable: true,
            }}
          />
        </Box>
        <Spacer height="15" />
        <Box style={{ alignItems: 'flex-end' }}>
          <Button
            disabled={!selected_parent_cause || !selected_cause}
            title="Aggiungi Causale"
            variant="default"
            onClick={addDefects}
          />
        </Box>
        <Spacer height="15" />
        <Table data={defects} structure={causeTableStructure} />
      </Box>
      {showMeasurementsModal && (
        <InboundWorkstationMeasurementsModal
          visible={showMeasurementsModal}
          allMeasuresList={allMeasuresList}
          measurements={measures}
          onClose={() => setShowMeasurementsModal(false)}
          onMeasureChange={(key, value) => {
            setMeasures({ ...measures, [key]: value })
          }}
        />
      )}
    </Box>
  )
}

const ControlStatusBox = styled(Box)<{ active?: boolean; backgroundColor?: string }>`
  height: 80px;
  width: 150px;
  background-color: ${(props) => props.backgroundColor || '#e2e2e2'};
  border: ${(props) => (props.active ? '5px solid #3091ff' : 'none')};
  color: white;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
`

const Label = styled.span`
  font-size: 20px;
`

const Value = styled.span`
  font-weight: bold;
  font-size: 20px;
`

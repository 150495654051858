import { Component } from 'react'
import { ItemIdentifier } from 'stylewhere/api'
import styled from '@emotion/styled'
import { Box, Icons } from 'stylewhere/components'

interface Props {
  identifier: ItemIdentifier
  last?: boolean
  showStatus?: boolean
}

interface State {
  dataMatrix: string
}

const bwipjs = require('bwip-js');

export class IdentifierRow extends Component<Props, State> {
  state: State = {
    dataMatrix: '',
  }
  componentDidMount() {
    const { identifier } = this.props
    if (identifier.role === 'data_matrix') this.loadDataMatrix()
  }

  loadDataMatrix = () => {
    const { identifier } = this.props
    const canvas = document.createElement('canvas')
    bwipjs.toCanvas(canvas, {
      bcid: 'datamatrix',
      text: identifier.code,
    })
    this.setState({ dataMatrix: canvas.toDataURL('image/png') })
  }

  render() {
    const { identifier, last } = this.props
    return (
      <Box vcenter row>
        {identifier.type === 'UHF_TAG' && (
          <Icons.Rfid width={20} height={20} style={{ marginTop: -7, marginRight: 17 }} />
        )}
        {identifier.type === 'NFC_Tag' && (
          <Icons.Nfc width={20} height={20} style={{ marginTop: -7, marginRight: 17 }} />
        )}
        {identifier.type !== 'UHF_TAG' && identifier.type !== 'NFC_Tag' && (
          <Icons.Serial width={20} height={20} style={{ marginTop: -7, marginRight: 17 }} />
        )}
        <IdentifierContainer data-testid={`identifier-container`} data-cy={identifier.code} flex>
          {identifier.role !== 'data_matrix' && (
            <Code height={40} vcenter>
              {identifier.code}
            </Code>
          )}
          {identifier.role === 'data_matrix' && <DataMatrix alt="" src={this.state.dataMatrix} />}
          <Separator last={last} />
        </IdentifierContainer>
      </Box>
    )
  }
}

const DataMatrix = styled.img`
  margin-top: 10px;
  height: 100px;
  width: 100px;
`

const Separator = styled(Box)<{ last?: boolean }>`
  height: 1px;
  background-color: ${({ last }) => (last ? 'transparent' : '#e0e0e0')};
`

const IdentifierContainer = styled(Box)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Code = styled(Box)`
  font-style: normal;
  font-weight: bold;
  font-size: 13;
  line-height: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

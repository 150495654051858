import styled from '@emotion/styled'
import 'core/theme/datepicker.scss'
import { format } from 'date-fns'
import { it } from 'date-fns/locale'
import { useState } from 'react'
import ReactDatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker'
import { Icons, Modal } from 'stylewhere/components'
import i18n from 'stylewhere/i18n'

registerLocale('it', it)

export const DatePicker: React.FC<ReactDatePickerProps> = (props) => {
  const [open, setOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState<Date | null | undefined>(props.selected)

  const closeModal = () => {
    setOpen(false)
  }

  const clear = (event) => {
    event.preventDefault()
    setSelectedDate(undefined)
    props.onChange && props.onChange(null, undefined)
    closeModal()
  }

  const onSelect = (date) => {
    props.onChange && props.onChange(date, undefined)
    setSelectedDate(date)
    closeModal()
  }

  const dateFormat = () => {
    if (props.dateFormat) {
      if (Array.isArray(props.dateFormat)) return props.dateFormat[0]
      else return props.dateFormat
    }
    return i18n.language === 'it' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
  }

  return (
    <>
      <Modal
        padding="0px"
        paddingDivContent={0}
        align="center"
        visible={open}
        onClose={closeModal}
        title={''}
        size="lg"
      >
        {/* @ts-expect-error Server Component */}
        <ReactDatePicker
          locale={i18n.language}
          dateFormat={dateFormat()}
          inline
          name={props.name}
          selected={selectedDate}
          showTimeSelect={props.showTimeSelect}
          onChange={(date: Date | null) => onSelect(date)}
          required={props.required}
          isClearable={props.isClearable}
          disabled={props.disabled}
          customInput={props.customInput}
          placeholderText={props.placeholderText}
          startDate={props.startDate}
          endDate={props.endDate}
          showYearDropdown={props.showYearDropdown}
          yearDropdownItemNumber={props.yearDropdownItemNumber || 4}
        />
      </Modal>
      <DateButton>
        <DateButtonText
          selected={selectedDate}
          style={{ paddingTop: selectedDate ? 10 : 0 }}
          onClick={() => setOpen(true)}
        >
          {selectedDate ? format(selectedDate, dateFormat()) : props.placeholderText}
        </DateButtonText>
        {selectedDate && props.isClearable && (
          <ImageWrap>
            <Icons.Close style={{ margin: 10, cursor: 'pointer', width: 24 }} onClick={(event) => clear(event)} />
          </ImageWrap>
        )}
      </DateButton>
    </>
  )
}

const DateButton = styled.div`
  flex: 1;
  height: 65px;
  background: #ffffff;
  width: 100%;
  border: 2px solid #d2d2d2;
  border-radius: 10px;
  position: relative;
  display: flex;
  justify-content: center;
`

const DateButtonText = styled.div<{ selected: Date | null | undefined }>`
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
  font-style: normal;
  font-weight: normal;
  color: ${({ selected }) => (selected ? '#333333' : '#666666')};
  font-size: 22px;
  line-height: 62px;
  cursor: pointer;
`

const ImageWrap = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 8px;
`

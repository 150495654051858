import axios from 'axios'

const API_URL = 'http://localhost:9100/'

interface Device {
  name: string
  deviceType: string
  connection: string
  uid: string
  provider: string
  manufacturer: string
  version: number
}

export class ZebraBrowserPrintClient {
  device: Device = {} as Device

  getDefaultPrinter = async (): Promise<Device> => {
    const endpoint = API_URL + 'default'

    try {
      const res = await axios.get(endpoint)
      const data = await res.data

      if (data && data !== undefined && typeof data !== 'object' && data.split('\n\t').length === 7) {
        const deviceRaw = data.split('\n\t')

        const name = this.cleanUpString(deviceRaw[1])
        const deviceType = this.cleanUpString(deviceRaw[2])
        const connection = this.cleanUpString(deviceRaw[3])
        const uid = this.cleanUpString(deviceRaw[4])
        const provider = this.cleanUpString(deviceRaw[5])
        const manufacturer = this.cleanUpString(deviceRaw[6])

        return {
          connection,
          deviceType,
          manufacturer,
          name,
          provider,
          uid,
          version: 0,
        }
      }

      throw new Error("There's no default printer")
    } catch (error: any) {
      throw new Error(error)
    }
  }

  getAvailablePrinters = async () => {
    const endpoint = API_URL + 'available'

    try {
      const res = await axios.get(endpoint)
      const data = await res.data

      if (data && data !== undefined && data.printer && data.printer !== undefined && data.printer.length > 0) {
        return data.printer
      }

      return new Error('No printers available')
    } catch (error: any) {
      throw new Error(error)
    }
  }

  setPrinter = (device: Device) => {
    this.device = device
  }

  getPrinter = (): Device => {
    return this.device
  }

  cleanUpString = (str: string): string => {
    const arr = str.split(':')
    const result = arr[1].trim()
    return result
  }

  checkPrinterStatus = async () => {
    await this.write('~HQES')
    const result = await this.read()

    const errors = []
    let isReadyToPrint = false

    const isError = result.charAt(70)
    const media = result.charAt(88)
    const head = result.charAt(87)
    const pause = result.charAt(84)

    isReadyToPrint = isError === '0'

    switch (media) {
      case '1':
        errors.push('Paper out')
        break
      case '2':
        errors.push('Ribbon Out')
        break
      case '4':
        errors.push('Media Door Open')
        break
      case '8':
        errors.push('Cutter Fault')
        break
      default:
        break
    }

    switch (head) {
      case '1':
        errors.push('Printhead Overheating')
        break
      case '2':
        errors.push('Motor Overheating')
        break
      case '4':
        errors.push('Printhead Fault')
        break
      case '8':
        errors.push('Incorrect Printhead')
        break
      default:
        break
    }

    if (pause === '1') errors.push('Printer Paused')

    if (!isReadyToPrint && errors.length === 0) errors.push('Error: Unknown Error')

    return {
      isReadyToPrint,
      errors: errors.join(),
    }
  }

  write = async (data: string) => {
    try {
      const endpoint = API_URL + 'write'

      const myData = {
        device: this.device,
        data,
      }

      await axios.post(endpoint, myData)
    } catch (error: any) {
      throw new Error(error)
    }
  }

  read = async () => {
    try {
      const endpoint = API_URL + 'read'

      const myData = {
        device: this.device,
      }

      const res = await axios.post(endpoint, myData)
      const data = await res.data
      return data
    } catch (error: any) {
      throw new Error(error)
    }
  }

  print = async (text: string) => {
    try {
      await this.write(text)
    } catch (error: any) {
      throw new Error(error)
    }
  }
}

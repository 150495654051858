import { api, responseErrorCheck, TmrBaseResource } from 'core/api'
import {
  InboundWorkstationItemConfirmation,
  SapInboundPartialConfirmationPayload,
  SapInboundUpdatePayload,
  SapInboundWorkstationConfirmationPayload,
} from './types'

export class InboundWorkstation extends TmrBaseResource {
  static endpoint = ''

  static getDefectsParentsByMerchCategory(merchCategory: string) {
    return api
      .get<any>(
        `qualityControl/defects?documentTypes=${merchCategory}&isParent=true&deleted=false&page=0&size=10000&sort=description,ASC`,
        {}
      )
      .then(responseErrorCheck)
  }

  static getDefectsChildsByMerchCategory(merchCategory: string, parentId: string) {
    return api
      .get<any>(
        `qualityControl/defects?documentTypes=${merchCategory}&parentIds=${parentId}&deleted=false&page=0&size=10000&sort=description,ASC`,
        {}
      )
      .then(responseErrorCheck)
  }

  static getDefectById(defectId: string) {
    return api.get<any>(`qualityControl/defects?ids=${defectId}`).then(responseErrorCheck)
  }

  static getItemInfo(mission: string, epc: string, warehouse: string) {
    return api
      .get<any>(`sapQualityControl/sapInbounds/item/info?mission=${mission}&epc=${epc}&warehouse=${warehouse}`, {})
      .then(responseErrorCheck)
  }

  static getInboundWorkstationData(mission: string, warehouse: string) {
    return api
      .get<any>(`sapQualityControl/sapInbounds/postazione/ingresso/check?mission=${mission}&warehouse=${warehouse}`, {})
      .then(responseErrorCheck)
  }

  static getSapInbounds(mission: string, warehouse: string) {
    return api
      .get<any>(`sapQualityControl/sapInbounds?missions=${mission}&warehouses=${warehouse}&page=0&size=10000`, {})
      .then(responseErrorCheck)
  }

  static getApplicationSettings(code: string) {
    return api.get<any>(`qualityControl/applicationSettings?codes=${code}`).then(responseErrorCheck)
  }

  static createSapQualityControlItem(data: InboundWorkstationItemConfirmation) {
    return api.post<any>(`sapQualityControl/sapInbounds/postazione/ingresso/cq`, data).then(responseErrorCheck)
  }

  // static getSessionItemInfo(sessionItemId: string) {
  //   return api.get<any>(`qualityControl/sessionItems?ids=${sessionItemId}&page=0&size=1`).then(responseErrorCheck)
  // }

  // static getDocumentInfo(documentId: string) {
  //   return api.get<any>(`qualityControl/unlockDocuments?ids=${documentId}&page=0&size=1`).then(responseErrorCheck)
  // }

  //risposta della chiamata dentro al content possibile
  // [{
  //   value: '0650SM',
  //   description: null,
  // }]
  static getSizeColorByMaterial(material: string) {
    return api
      .post<any>(`qualityControl/products/fieldsAutocomplete?page=0&size=10000`, {
        field: 'attributes.sizeColor',
        filters: {
          'attributes.material': [material],
        },
      })
      .then(responseErrorCheck)
  }

  static updateSapInbound(data: SapInboundUpdatePayload) {
    return api.put('sapQualityControl/sapInbounds/notes', data).then(responseErrorCheck)
  }

  static partialSapInboundConfirm(data: SapInboundPartialConfirmationPayload) {
    return api
      .post('sapQualityControl/sapInbounds/postazione/ingresso/partialConfirm/async', data)
      .then(responseErrorCheck)
  }

  static checkInboundWorkstationDocumentTypeSetting(mission: string, warehouse: string) {
    return api
      .get(
        `sapQualityControl/sapInbounds/documentTypes/checkSetting?mission=${mission}&warehouse=${warehouse}`,
        undefined
      )
      .then(responseErrorCheck)
  }

  static updateSapInboundDocumentType(missionId: string, catMerch: string) {
    return api
      .put(`sapQualityControl/sapInbounds/${missionId}/documentTypes?documentType=${catMerch.toUpperCase()}`, undefined)
      .then(responseErrorCheck)
  }

  static confirmInboundWorkstation(data: SapInboundWorkstationConfirmationPayload) {
    return api.post('sapQualityControl/sapInbounds/postazione/ingresso/confirm/async', data).then(responseErrorCheck)
  }

  // static getSizeByMaterial(material: string) {
  //   return api
  //     .post<any>(`qualityControl/products/fieldsAutocomplete?page=0&size=10000`, {
  //       field: 'sizeValue',
  //       filters: {
  //         'attributes.material': [material],
  //       },
  //     })
  //     .then(responseErrorCheck)
  // }
}

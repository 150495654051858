import React, { useEffect, useState, useCallback } from 'react'
import { Box, Button } from 'stylewhere/components'
import { __ } from 'stylewhere/i18n'
import { Progress } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { useLocation } from 'react-router-dom'
import { RfidReader, VERSION_CHECK_EVENTS } from 'stylewhere/shared'

interface UpdateNotificationProps {
  onReload?: () => void
}

export const UpdateNotification: React.FC<UpdateNotificationProps> = ({ onReload }) => {
  const [showNotification, setShowNotification] = useState(false)
  const [progress, setProgress] = useState(100)
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)
  const location = useLocation()

  const isOperationalPage = useCallback((pathname: string): boolean => {
    const operationalPatterns = ['/reading', '/verify', '/add', '/encode']
    const nonOperationalPatterns = ['/view', '/start', '/utilities', '/login', '/connection-error', '/dashboard']

    const isOperational = operationalPatterns.some((pattern) => pathname.includes(pattern))
    const isNonOperational = nonOperationalPatterns.some((pattern) => pathname.includes(pattern))

    return isOperational && !isNonOperational && !RfidReader.isReading()
  }, [])

  const handleReload = useCallback(() => {
    if (timer) clearInterval(timer)
    if (onReload) {
      onReload()
    } else {
      window.location.reload()
    }
  }, [timer, onReload])

  useEffect(() => {
    const handleUpdateDetected = () => {
      setShowNotification(true)

      if (!isOperationalPage(location.pathname)) {
        // Inizia il countdown solo per le pagine non operative
        const interval = setInterval(() => {
          setProgress((prev) => {
            if (prev <= 0) {
              handleReload()
              return 0
            }
            return prev - 100 / 30 // 30 secondi totali
          })
        }, 1000)

        setTimer(interval)
      }
    }

    window.addEventListener(VERSION_CHECK_EVENTS.VERSION_UPDATE_DETECTED, handleUpdateDetected)

    return () => {
      if (timer) clearInterval(timer)
      window.removeEventListener(VERSION_CHECK_EVENTS.VERSION_UPDATE_DETECTED, handleUpdateDetected)
    }
  }, [location.pathname, handleReload, isOperationalPage, timer])

  if (!showNotification) return null

  if (isOperationalPage(location.pathname)) {
    return (
      <NotificationContainer>
        <Box flex row vcenter>
          <Box
            flex
            style={{ width: '100%', display: 'flex', flexDirection: 'column', minHeight: 80, justifyContent: 'center' }}
          >
            <Box flex row vcenter>
              <Box flex vcenter>
                <NotificationTitle>{__('messages.newVersionAvailable')}</NotificationTitle>
                <NotificationText>{__('messages.update_available_description')}</NotificationText>
              </Box>
            </Box>
          </Box>
        </Box>
      </NotificationContainer>
    )
  }

  return (
    <NotificationContainer>
      <Box flex row vcenter>
        <Box
          flex
          style={{ width: '100%', display: 'flex', flexDirection: 'column', minHeight: 80, justifyContent: 'center' }}
        >
          <Box flex row vcenter>
            <Box flex>
              <NotificationTitle>{__('messages.newVersionAvailable')}</NotificationTitle>
              <NotificationText>{__('messages.pageWillReload')}</NotificationText>
            </Box>

            <Button
              variant="primary"
              size="small"
              title={__('misc.reload')}
              onClick={handleReload}
              style={{ marginLeft: 16 }}
            />
          </Box>
        </Box>
      </Box>
      <StyledProgress value={progress} size="sm" colorScheme="blue" />
    </NotificationContainer>
  )
}

const NotificationContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #ffffff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  max-width: 400px;
  min-width: 300px;
  overflow: hidden;
`

const NotificationText = styled.span`
  font-size: 14px;
  color: #333333;
`

const NotificationTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
  color: #333333;
`

const StyledProgress = styled(Progress)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  border-radius: 0 0 8px 8px;
  background: transparent;

  & > div {
    transition: width 0.5s linear;
  }
`

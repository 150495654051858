import { Component } from 'react'
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalProps as ChakraModalProps,
  ModalCloseButton,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { Spacer, ModalTitle } from 'stylewhere/components'

export interface ModalProps extends Partial<ChakraModalProps> {
  title?: React.ReactNode
  visible?: boolean
  onClose: () => void
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | 'full'
  fullContent?: boolean
  align?: 'left' | 'right' | 'center'
  padding?: string
  paddingDivContent?: number
  showCloseButton?: boolean
  'data-testid'?: string
}

interface State {
  visible: boolean
}

export class Modal extends Component<ModalProps, State> {
  state: State = {
    visible: this.props.visible ?? false,
  }

  show = () => {
    this.setState({ visible: true })
  }

  hide = () => {
    this.setState({ visible: false })
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      visible: nextProps.visible,
    }
  }

  render() {
    const {
      showCloseButton,
      fullContent,
      title,
      children,
      align,
      padding,
      paddingDivContent,
      'data-testid': dataTestId,
      ...rest
    } = this.props
    const { visible } = this.state
    return (
      <ChakraModal {...rest} isOpen={visible} isCentered closeOnEsc>
        <ModalOverlay />
        <Content data-testid={dataTestId || 'modal-content'} padding={padding} fullContent={fullContent}>
          {showCloseButton && <ModalCloseButton />}
          {title && (
            <div data-testid="modal-title">
              <ModalTitle align={align} title={title} />
              <Spacer />
            </div>
          )}
          {!fullContent && (
            <Body style={{ textAlign: align || 'left' }}>
              <div
                data-testid="modal-body"
                style={{ padding: paddingDivContent !== undefined ? paddingDivContent : 3 }}
              >
                {children}
              </div>
            </Body>
          )}
          {fullContent && <div data-testid="modal-body">{children}</div>}
        </Content>
      </ChakraModal>
    )
  }
}

const Body = styled(ModalBody)`
  overflow: auto;
`
const Content = styled(ModalContent)<{ fullContent?: boolean; padding?: string }>`
  ${({ fullContent, padding }) =>
    !fullContent &&
    `
    padding: ${padding || '25px 32px'};
  `}
  overflow: hidden;
`

import { Component } from 'react'
import { Router, ShippingOperationConfig, RemoteOperation, getDataFromSchema } from 'stylewhere/shared'
import { isModalError, showToastError } from 'stylewhere/utils'
import { Shippings, ShippingParcel, Asn, Asns, Parcels, api, BaseResource, ResourcePage } from 'stylewhere/api'
import type { Routes } from 'stylewhere/pages'
import { T, __ } from 'stylewhere/i18n'
import { Box, Icons, OperationStart, Page } from 'stylewhere/components'
import { ShippingExtensions } from 'stylewhere/extensions'
import { get as _get, set as _set } from 'lodash'
import { RouteComponentProps, StaticContext } from 'react-router'
import { CustomError } from 'stylewhere/shared/errors'

export interface ShippingParcelAddParams {
  opCode: string
  asnId: string
  parcelId: string
}

interface State {
  asn?: Asn | undefined
  parcel?: ShippingParcel | undefined
  loading: boolean
  initialValues?: object
}

interface LocationState {
  asn?: Asn
  parcel?: ShippingParcel
}

export default class ShippingParcelAdd extends Component<
  RouteComponentProps<ShippingParcelAddParams, StaticContext, LocationState>,
  State
> {
  asnId: string
  parcelId: string
  opCode: string
  isModal: boolean
  operation: ShippingOperationConfig
  submitPath: Routes = '/shipping/:opCode/reading' // FIXME
  backPath: Routes = '/shipping/:opCode/asn/:asnId'
  state: State = {
    loading: true,
  }
  locationState = Router.getLocationState<LocationState>(this.props)

  constructor(props: any) {
    super(props)
    const matchParams: ShippingParcelAddParams = Router.getMatchParams(this.props)
    this.asnId = matchParams.asnId
    this.parcelId = matchParams.parcelId
    this.opCode = matchParams.opCode
    this.isModal = false
    this.operation = RemoteOperation.getOperationConfig<ShippingOperationConfig>(this.opCode)
  }

  getInitialValue = async (schema, object, initialValues) => {
    schema.map(async (e) => {
      const check = _get(initialValues, e.name)
      if (!check) {
        const value = _get(object, e.name)
        if (value) {
          if (e.type === 'autocomplete' && e.endpoint && typeof value === 'string') {
            try {
              const result: any = await api.get<ResourcePage<BaseResource> | BaseResource[]>(e.endpoint, {
                size: 1,
                ids: value,
              })
              if (result && result.data && result.data.content && result.data.content.length === 1) {
                _set(initialValues, e.name, result.data.content[0])
              }
            } catch (err) {
              console.error(err)
            }
          } else {
            _set(initialValues, e.name, value)
          }
        }
      }
    })
    return initialValues
  }

  async componentDidMount() {
    this.isModal = isModalError(this.operation)
    try {
      let asn: State['asn'], parcel: State['parcel']
      if (this.locationState?.asn) {
        asn = this.locationState?.asn
      } else {
        const loadedAsn = await Asns.get<Asn>(this.asnId)
        if (loadedAsn) {
          asn = loadedAsn
        }
      }
      if (!asn) {
        showToastError(__(T.misc.shipment), __(T.error.error), this.isModal)
        Router.navigate(Router.replaceMatchParams(this.backPath, { asnId: this.asnId }), {
          opCode: this.operation.code,
        })
        return
      }

      const schema = ShippingExtensions.formSchema(this.operation, undefined, ['', 'parcel'])

      let initialValues: Record<string, unknown> = {}
      initialValues = await this.getInitialValue(schema, asn, initialValues)

      // parcel id is not always available, when we add a new parcel it should be empty
      if (this.locationState?.parcel) {
        parcel = this.locationState?.parcel
      } else if (this.parcelId) {
        const loadedParcel = await Parcels.get<ShippingParcel>(this.parcelId)
        if (loadedParcel) parcel = loadedParcel
      }

      if (parcel) {
        initialValues = await this.getInitialValue(schema, parcel, initialValues)
        if (!initialValues.parcelCode) {
          initialValues.parcelCode = parcel.code
        }
        this.setState({ asn, parcel, loading: false, initialValues })
      } else {
        this.setState({ asn, loading: false, initialValues })
      }
    } catch (error) {
      showToastError(error, __(T.error.error), this.isModal)
      this.setState({ loading: false })
    }
  }

  onSubmit = async (formData, operation: ShippingOperationConfig, schema) => {
    const { asn, parcel: selectedParcel } = this.state
    if (!asn) {
      showToastError(__(T.misc.shipment), __(T.error.error), this.isModal)
      return
    }

    let parcel = selectedParcel
    const payloadStartParcel: any = getDataFromSchema(formData, schema) || {}
    const payload = {
      ...payloadStartParcel,
      asn: { code: asn.code },
    }
    try {
      parcel = await Shippings.startParcel(operation, payload)
      Router.navigate(
        this.submitPath,
        { opCode: operation.code, asnId: this.asnId },
        {
          state: {
            formData,
            parcel,
            asn,
            backRoute: this.backPath,
            backRouteParams: {
              opCode: this.opCode,
              asnId: this.asnId,
            },
          },
        }
      )
    } catch (err) {
      if (err instanceof CustomError) {
        showToastError(
          `${CustomError.interpolateMessage(err.message, payload)}. [${err.errorCode}]`,
          __(T.error.error),
          this.isModal
        )
        return
      }
      showToastError(err, 'Start Parcel Error', this.isModal)
    }
  }

  render() {
    const { loading, initialValues } = this.state
    if (loading) {
      return (
        <Page
          title={__(T.misc.loading)}
          onBackPress={() => {
            Router.navigate(this.backPath, { opCode: this.opCode, asnId: this.asnId })
          }}
        >
          <Page.Content notBoxed>
            <Box flex center>
              <Icons.Loader1 />
            </Box>
          </Page.Content>
        </Page>
      )
    }

    // console.log('initialValues', initialValues)
    return (
      <OperationStart
        backPath={Router.replaceMatchParams(this.backPath, { asnId: this.asnId })}
        startWithRfidSearch={false}
        submitPath={this.submitPath}
        extensions={ShippingExtensions}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
        filterKey={['', 'parcel']}
      />
    )
  }
}

import { api, ResourcePage, responseErrorCheck, responseErrorCheckSilent } from 'stylewhere/api'

export interface ServiceInfo {
  serviceName: string
  serviceVersion: string
  buildDate: string
  plugins: any[]
}

export interface ServicesInfo {
  [serviceName: string]: {
    version: string
    buildDate: string
    plugins: any[]
  }
}

export class ServiceInformations {
  static endpoint = '/api/v1/serviceInformations'

  static search(): Promise<ResourcePage<ServiceInfo>> {
    return api.get<ResourcePage<ServiceInfo>>(this.endpoint).then(responseErrorCheck)
  }

  static searchSilent(): Promise<ResourcePage<ServiceInfo>> {
    return api.get<ResourcePage<ServiceInfo>>(this.endpoint).then(responseErrorCheckSilent)
  }
}

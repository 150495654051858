import { Component } from 'react'
import styled from '@emotion/styled'
import { Box, Image, Spacer, Icons } from 'stylewhere/components'
import { ProductInfoField, Products, TmrProduct } from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'
import { getCustomTranslation, getField, __CAPITALIZE } from 'stylewhere/utils'
import { AppStore } from 'stylewhere/shared'

interface Props {
  product?: TmrProduct
  fields?: ProductInfoField[]
  showMore?: any
  viewMore?: boolean
  onCloseClick?: () => void
  disabledDescription?: boolean
  marginCard?: string
  disabledImage?: boolean
  paddingCard?: string
  shadowCard?: string
}

export class ProductInfoBox extends Component<Props> {
  static getFieldLabel = (label?: string) => {
    return __CAPITALIZE(getCustomTranslation(label))
  }

  static Field = ({ label, value = '---', rowLabel = '' }: { label?: string; value?: string; rowLabel?: string }) => (
    <ContainerValue data-testid={`product-info-field`} data-cy={rowLabel || label} row>
      <LabelText data-testid="label-text">{ProductInfoBox.getFieldLabel(label)}</LabelText>
      <ValueText data-testid="value-text">{value}</ValueText>
    </ContainerValue>
  )

  getValue = (field: ProductInfoField) => {
    const { product } = this.props
    if (field.path) return getField(product, field.path)
    if (field.paths) return field.paths.map((path) => getField(product, path) as string).join(' - ')
    return '---'
  }

  getProductCode(product?: TmrProduct) {
    return product?.code ?? '---'
  }

  showImage = () => {
    const { disabledImage } = this.props
    return !disabledImage && AppStore.getShowProductImage()
  }

  render() {
    const {
      product,
      fields = [],
      showMore,
      viewMore,
      onCloseClick,
      disabledDescription,
      marginCard,
      paddingCard,
      shadowCard,
    } = this.props
    return (
      <>
        <CardContainer
          padding={paddingCard || '20px 20px 0px 20px'}
          shadow={shadowCard || '0px 1px 4px rgba(0, 0, 0, 0.15)'}
          style={{ margin: marginCard || 0 }}
          data-testid={`product-info-box`}
          data-cy={product?.code}
        >
          <Box justify={'space-between'} row>
            <Title>{__(T.misc.product)}</Title>
            {onCloseClick && (
              <Box onClick={onCloseClick}>
                <Icons.Close />
              </Box>
            )}
          </Box>
          <Box row>
            {this.showImage() && (
              <Image
                transparent
                width={120}
                height={120}
                maxHeightContainer={260}
                variant="boxed"
                src={Products.getImageUrl(product)}
              />
            )}
            {this.showImage() && <Spacer />}
            <Box flex style={{ overflow: 'hidden' }}>
              <CodeLabel data-testid={`product-code`}>{this.getProductCode(product)}</CodeLabel>
              {!disabledDescription && (
                <DescriptionLabel data-testid={`product-description`}>{product?.description ?? '---'}</DescriptionLabel>
              )}
              <Spacer />
              {fields.map((field, index) => (
                <ProductInfoBox.Field
                  data-testid={`product-info-field`}
                  key={index}
                  label={getCustomTranslation(field.label)}
                  rowLabel={field.label}
                  value={this.getValue(field) ?? '---'}
                />
              ))}
              {showMore && showMore !== undefined && (
                <ViewAttributeBox data-testid={`view-attribute-box`} onClick={showMore}>
                  {viewMore ? __(T.misc.hide_more) : __(T.misc.show_more)}
                </ViewAttributeBox>
              )}
            </Box>
          </Box>
        </CardContainer>
      </>
    )
  }
}

const CardContainer = styled(Box)<{ padding: string; shadow: string }>`
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  padding: ${({ padding }) => padding || '20px;'};
  margin: 2px;
  box-shadow: ${({ shadow }) => shadow || '0px 1px 4px rgba(0, 0, 0, 0.15);'};
`

const ContainerValue = styled(Box)`
  overflow-x: hidden;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 10px;
  padding-bottom: 10px;
  &:last-child {
    border-bottom: 0 solid #e0e0e0;
  }
`

const Title = styled.div`
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #000000;
  opacity: 0.4;
  margin-bottom: 20px;
`

const LabelText = styled.span`
  font-size: 14px;
  line-height: 16px;
  align-items: center;
`
const ValueText = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const CodeLabel = styled.span`
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  display: flex;
  align-items: center;
  word-break: break-all;
`

const DescriptionLabel = styled.span`
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
`

const ViewAttributeBox = styled(Box)`
  font-size: 14px;
  line-height: 16px;
  text-align: right;
`

import { createBrowserHistory, Location } from 'history'
import { stringify, parse } from 'qs'
import type { Routes } from 'stylewhere/pages'
import { RouteComponentProps } from 'react-router-dom'

export type Route = { component: React.ReactNode }

interface RouteParams {
  [key: string]: any
}

interface NavigationOptions {
  replace?: boolean
  state?: any
  props?: { [key: string]: any }
}

// navigation history
export const history = createBrowserHistory()

export const replaceMatchParams = (page: Routes, params: Record<string, any>) => {
  Object.keys(params).forEach((key) => {
    if (page.includes(`:${key}`)) {
      page = page.replace(`:${key}`, params[key]) as Routes
      delete params[key]
    }
  })
  return page
}

export function navigate(_path: Routes, routeParams?: RouteParams, options?: NavigationOptions): void {
  // add query params to path
  const route = routeParams ? replaceMatchParams(_path as Routes, routeParams) : _path
  const stringParams = routeParams ? stringify(routeParams, { arrayFormat: 'repeat' }) : ''
  const path = `${route}${stringParams ? `?${stringParams}` : ''}`
  const props = options ? options.props : undefined

  options && options.replace ? history.replace(path, props) : history.push(path, options?.state)
}

export function reloadApp(destination?: Routes) {
  if (destination) navigate(destination)
  window.location.reload()
}

export function refresh(queryParams?: RouteParams) {
  navigate(history.location.pathname as Routes, queryParams)
}

export function getMatchParams(props: RouteComponentProps<any>) {
  return props.match.params
}

export function getLocationState<S extends object>(props: { location: Location<S> }) {
  return props.location.state ?? ({} as Partial<S>)
}

export function getQueryParams(): Record<string, any> {
  const { search } = history.location
  const queryParams = search && search.charAt(0) === '?' ? search.substring(1) : search
  return parse(queryParams)
}

export function addQueryParams(newParams: Record<string, unknown>, reset = false): void {
  const { search } = history.location
  const queryParams = reset ? {} : parse(search && search.charAt(0) === '?' ? search.substring(1) : search)
  const params = {
    ...queryParams,
    ...newParams,
  }
  let paramsString = ''
  Object.keys(params).map((key) => (paramsString += `${key}=${params[key]}&`))
  if (paramsString.length > 0) paramsString = paramsString.substring(0, paramsString.length - 1)
  const encoded = paramsString ? `?${encodeURI(paramsString)}` : ''
  if (search !== encoded) history.push(encoded)
}

export function setQueryParams(newParams: Record<string, any>) {
  addQueryParams(newParams, true)
}
